import { Theme, alpha } from '@mui/material'

export type MuiTabCallbackProps = {
  theme: Theme
}

export const MuiTab = (): object => {
  return {
    styleOverrides: {
      root: ({ theme }: MuiTabCallbackProps) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
          minWidth: 0,
        },
        marginRight: theme.spacing(0.5),
        color: theme.palette.text.primary,
        background: `linear-gradient(126deg, ${alpha(theme.palette.primary.main, 0.06)} 15.93%, ${alpha(theme.palette.secondary.main, 0.06)} 93.42%)`,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        '&:hover': {
          // TODO: Need to add hover effect
        },
        '&.Mui-selected': {
          color: theme.palette.common.white,
          background: `linear-gradient(126deg, ${theme.palette.primary.main} 15.93%, ${theme.palette.secondary.main} 93.42%)`,
        },
        minHeight: 40,
        height: 40,
      }),
    },
    defaultProps: {},
  }
}
