export const MuiCssBaseline = (palette: any): object => {
  return {
    styleOverrides: {
      ':root': {
        '--toastify-z-index': 1200,
      },
      body: {
        // Used to change style of selected text in the browser
        '& ::-moz-selection': {
          backgroundColor: palette.secondary.main,
          color: palette.background.default,
        },
        '& ::selection': {
          backgroundColor: palette.secondary.main,
          color: palette.background.default,
        },
      },
      '.Toastify__toast': {
        boxShadow: '20px 20px 50px 12px rgba(0, 0, 0, 0.10) !important',
        fontFamily: 'inherit !important',
        overflow: 'visible !important',
        padding: '0px !important',
      },
      '.Toastify__toast-container': {
        top: '116px !important',
        right: 'max(var(--toastify-toast-right), 32px) !important',
      },
      '.Toastify__toast--stacked:before': {
        height: '8px !important',
        transform: 'translate(0px, 8px)',
      },
      '.Toastify__toast--stacked:after': {
        height: '8px !important',
      },
      '.Toastify__toast-body': {
        margin: '0px !important',
        padding: '0px !important',
      },
      '.Toastify__toast-body>div': {
        height: '100% !important',
      },
      '.Toastify__custom-close-button': {
        display: 'none !important',
      },
      '.Toastify__toast:hover .Toastify__custom-close-button': {
        display: 'flex !important',
      },
      '.Toastify__toast-theme--light': {
        backgroundColor: `#F2F1FC !important`,
        color: `${palette.text.primary} !important`,
      },
    },
  }
}
