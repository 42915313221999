import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import Envs from '../envs'
import useAuthStore from '../../state/auth-store/index'
import { UpdateAccessToken } from '../../side-effects/auth'
import { REFRESH_TOKEN_ENDPOINT } from '../../constants/api'
import { LOGIN_PATH } from '../../constants/paths'
import useProfileStore from '../../state/profile-store'

type RetryConfig = AxiosRequestConfig & {
  retry: number
  retryDelay: number
  retryOn: number[]
}
export const retryConfig: RetryConfig = {
  retry: 2,
  retryDelay: 1000,
  retryOn: [401, 500, 503, 502],
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: Envs.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Function to get auth from localStorage
const getAuth = (): any => {
  const auth = localStorage.getItem('auth')
  return auth ? JSON.parse(auth) : null
}

// Inject Authorization header into requests
axiosInstance.interceptors.request.use((config) => {
  const auth = getAuth()
  const shouldIncludeAuth = config.headers?.includeAuth

  const newConfig = { ...config }

  if (
    auth &&
    auth.state.auth &&
    auth.state.auth.accessToken &&
    shouldIncludeAuth
  ) {
    newConfig.headers.Authorization = `Bearer ${auth.state.auth.accessToken} `
  }

  // Remove custom flag to prevent axios errors
  delete newConfig.headers?.includeAuth

  return newConfig
})

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Modify the request config here if needed
    return config
  },
  (error: AxiosError) => {
    // Handle request error here
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // You can modify the response data here
    return response
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as RetryConfig
    console.log('error', error.response)

    // Check if it's a 401 Unauthorized error
    if (error.response?.status === 401) {
      const auth = getAuth() // Get current auth tokens from storage

      if (auth && auth.state.auth && auth.state.auth.refreshToken) {
        try {
          // Attempt to refresh the access token using the refresh token
          const refreshResponse = await axios.post(
            `${REFRESH_TOKEN_ENDPOINT}`,
            {
              refresh: auth.state.auth.refreshToken,
            }
          )

          if (refreshResponse.status === 200) {
            const { access } = refreshResponse.data
            console.log('access', access)
            UpdateAccessToken(access) // Update the access token in Zustand store (it will internally update in localStorage as well)

            // Update the Authorization header on the original request and retry
            if (originalRequest.headers) {
              originalRequest.headers.Authorization = `Bearer ${access}`
            }
            originalRequest.retry += 1 // Mark the request as retried
            return axios(originalRequest) // Retry the original request with the new access token
          }
        } catch (refreshError) {
          // If token refresh fails, handle logout and redirection
          const { logout } = useAuthStore.getState()
          logout()
          window.location.href = LOGIN_PATH
          return Promise.reject(refreshError) // Return the refresh attempt error
        }
      }
      if (auth) {
        const { logout } = useAuthStore.getState()
        logout()
        return Promise.reject(error) // Return the original 401 error
      }
    }

    // Handle retry logic for other errors as per your existing setup
    let retry =
      'retry' in originalRequest ? originalRequest.retry : retryConfig.retry
    const retryDelay =
      'retryDelay' in originalRequest
        ? originalRequest.retryDelay
        : retryConfig.retryDelay
    const retryOn =
      'retryOn' in originalRequest
        ? originalRequest.retryOn
        : retryConfig.retryOn
    if (originalRequest && retry && retryDelay) {
      if (!retryOn?.length || retryOn.includes(error.response?.status || 0)) {
        retry -= 1
        return new Promise((resolve) => {
          setTimeout(() => {
            originalRequest.retry = retry
            resolve(axiosInstance(originalRequest))
          }, retryDelay)
        })
      }
    }
    // if (
    //   originalRequest.retry &&
    //   originalRequest.retryDelay &&
    //   originalRequest.retryOn?.includes(error.response?.status || 0)
    // ) {
    //   originalRequest.retry -= 1
    //   console.log('Here2', originalRequest.retry)
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       console.log('Here3', originalRequest)
    //       resolve(axios(originalRequest))
    //     }, originalRequest.retryDelay)
    //   })
    // }

    return Promise.reject(error) // For all other errors, reject the promise
  }
)

export default axiosInstance
