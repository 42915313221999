import { useEffect, useRef } from 'react'
import { Howl } from 'howler'

interface SoundOptions {
  loop?: boolean
  volume?: number
  rate?: number
  onPlay?: () => void
  onStop?: () => void
  onEnd?: () => void
  preventReplay?: boolean
}

const useNotificationSound = (
  soundUrl: string,
  options: SoundOptions = {}
): {
  playSound: () => void
  stopSound: () => void
  resetSound: () => void
} => {
  const sound = useRef<Howl | null>(null)
  const isPlaying = useRef(false)

  useEffect(() => {
    sound.current = new Howl({
      src: [soundUrl],
      loop: options.loop || false,
      volume: options.volume || 1,
      rate: options.rate || 1,
      onplay: () => {
        isPlaying.current = true
        options.onPlay?.()
      },
      onstop: () => {
        isPlaying.current = false
        options.onStop?.()
      },
      onend: () => {
        isPlaying.current = false
        options.onEnd?.()
      },
    })

    // Clean up the sound on unmount
    return () => {
      if (sound.current) {
        sound.current.unload()
      }
    }
  }, [
    soundUrl,
    options.loop,
    options.volume,
    options.rate,
    options.onPlay,
    options.onStop,
    options.onEnd,
  ])

  const playSound = (): void => {
    if (options.preventReplay && isPlaying.current) {
      return
    }
    if (sound.current) {
      sound.current.seek(0)
      sound.current.play()
    }
  }

  const stopSound = (): void => {
    if (sound.current) {
      sound.current.stop()
    }
  }

  const resetSound = (): void => {
    if (sound.current) {
      sound.current.seek(0)
    }
  }

  return { playSound, stopSound, resetSound }
}

export default useNotificationSound
