import React, { Suspense, lazy, useEffect, useMemo } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { responsiveFontSizes } from '@mui/material/styles'
import { ToastContainer, ToastItem, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import '../../styles/app.scss'
import { theme } from '../../material-ui/mui-theme'
import useCommonStore from '../../state/common-store'
import { PublicRoutes, PrivateRoutes } from '../../routers'
import useProfileStore from '../../state/profile-store'
import {
  ACTIVITY_PATH,
  DASHBOARD_PATH,
  LOGIN_PATH,
  ONBOARDING_PATH,
  SIGNUP_PATH,
  USER_PROFILE_PATH,
  RESET_PASSWORD_EMAIL_SENT_PATH,
  RESET_PASSWORD_DONE_PATH,
  RESET_PASSWORD_INIT_PATH_INTERAL,
  SETTINGS_PATH,
  VERIFY_EMAIL_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_UPDATE_PATH,
  SEARCH_PATH,
} from '../../constants/paths'
import PageNotFound from '../PageNotFound'
import ResetPasswordDone from '../ResetPassword/reset-password-done'
import ResetPasswordInternalInit from '../ResetPasswordInternal/reset-password-init'
import useNotificationStore from '../../state/notifications-store'
import useNotification from '../../side-effects/hooks/useNotification'
import useAuthStore from '../../state/auth-store'
// import SearchResults from '../Search'
import useJuneAnalyticsStore from '../../state/june-store'
import { getSummary } from '../../helpers/june-helpers'
import { getDeviceDetails } from '../../helpers/browser'
import { ACTIVE, LOGIN, AGENT } from '../../constants/june_so'
import FullScreenLoader from '../partials/Loaders/full-screen-loader'
import RouteElement from '../../routers/route-element'

const Dashboard = lazy(
  () =>
    import(
      /* webpackChunkName: 'Dashboard' */
      '../Dashboard'
    )
)
const Activity = lazy(
  () =>
    import(
      /* webpackChunkName: 'Activity' */
      '../Activity'
    )
)
const Settings = lazy(
  () =>
    import(
      /* webpackChunkName: 'Settings' */
      '../Settings'
    )
)
const Onboarding = lazy(
  () =>
    import(
      /* webpackChunkName: 'Onboarding' */
      '../Onboarding'
    )
)
const UserProfile = lazy(
  () =>
    import(
      /* webpackChunkName: 'UserProfile' */
      '../Profile'
    )
)
const SearchResults = lazy(
  () =>
    import(
      /* webpackChunkName: 'SearchResults' */
      '../Search'
    )
)

const Login = lazy(
  () =>
    import(
      /* webpackChunkName: 'Login' */
      '../Login'
    )
)
const Signup = lazy(
  () =>
    import(
      /* webpackChunkName: 'Signup' */
      '../Signup'
    )
)
const VerifyEmail = lazy(
  () =>
    import(
      /* webpackChunkName: 'VerifyEmail' */
      '../VerifyEmail'
    )
)
const ResetPasswordInit = lazy(
  () =>
    import(
      /* webpackChunkName: 'ResetPasswordInit' */
      '../ResetPassword/reset-password-init'
    )
)
const ResetPasswordEmailSent = lazy(
  () =>
    import(
      /* webpackChunkName: 'ResetPasswordEmailSent' */
      '../ResetPassword/reset-password-email-sent'
    )
)
const UpdatePassword = lazy(
  () =>
    import(
      /* webpackChunkName: 'UpdatePassword' */
      '../ResetPassword/update-password'
    )
)

function App(): JSX.Element {
  // June INIT
  const { loadAnalytics } = useJuneAnalyticsStore.getState()
  loadAnalytics()
  // Theme mode
  const themeMode = useCommonStore((state) => state.themeMode)
  const globalTheme = responsiveFontSizes(theme(themeMode))

  // Login
  const initialLoggedIn = useProfileStore((state) => state.loggedIn)
  const setLoggedIn = useProfileStore((state) => state.setLoggedIn)
  const isProfileLoading = useProfileStore((state) => state.isUserInfoLoading)
  const { accessToken } = useAuthStore((state) => state.auth)
  useEffect(() => {
    if (accessToken) {
      setLoggedIn(true)
    }
  }, [])
  const loggedIn = useMemo(
    () => initialLoggedIn || Boolean(accessToken),
    [initialLoggedIn, accessToken]
  )

  const notifications = useNotificationStore((state) => state.notifications)
  const removeNotification = useNotificationStore(
    (state) => state.removeNotification
  )

  const { notify } = useNotification()

  const prevInAppNotificationsEmail = useNotificationStore(
    (state) => state.email
  )
  const setPrevInAppNotificationsEmail = useNotificationStore(
    (state) => state.setEmail
  )
  const getUserInfo = useProfileStore((state) => state.getUserInfo)
  useEffect(() => {
    getUserInfo()
      .then((response) => {
        if (loggedIn && response?.data?.user?.email) {
          // June.so (Identify)
          const { getAnalytics } = useJuneAnalyticsStore.getState()
          const analytics = getAnalytics()
          const { organization } = useProfileStore.getState()
          const { token } = organization
          const { userInfo } = useProfileStore.getState()
          const {
            id,
            firstName,
            lastName,
            email,
            company,
            department,
            jobTitle,
            photo,
            role,
            onboarded,
          } = userInfo

          analytics?.identify(String(id), {
            email,
            firstName,
            lastName,
            role,
            industry: company,
            device: getDeviceDetails(),
            token,
            userType: AGENT,
            department,
            jobTitle,
            avatar: photo,
            onboarded,
            clientType: role,
          })

          if (prevInAppNotificationsEmail !== response.data.user.email) {
            toast.dismiss({ containerId: 'in-app-notification-container' })
            notifications.forEach((notification) => {
              removeNotification(notification.id)
            })
          } else {
            toast.dismiss({ containerId: 'in-app-notification-container' })
            notifications.forEach((notification) => {
              notify({ ...notification })
            })
          }
        }
        setPrevInAppNotificationsEmail(response?.data?.user?.email)
      })
      .catch(() => {
        toast.dismiss({ containerId: 'in-app-notification-container' })
      })
  }, [loggedIn])

  const { name: organizationName } = useProfileStore(
    (state) => state.organization
  )

  // Future Scope: Set theme mode based on user's preference
  // const setThemeMode = useCommonStore((state) => state.setThemeMode)
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  // useEffect(() => {
  //   if (prefersDarkMode) {
  //     setThemeMode('dark')
  //   }
  // }, [prefersDarkMode, setThemeMode])

  // useEffect(() => {
  //   toast.dismiss({ containerId: 'in-app-notification-container' })
  //   notifications.forEach((notification) => {
  //     notify({ ...notification })
  //   })
  // }, [])

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <Suspense fallback={<FullScreenLoader />}>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route element={<PublicRoutes />}>
              <Route path={LOGIN_PATH} element={<Login />} />
              <Route path={SIGNUP_PATH} element={<Signup />} />

              {/* Below is not used now as we are activating account on signup */}
              <Route path={VERIFY_EMAIL_PATH} element={<VerifyEmail />} />

              <Route
                path={RESET_PASSWORD_PATH}
                element={<ResetPasswordInit />}
              />
              <Route
                path={RESET_PASSWORD_EMAIL_SENT_PATH}
                element={<ResetPasswordEmailSent />}
              />
              <Route
                path={RESET_PASSWORD_UPDATE_PATH}
                element={<UpdatePassword />}
              />

              {/* Future Scope: Add more public routes below */}
            </Route>

            {/* Private Routes */}
            <Route element={<PrivateRoutes />}>
              <Route
                path={DASHBOARD_PATH}
                element={
                  <RouteElement>
                    <Dashboard />
                  </RouteElement>
                }
              />
              <Route
                path={ACTIVITY_PATH}
                element={
                  <RouteElement>
                    <Activity />
                  </RouteElement>
                }
              />
              <Route path={SETTINGS_PATH} element={<Settings />} />
              <Route path={ONBOARDING_PATH} element={<Onboarding />} />
              <Route path={USER_PROFILE_PATH} element={<UserProfile />} />
              <Route path={SEARCH_PATH} element={<SearchResults />} />

              <Route
                path={RESET_PASSWORD_INIT_PATH_INTERAL}
                element={<ResetPasswordInternalInit />}
              />
              {/* 4) reset-password-done */}
              <Route
                path={RESET_PASSWORD_DONE_PATH}
                element={<ResetPasswordDone />}
              />
              {/* Future Scope: Add more private routes below */}
            </Route>

            {/* For other routes to work - PageNotFound should be at the last */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  )
}

export default App
