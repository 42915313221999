import { AxiosResponse } from 'axios'
import { camelCase, snakeCase } from 'lodash'

export const snakeToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamelCase(v))
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: snakeToCamelCase(obj[key]),
      }),
      {}
    )
  }
  return obj
}

export const camelToSnakeCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelToSnakeCase(v))
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCase(key)]: camelToSnakeCase(obj[key]),
      }),
      {}
    )
  }
  return obj
}

export const getResponse = async (
  request: Promise<AxiosResponse<any, any>>
): Promise<AxiosResponse<any, any>> => {
  try {
    const response = await request
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
