import { Box, Button, Stack, Typography } from '@mui/material'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import {
  DraggableDirection,
  Id,
  Theme,
  ToastClassName,
  ToastContent,
  ToastContentProps,
  ToastOptions,
  ToastPosition,
  ToastTransition,
  TypeOptions,
} from 'react-toastify'
import CloseDetailButton from './close-detail-button'
import { getDeviceDetails } from '../../../helpers/browser'
import { getSummary } from '../../../helpers/june-helpers'
import {
  PI_IN_APP_NOTIFICATIONS_CLICK,
  PI_IN_APP_NOTIFICATIONS_CLOSE,
  NOTIFICATION,
} from '../../../constants/june_so'
import useJuneAnalyticsStore from '../../../state/june-store'
import useProfileStore from '../../../state/profile-store'

export interface ToastProps extends ToastOptions {
  isIn: boolean
  staleId?: Id
  toastId: Id
  key: Id
  transition: ToastTransition
  closeToast: () => void
  position: ToastPosition
  children?: ToastContent
  draggablePercent: number
  draggableDirection?: DraggableDirection
  progressClassName?: ToastClassName
  className?: ToastClassName
  bodyClassName?: ToastClassName
  deleteToast: () => void
  theme: Theme
  type: TypeOptions
  collapseAll: () => void
  stacked?: boolean
}

export type InAppNotificationProps = {
  title: string
  message?: string
  timestamp?: string
  endUser?: {
    name: string
    company: string
  }
  toastProps: ToastProps
}

function InAppNotification(props: InAppNotificationProps): JSX.Element {
  const { toastProps, title, message, timestamp, endUser } = props

  const handleCloseToast = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    toastProps.closeToast()
    toastProps.onClose?.(e)
  }
  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const organization = useProfileStore((state) => state.organization)
  const userInfo = useProfileStore((state) => state.userInfo)
  const { token: orgToken } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  return (
    <Stack
      onClick={(e) => {
        // June.so tracking
        analytics?.track(PI_IN_APP_NOTIFICATIONS_CLICK, {
          pathname: window.location.pathname,
          status: true,
          timestamp: new Date().toISOString(),
          event_type: PI_IN_APP_NOTIFICATIONS_CLICK,
          user_id: customerId,
          device: deviceDetails,
          summary: getSummary(
            PI_IN_APP_NOTIFICATIONS_CLICK,
            String(customerId)
          ),
          view: window.location.pathname,
          subject: NOTIFICATION,
          object: {
            onboarded,
            notification: {
              title,
              message,
              timestamp,
              endUser,
            },
          },
          orgKey: orgToken,
        })

        toastProps.onClick?.(e)
        handleCloseToast(e)
      }}
      sx={{ ...toastProps.style }}
      width="100%"
      height="100%"
      spacing={1}
      p={1.75}
      justifyContent="center"
    >
      <Stack direction="row" alignItems="center" spacing={0.25}>
        <Typography variant="body2" fontWeight={600} noWrap flex={1}>
          {title}
        </Typography>
        {Boolean(timestamp) && (
          <Typography variant="body2" fontSize={12} color="textSecondary">
            {moment(timestamp).fromNow()}
          </Typography>
        )}
      </Stack>
      {Boolean(endUser) && Boolean(endUser?.name || endUser?.company) && (
        <>
          {Boolean(endUser?.name) && (
            <Typography variant="body2">{endUser?.name}</Typography>
          )}
          {Boolean(endUser?.company) && (
            <Typography variant="body2">{endUser?.company}</Typography>
          )}
        </>
      )}
      <CloseDetailButton
        onCloseDetail={(e) => {
          // June.so tracking
          analytics?.track(PI_IN_APP_NOTIFICATIONS_CLOSE, {
            pathname: window.location.pathname,
            status: true,
            timestamp: new Date().toISOString(),
            event_type: PI_IN_APP_NOTIFICATIONS_CLOSE,
            user_id: customerId,
            device: deviceDetails,
            summary: getSummary(
              PI_IN_APP_NOTIFICATIONS_CLOSE,
              String(customerId)
            ),
            view: window.location.pathname,
            subject: NOTIFICATION,
            object: {
              onboarded,
              notification: {
                title,
                message,
                timestamp,
                endUser,
              },
            },
            orgKey: orgToken,
          })
          handleCloseToast(e)
        }}
      />
    </Stack>
  )
}

InAppNotification.defaultProps = {
  message: '',
  timestamp: '',
  endUser: { name: '', company: '' },
}

export default InAppNotification
