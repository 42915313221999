import { Box, IconButton } from '@mui/material'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'

import React from 'react'
import SvgIconReactComponent from '../svg-icon-react-component'
import { BackIconComponent } from '../../../services/image-service'

export type BackButtonProps = {
  left?: number
  top?: number
  onBackBtnClick: () => void
}

function BackButton(props: BackButtonProps): JSX.Element {
  const { onBackBtnClick, left, top } = props
  return (
    <Box position="absolute" left={left} top={top}>
      <IconButton onClick={onBackBtnClick}>
        <SvgIconReactComponent
          width={22}
          height={22}
          background={() => 'transparent'}
        >
          <BackIconComponent />
        </SvgIconReactComponent>
        {/* <ArrowCircleLeftOutlinedIcon /> */}
      </IconButton>
    </Box>
  )
}

BackButton.defaultProps = {
  left: 0,
  top: 0,
}

export default BackButton
