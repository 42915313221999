import { Theme, alpha } from '@mui/material'

export type MuiTableRowCallbackProps = {
  theme: Theme
}

export const MuiTableRow = (palette: any): object => {
  return {
    styleOverrides: {
      root: ({ theme }: MuiTableRowCallbackProps) => ({
        '&:nth-of-type(even)': {
          backgroundColor: alpha(palette.secondary.main, 0.02),
        },
        '&:hover': {
          background: `linear-gradient(90deg, ${alpha(palette.secondary.main, 0)} 1.84%, ${alpha(palette.secondary.main, 0.19)} 28.06%)`,
        },
        'td:first-of-type': {
          borderTopLeftRadius: `${theme.shape.borderRadius}px`,
          borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
        },
        'td:last-of-type': {
          borderTopRightRadius: `${theme.shape.borderRadius}px`,
          borderBottomRightRadius: `${theme.shape.borderRadius}px`,
        },
      }),
    },
    defaultProps: {},
  }
}
