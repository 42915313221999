import { AxiosResponse } from 'axios'

import { API } from '../services/api'
import { SEARCH_END_USERS_ENDPOINT } from '../constants/api'
import { getResponse } from '../helpers/api-helpers'

export async function searchEndUsersList(
  search: string
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.get(`${SEARCH_END_USERS_ENDPOINT}`, {
      headers: { includeAuth: true },
      params: { search },
    })
  )
}
