import { AxiosResponse } from 'axios'
import { API } from '../services/api'
import {
  USER_PROFILE_ENDPOINT,
  POST_USER_PROFILE_PIC_ENDPOINT,
  POST_AGENT_ONLINE_STATUS_ENDPOINT,
} from '../constants/api'
import { camelToSnakeCase, getResponse } from '../helpers/api-helpers'

export async function getProfile(): Promise<any> {
  try {
    const response = await API.get(`${USER_PROFILE_ENDPOINT}`, {
      headers: { includeAuth: true },
    })
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updateProfile(
  data: any
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.put(USER_PROFILE_ENDPOINT, data, {
      headers: { includeAuth: true },
    })
  )
}

export async function updateProfilePic(
  file: File
): Promise<AxiosResponse<any, any>> {
  const formData = new FormData()
  formData.append('file', file)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      includeAuth: 'true',
    },
  }
  return API.post(POST_USER_PROFILE_PIC_ENDPOINT, formData, config)
    .then((response) => {
      // Handle the response
      return response
    })
    .catch((error) => {
      // Handle the error
      throw error
    })
}

export async function deleteProfilePic(): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.delete(POST_USER_PROFILE_PIC_ENDPOINT, {
      headers: { includeAuth: true },
    })
  )
}

export async function putAgentStatus(
  active: boolean,
  scheduledTime: string | null
): Promise<AxiosResponse<any, any>> {
  // TODO: Change the field names
  const data = camelToSnakeCase({
    active,
    scheduledTime,
  })
  return getResponse(
    API.post(`${POST_AGENT_ONLINE_STATUS_ENDPOINT}`, data, {
      headers: { includeAuth: true },
    })
  )
}
