export const MuiTextField = (palette: any): object => {
  return {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root, & .MuiInputLabel-root': {
          fontSize: 14,
        },
        '& .MuiInputLabel-root.MuiInputLabel-shrink': {
          // With the smaller font size, the shrink effect
          // shouldn't go quite as high ("-6px" instead of "-9px")
          // in order for it to look correct.
          transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .Mui-disabled': {
          WebkitTextFillColor: `${palette.text.primary} !important`,
          cursor: 'not-allowed !important',
        },
      },
    },
    defaultProps: {},
  }
}
