import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined'
import { Box } from '@mui/material'
import React from 'react'
import MeetingFooterBtn from './meeting-footer-btn'

function CallCameraToggle(): JSX.Element {
  const { meeting } = useDyteMeeting()

  const videoEnabled = useDyteSelector((m) => m.self.videoEnabled)

  const handleCallCameraToggle = async (): Promise<void> => {
    let response: void
    if (videoEnabled) {
      response = await meeting.self.disableVideo()
    } else {
      response = await meeting.self.enableVideo()
    }
    return response
  }

  return (
    <Box className="non-draggable">
      <MeetingFooterBtn
        btnMode={videoEnabled ? 'on' : 'off'}
        onBtnClick={() => handleCallCameraToggle()}
      >
        {videoEnabled ? <VideocamOutlinedIcon /> : <VideocamOffOutlinedIcon />}
      </MeetingFooterBtn>
    </Box>
  )
}

export default CallCameraToggle
