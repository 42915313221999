import { Theme, alpha } from '@mui/material'

export type MuiTabsCallbackProps = {
  theme: Theme
}

export const MuiTabs = (): object => {
  return {
    styleOverrides: {
      root: ({ theme }: MuiTabsCallbackProps) => ({
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        minHeight: 40,
        height: 40,
      }),
    },
    defaultProps: {},
  }
}
