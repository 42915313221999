import { trimStart } from 'lodash'
import PBNavLogo from '../assets/images/ping-base-nav-logo.svg'
import GreenTickMark from '../assets/images/green-tick-mark.svg'
import WavingHand from '../assets/images/Icons/waving-hand-icon.svg'
import EmptyStateVoiceNotesNotSelected from '../assets/images/EmptyStates/empty-state--voice-notes-not-selected.svg'
import EmptyStateNoNewActivity from '../assets/images/EmptyStates/empty-state--no-new-activity.svg'
import CallBack from '../assets/images/Icons/call-back-icon.svg'
import OutOfOffice from '../assets/images/Icons/out-of-office-icon.svg'
import { ReactComponent as LiveUsers } from '../assets/images/Icons/live-users-icon.svg'
import { ReactComponent as OpportunitiesUsers } from '../assets/images/Icons/opportunities-users-icon.svg'
import { ReactComponent as AllUsers } from '../assets/images/Icons/all-users-icon.svg'
import { ReactComponent as ConvertedUsers } from '../assets/images/Icons/converted-users-icon.svg'
import { ReactComponent as CallIcon } from '../assets/images/Icons/call-icon.svg'
import { ReactComponent as VideoCallIcon } from '../assets/images/Icons/video-call-icon.svg'
import { ReactComponent as MicIcon } from '../assets/images/Icons/mic-icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/images/Icons/delete-icon.svg'
import { ReactComponent as BackIcon } from '../assets/images/Icons/back-button-icon.svg'

import { ReactComponent as LogoutIcon } from '../assets/images/Icons/logout-icon.svg'
import { ReactComponent as SettingsIcon } from '../assets/images/Icons/settings-icon.svg'
import { ReactComponent as UserOutlined } from '../assets/images/Icons/user-outlined-icon.svg'

import { ReactComponent as CheckboxChecked } from '../assets/images/checkbox--checked.svg'
import { ReactComponent as CheckboxUnchecked } from '../assets/images/checkbox--unchecked.svg'
import { ReactComponent as TimelineCallHeld } from '../assets/images/Icons/TimelineIcons/call-held-icon.svg'
import { ReactComponent as TimelineCallScheduled } from '../assets/images/Icons/TimelineIcons/call-scheduled-icon.svg'
import { ReactComponent as TimelineLoggedIn } from '../assets/images/Icons/TimelineIcons/logged-in-icon.svg'
import { ReactComponent as TimelineMicIcon } from '../assets/images/Icons/TimelineIcons/mic-icon.svg'
import { ReactComponent as TimelineMissedCall } from '../assets/images/Icons/TimelineIcons/missed-call-icon.svg'
import { ReactComponent as TimelineDeclinedCall } from '../assets/images/Icons/TimelineIcons/declined-call-icon.svg'
import { ReactComponent as TimelineCheckIn } from '../assets/images/Icons/TimelineIcons/check-in-icon.svg'
import { ReactComponent as TimelineSessionRecording } from '../assets/images/Icons/TimelineIcons/session-recording-icon.svg'
import { ReactComponent as Calendar } from '../assets/images/Icons/calendar-icon.svg'
import { ReactComponent as MissedCall } from '../assets/images/Icons/missed-call-icon.svg'
import { ReactComponent as GridIcon } from '../assets/images/Icons/grid-icon.svg'
import { ReactComponent as LinkedIn } from '../assets/images/Icons/linked-in-icon.svg'

import { ReactComponent as LauncherSettings } from '../assets/images/Icons/launcher-settings-icon.svg'
import { ReactComponent as TeamDetails } from '../assets/images/Icons/team-details-icon.svg'
import { ReactComponent as BillIcon } from '../assets/images/Icons/bill-icon.svg'

import { ReactComponent as GradientTvIcon } from '../assets/images/Icons/gradient-tv-icon.svg'

import LoaderFullScreen from '../assets/images/Loaders/loader-full-screen.gif'

import { ReactComponent as NavBarHome } from '../assets/images/Icons/nav-bar-home-icon.svg'
import { ReactComponent as NavBarActivity } from '../assets/images/Icons/nav-bar-activity-icon.svg'

import ToolsVillaLauncherIcon from '../assets/images/Icons/toolsvilla_launcher.svg'

export const PingBaseNavLogo = PBNavLogo
export const GreenTickMarkImage = GreenTickMark
export const EmptyStateVoiceNotesNotSelectedImage =
  EmptyStateVoiceNotesNotSelected
export const EmptyStateNoNewActivityImage = EmptyStateNoNewActivity

// Icons
export const WavingHandIcon = WavingHand
export const CheckboxCheckedIconComponent = CheckboxChecked
export const CheckboxUncheckedIconComponent = CheckboxUnchecked
export const CallBackIcon = CallBack
export const OutOfOfficeIcon = OutOfOffice
export const LiveUsersIcon = LiveUsers
export const OpportunitiesUsersIcon = OpportunitiesUsers
export const AllUsersIcon = AllUsers
export const ConvertedUsersIcon = ConvertedUsers
export const CallIconComponent = CallIcon
export const VideoCallIconComponent = VideoCallIcon
export const MicIconComponent = MicIcon
export const DeleteIconComponent = DeleteIcon
export const CalendarIconComponent = Calendar
export const MissedCallIconComponent = MissedCall
export const BackIconComponent = BackIcon
export const LinkedInIconComponent = LinkedIn
export const LogoutIconComponent = LogoutIcon
export const SettingsIconComponent = SettingsIcon
export const UserOutlinedComponent = UserOutlined

// Timeline Icons
export const TimelineCallHeldIconComponent = TimelineCallHeld
export const TimelineCallScheduledIconComponent = TimelineCallScheduled
export const TimelineLoggedInIconComponent = TimelineLoggedIn
export const TimelineMicIconComponent = TimelineMicIcon
export const TimelineMissedCallIconComponent = TimelineMissedCall
export const TimelineDeclinedCallIconComponent = TimelineDeclinedCall
export const TimelineCheckInIconComponent = TimelineCheckIn
export const TimelineSessionRecordingIconComponent = TimelineSessionRecording
// Settings Icons
export const LauncherSettingsIconComponent = LauncherSettings
export const TeamDetailsIconComponent = TeamDetails
export const BillIconComponent = BillIcon

// Activity Icons
export const GridIconComponent = GridIcon

// Loaders
export const LoaderFullScreenGif = LoaderFullScreen

// NavBar Icons
export const NavBarHomeIconComponent = NavBarHome
export const NavBarActivityIconComponent = NavBarActivity

// Gradient Icons
export const GradientTvIconComponent = GradientTvIcon

// Toolsvilla
export const ToolsVillaLauncherIconComponent = ToolsVillaLauncherIcon

const AZURE_STORAGE_URL =
  'https://pingbaselrsstorage.blob.core.windows.net/pingbase-client/assets/'
const getAzureImage = (path: string): string =>
  `${AZURE_STORAGE_URL}${trimStart(path, '/')}`
// Images
export const OnboardingSidebarImage = getAzureImage(
  'images/common/onboarding_sidebar.svg'
)
export const EmailSentSuccessImage = getAzureImage(
  'images/common/email_sent_success.svg'
)
export const ResetPasswordSuccessImage = getAzureImage(
  'images/common/reset_password_success.svg'
)
export const OnboardingInviteSent = getAzureImage(
  'images/common/email_sent_v1.svg'
)
export const OnboardingLauncherAvatar1 = getAzureImage(
  'videos/widget/ManWaving.mp4'
)
export const OnboardingLauncherAvatar2 = getAzureImage(
  'videos/widget/WomanWaving.mp4'
)
export const SlackIconImage = getAzureImage('images/common/slack.svg')
export const GoogleCalendarIconImage = getAzureImage(
  'images/common/google_calendar.svg'
)
export const OutlookCalendarIconImage = getAzureImage(
  'images/common/outlook.svg'
)
export const CheckInInit = getAzureImage(
  'images/onboarding/check-in-preview-init.svg'
)
export const CheckInWithSkip = getAzureImage(
  'images/onboarding/check-in_with_skip.png'
)
export const CheckInWithoutSkip = getAzureImage(
  'images/onboarding/check-in_without_skip.png'
)
