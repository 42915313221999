import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useProfileStore from '../../state/profile-store'
import ResetPasswordInternalForm from './reset-password-form'
import MuiComponents from '../../material-ui/mui-test/mui-components'
import LeftAndFooterLayout from '../partials/Layout/left-and-footer-layout'
import BackButton from '../partials/Onboarding/back-button'
import useAuthStore from '../../state/auth-store'
import { resetPasswordAdoch } from '../../side-effects/reset-password'
import useResetPasswordStore from '../../state/reset-password'
import { getDeviceDetails } from '../../helpers/browser'
import { getSummary } from '../../helpers/june-helpers'
import {
  PI_RESET_PASSWORD_INTERNAL_REQUEST,
  PI_RESET_PASSWORD_INTERNAL_SUCCESS,
  PI_RESET_PASSWORD_INTERNAL_FAILURE,
  PROFILE,
} from '../../constants/june_so'
import useJuneAnalyticsStore from '../../state/june-store'

function ResetPasswordInternalInit(): JSX.Element {
  const navigate = useNavigate()
  const { isLoading, setIsLoading } = useResetPasswordStore()
  // const isLoading = useResetPasswordStore((state) => state.isLoading)
  // const setIsLoading = useResetPasswordStore((state) => state.setIsLoading)
  // const setLoggedIn = useProfileStore((state) => state.setLoggedIn)

  // const [errorLoggedIn, setErrorLoggedIn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // const setIsAuthLoading = useAuthStore((state) => state.setIsAuthLoading)

  // const setAuth = useAuthStore((state) => state.setAuth)

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { userInfo, organization } = useProfileStore.getState()
  const { token } = organization
  const { id, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  const onSubmit = (email: string): void => {
    try {
      setIsLoading(true)
      // JUNE.SO Tracking
      const response = resetPasswordAdoch(email).then((res) => {
        if (res.success !== true) {
          setIsLoading(false)
          setErrorMessage(res.data.Error)
          analytics?.track(PI_RESET_PASSWORD_INTERNAL_FAILURE, {
            pathname: window.location.pathname,
            status: true,
            timestamp: new Date().toISOString(),
            event_type: PI_RESET_PASSWORD_INTERNAL_FAILURE,
            user_id: id,
            device: deviceDetails,
            summary: getSummary(PI_RESET_PASSWORD_INTERNAL_FAILURE, String(id)),
            view: window.location.pathname,
            subject: PROFILE,
            object: {
              onboarded,
            },
            orgKey: token,
          })
        } else {
          analytics?.track(PI_RESET_PASSWORD_INTERNAL_SUCCESS, {
            pathname: window.location.pathname,
            status: true,
            timestamp: new Date().toISOString(),
            event_type: PI_RESET_PASSWORD_INTERNAL_SUCCESS,
            user_id: id,
            device: deviceDetails,
            summary: getSummary(PI_RESET_PASSWORD_INTERNAL_SUCCESS, String(id)),
            view: window.location.pathname,
            subject: PROFILE,
            object: {
              onboarded,
            },
            orgKey: token,
          })
          setIsLoading(false)
          navigate('/reset-password-done')
        }
      })
    } catch (error: any) {
      setIsLoading(false)
      setErrorMessage('Something went wrong. Please try again.')
    }
  }

  const authorized = useAuthStore((state) => state.getAuth())
  const handleBackBtnClick = (): void => {
    if (authorized) {
      navigate('/profile')
    } else {
      navigate('/')
    }
  }

  return (
    <Stack
      pl={8}
      pt={7}
      pb={1}
      direction="row"
      alignItems="start"
      height="100%"
      overflow="hidden auto"
    >
      <Box pl={12} pt={0.4} position="relative" width="100%">
        <BackButton onBackBtnClick={handleBackBtnClick} />
        <Typography variant="h5">New Password</Typography>
        <Stack mt={8} maxWidth={450} width="100%">
          <ResetPasswordInternalForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            errorMessage={errorMessage}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export default ResetPasswordInternalInit
