import {
  Box,
  Button,
  Collapse,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import { get } from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useMemo } from 'react'
import useProfileStore from '../../../state/profile-store'
import useAuthStore from '../../../state/auth-store'
import {
  ONBOARDING_PATH,
  SETTINGS_PATH,
  USER_PROFILE_PATH,
} from '../../../constants/paths'
import OnlineOfflineIndicator from '../online-offline-indicator'
import { getDeviceDetails } from '../../../helpers/browser'
import { getSummary } from '../../../helpers/june-helpers'
import {
  PI_PROFILE_DROPDOWN_LOGOUT,
  PI_PROFILE_DROPDOWN_PROFILE,
  PI_PROFILE_DROPDOWN_SETTINGS,
  PI_PROFILE_DROPDOWN,
  PROFILE,
} from '../../../constants/june_so'
import useJuneAnalyticsStore from '../../../state/june-store'
import SvgIconReactComponent from '../svg-icon-react-component'
import {
  LogoutIconComponent,
  SettingsIconComponent,
  UserOutlinedComponent,
} from '../../../services/image-service'
import useCommonStore from '../../../state/common-store'
import DisabledWrapper from '../Wrappers/disabled-wrapper'

function ProfileDropdown(): JSX.Element {
  const loggedIn = useProfileStore((state) => state.loggedIn)
  const setLoggedIn = useProfileStore((state) => state.setLoggedIn)
  const getUserInfo = useProfileStore((state) => state.getUserInfo)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState(false)
  const [redirect, setRedirect] = React.useState<null | string>(null)
  const [statusDropdownOpen, setStatusDropdownOpen] = React.useState(false)

  const userInfo = useProfileStore((state) => state.userInfo)

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { organization } = useProfileStore.getState()
  const { token } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
    analytics?.track(PI_PROFILE_DROPDOWN, {
      pathname: window.location.pathname,
      status: true,
      timestamp: new Date().toISOString(),
      event_type: PI_PROFILE_DROPDOWN,
      user_id: customerId,
      device: deviceDetails,
      summary: getSummary(PI_PROFILE_DROPDOWN, String(customerId)),
      view: window.location.pathname,
      subject: PROFILE,
      object: {
        onboarded,
      },
      orgKey: token,
    })
  }
  const handleClose = (): void => {
    // setAnchorEl(null)
    setOpen(false)
    setStatusDropdownOpen(false)
  }
  const handleStatusDropdownClick = (): void => {
    // setStatusDropdownOpen(!statusDropdownOpen)
  }
  const navigate = useNavigate()
  const location = useLocation()
  const pathsToExclude = [ONBOARDING_PATH]

  useEffect(() => {
    if (redirect) {
      navigate(redirect)
    }
    setRedirect(null)
  }, [open])

  const handleProfileClick = (): void => {
    analytics?.track(PI_PROFILE_DROPDOWN_PROFILE, {
      pathname: window.location.pathname,
      status: true,
      timestamp: new Date().toISOString(),
      event_type: PI_PROFILE_DROPDOWN_PROFILE,
      user_id: customerId,
      device: deviceDetails,
      summary: getSummary(PI_PROFILE_DROPDOWN_PROFILE, String(customerId)),
      view: window.location.pathname,
      subject: PROFILE,
      object: {
        onboarded,
      },
      orgKey: token,
    })
    handleClose()
    setRedirect(USER_PROFILE_PATH)
  }

  const handleSettingsClick = (): void => {
    analytics?.track(PI_PROFILE_DROPDOWN_SETTINGS, {
      pathname: window.location.pathname,
      status: true,
      timestamp: new Date().toISOString(),
      event_type: PI_PROFILE_DROPDOWN_SETTINGS,
      user_id: customerId,
      device: deviceDetails,
      summary: getSummary(PI_PROFILE_DROPDOWN_SETTINGS, String(customerId)),
      view: window.location.pathname,
      subject: PROFILE,
      object: {
        onboarded,
      },
      orgKey: token,
    })
    handleClose()
    setRedirect(SETTINGS_PATH)
  }

  // const getUserInfo = useProfileStore((state) => state.getUserInfo)

  // useEffect(() => {
  //   // get the userinfo if not present
  //   if (loggedIn && !userInfo?.email) {
  //     getUserInfo()
  //   }
  // }, [loggedIn])

  const logout = useAuthStore((state) => state.logout)

  const isAgentOnline = useProfileStore((state) => state.userInfo.isAgentOnline)
  const setAgentOnlineStatus = useProfileStore(
    (state) => state.setAgentOnlineStatus
  )

  const handleStatusChange = (
    active: boolean,
    timeDelta: number | null
  ): void => {
    // setAgentOnlineStatus(active, timeDelta)
    handleClose()
  }

  const { banners } = useProfileStore((state) => state.organization)
  const handleSetToActive = (): void => {
    // if (
    //   banners?.length &&
    //   banners.filter(
    //     (bannerObj) => bannerObj.bannerType === 'ooo' && bannerObj.isActive
    //   )?.length
    // ) {
    //   handleClose()
    //   useCommonStore.setState({ agentStatusBannerDropdownOpen: true })
    // } else {
    //   handleStatusChange(true, null)
    // }
  }

  // Calculate the milliseconds until tomorrow
  const getUntilTomorrowDelta = (): number => {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(now.getDate() + 1)
    tomorrow.setHours(0, 0, 0, 0)
    return tomorrow.getTime() - now.getTime()
  }

  const setActiveStatusComp = (): JSX.Element[] => {
    if (!isAgentOnline) {
      return [
        <DisabledWrapper isDisabled>
          <MenuItem disabled onClick={handleSetToActive} sx={{ py: 1.5 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Box
                width={12}
                height={12}
                borderRadius={1}
                sx={{ background: ({ palette }) => palette.primary.main }}
              />
              <Typography variant="body2" mr={1.5} flex={1}>
                Set yourself as active
              </Typography>
            </Stack>
          </MenuItem>
        </DisabledWrapper>,
      ]
    }
    if (
      banners?.length &&
      banners.filter(
        (bannerObj) => bannerObj.bannerType === 'ooo' && bannerObj.isActive
      )?.length
    ) {
      return [
        <DisabledWrapper isDisabled>
          <MenuItem
            onClick={() => handleStatusChange(false, null)}
            disabled
            sx={{ py: 1.5 }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Box
                width={12}
                height={12}
                borderRadius={1}
                sx={{ background: '#D9D9D9' }}
              />
              <Typography variant="body2" mr={1.5} flex={1}>
                Set yourself as busy
              </Typography>
            </Stack>
          </MenuItem>
        </DisabledWrapper>,
      ]
    }
    return [
      <DisabledWrapper isDisabled>
        <MenuItem onClick={handleStatusDropdownClick} sx={{ py: 1.5 }} disabled>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Box
              width={12}
              height={12}
              borderRadius={1}
              sx={{ background: '#D9D9D9' }}
            />
            <Typography variant="body2" mr={1.5} flex={1}>
              Set yourself as busy
            </Typography>
            {statusDropdownOpen ? (
              <ExpandLessRoundedIcon fontSize="small" />
            ) : (
              <ExpandMoreRoundedIcon fontSize="small" />
            )}
          </Stack>
        </MenuItem>
      </DisabledWrapper>,
      <Collapse in={statusDropdownOpen} timeout="auto" unmountOnExit>
        <MenuList>
          <MenuItem
            onClick={() => handleStatusChange(false, 10 * 60 * 1000)}
            sx={{ pl: 4.5 }}
          >
            <Typography variant="body2">For 10 mins</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => handleStatusChange(false, 30 * 60 * 1000)}
            sx={{ pl: 4.5 }}
          >
            <Typography variant="body2">For 30 mins</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => handleStatusChange(false, 60 * 60 * 1000)}
            sx={{ pl: 4.5 }}
          >
            <Typography variant="body2">For 1 hour</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => handleStatusChange(false, 2 * 60 * 60 * 1000)}
            sx={{ pl: 4.5 }}
          >
            <Typography variant="body2">For 2 hours</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => handleStatusChange(false, getUntilTomorrowDelta())}
            sx={{ pl: 4.5 }}
          >
            <Typography variant="body2">Until tomorrow</Typography>
          </MenuItem>
        </MenuList>
      </Collapse>,
    ]
  }

  return loggedIn ? (
    <Box>
      <Button
        variant="outlined"
        color="inherit"
        id="profile-dropdown"
        aria-controls={open ? 'profile-dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          borderColor: ({ palette }) => alpha(palette.text.primary, 0.24),
          '&:hover': {
            borderColor: ({ palette }) => alpha(palette.text.primary, 0.38),
          },
          height: '40px',
          px: 2,
          minWidth: 160,
          justifyContent: 'space-between',
          borderRadius: 100,
        }}
        onClick={handleClick}
        endIcon={
          open ? (
            <KeyboardArrowDownIcon sx={{ transform: 'rotate(180deg)' }} />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
      >
        <Stack direction="row" spacing={1} alignItems="center" flex={1}>
          <OnlineOfflineIndicator isOnline={isAgentOnline} isTabActive />
          <Typography variant="body2" fontWeight={500} noWrap>
            {userInfo?.firstName ? `${userInfo?.firstName}` : 'Profile'}
          </Typography>
        </Stack>
      </Button>
      <Menu
        id="profile-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          'aria-labelledby': 'profile-dropdown',
          sx: {
            width: `max(${(anchorEl && anchorEl.offsetWidth) || 160}, "fit-content")`,
            padding: 0,
          },
        }}
        slotProps={{
          paper: {
            sx: { minWidth: 160 },
          },
        }}
      >
        {!pathsToExclude.includes(location.pathname) && setActiveStatusComp()}
        {/* <MenuItem onClick={handleClose}>Set yourself as Away</MenuItem> */}
        {!pathsToExclude.includes(location.pathname) && [
          <Divider sx={{ margin: '0px !important' }} />,
          <MenuItem onClick={handleProfileClick} sx={{ py: 1.5 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <SvgIconReactComponent>
                <UserOutlinedComponent />
              </SvgIconReactComponent>
              <Typography variant="body2">Profile</Typography>
            </Stack>
          </MenuItem>,
          <MenuItem onClick={handleSettingsClick} sx={{ py: 1.5 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <SvgIconReactComponent>
                <SettingsIconComponent />
              </SvgIconReactComponent>
              <Typography variant="body2">Settings</Typography>
            </Stack>
          </MenuItem>,
        ]}
        <MenuItem
          onClick={() => {
            // June.so Event
            analytics?.track(PI_PROFILE_DROPDOWN_LOGOUT, {
              pathname: window.location.pathname,
              status: true,
              timestamp: new Date().toISOString(),
              event_type: PI_PROFILE_DROPDOWN_LOGOUT,
              user_id: customerId,
              device: deviceDetails,
              summary: getSummary(
                PI_PROFILE_DROPDOWN_LOGOUT,
                String(customerId)
              ),
              view: window.location.pathname,
              subject: PROFILE,
              object: {
                onboarded,
              },
              orgKey: token,
            })
            handleClose()
            logout()
          }}
          sx={{ py: 1.5 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <SvgIconReactComponent>
              <LogoutIconComponent />
            </SvgIconReactComponent>
            <Typography variant="body2">Logout</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <Box />
  )
}

export default ProfileDropdown
