import { create } from 'zustand'
import { AnalyticsBrowser } from '@june-so/analytics-next'
import { JuneStoreState, JuneStoreType } from './june-store.type'
import Envs from '../../services/envs'

const juneStoreState: JuneStoreState = {
  analytics: undefined,
}
export const useJuneAnalyticsStore = create<JuneStoreType>((set, get) => ({
  ...juneStoreState,

  loadAnalytics: async () => {
    const { analytics } = get()
    if (!analytics) {
      const writeKey = Envs.JUNE_SO_WRITE_KEY
      const response = await AnalyticsBrowser.load({ writeKey })
      set({ analytics: response[0] })
    }
  },

  getAnalytics: () => get().analytics,
}))
