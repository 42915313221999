export const MuiFormControlLabel = (palette: any): object => {
  return {
    styleOverrides: {
      root: {},
      asterisk: {
        display: 'none',
      },
    },
    defaultProps: {},
  }
}
