import { Grid } from '@mui/material'
import React from 'react'

export type ParticipantDetails = {
  id: string
  name: string
  picture?: string
  isSelf?: boolean
  videoEnabled: boolean
  videoTrack?: MediaStreamTrack
  audioEnabled: boolean
  audioTrack?: MediaStreamTrack
  screenShareEnabled: boolean
  screenShareTracks: {
    audio?: MediaStreamTrack
    video?: MediaStreamTrack
  }
}

export type CallGridProps = {
  participants: ParticipantDetails[]
  ringingVideoParticipantTile?: JSX.Element | null
  offlineParticipantTile?: JSX.Element | null
  videoParticipantTileNode: (participant: ParticipantDetails) => JSX.Element
  screenShareParticipant: null | ParticipantDetails
  screenShareViewNode: (participant: ParticipantDetails) => JSX.Element
}

function CallGrid(props: CallGridProps): JSX.Element {
  const {
    participants,
    ringingVideoParticipantTile,
    offlineParticipantTile,
    videoParticipantTileNode,
    screenShareParticipant,
    screenShareViewNode,
  } = props

  let extraTiles = 0
  if (ringingVideoParticipantTile && offlineParticipantTile) {
    extraTiles = 2
  } else if (ringingVideoParticipantTile || offlineParticipantTile) {
    extraTiles = 1
  }

  const participantsLength = participants.length + extraTiles

  return (
    <Grid container columns={12} spacing={1} sx={{ width: '100%' }}>
      {screenShareParticipant ? (
        <>
          <Grid item xs={8}>
            {screenShareParticipant &&
              screenShareViewNode(screenShareParticipant)}
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {Boolean(ringingVideoParticipantTile) && (
              <Grid item xs={12} display="flex" alignItems="center">
                {ringingVideoParticipantTile}
              </Grid>
            )}
            {Boolean(offlineParticipantTile) && (
              <Grid item xs={12} display="flex" alignItems="center">
                {offlineParticipantTile}
              </Grid>
            )}
            {participants?.map((participant) => (
              <Grid
                item
                xs={12}
                key={`call-grid-${participant.id}`}
                display="flex"
                alignItems="center"
              >
                {videoParticipantTileNode(participant)}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          {Boolean(ringingVideoParticipantTile) && (
            <Grid item xs={12 / participantsLength}>
              {ringingVideoParticipantTile}
            </Grid>
          )}
          {Boolean(offlineParticipantTile) && (
            <Grid item xs={12 / participantsLength}>
              {offlineParticipantTile}
            </Grid>
          )}
          {participants?.map((participant) => (
            <Grid
              item
              xs={12 / participantsLength}
              key={`call-grid-${participant.id}`}
            >
              {videoParticipantTileNode(participant)}
            </Grid>
          ))}
        </>
      )}
    </Grid>
  )
}

CallGrid.defaultProps = {
  ringingVideoParticipantTile: null,
  offlineParticipantTile: null,
}

export default CallGrid
