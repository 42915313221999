import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'

const root = ReactDOM.createRoot(
  document.getElementById('ping-base') as HTMLElement
)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
