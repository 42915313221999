// Import Pusher
import Pusher from 'pusher-js'
import Envs from '../services/envs'
import { PUSHER_AUTH_ENDPOINT } from '../constants/api'

const { API_VERSION, API_BASE_URL } = Envs

class PusherSingleton {
  private static instance: PusherSingleton

  private client: Pusher

  private PUSHER_APP_KEY: string

  private PUSHER_APP_CLUSTER: string

  private USER_TOKEN: string

  constructor(
    PUSHER_APP_KEY: string,
    PUSHER_APP_CLUSTER: string,
    USER_TOKEN: string
  ) {
    this.PUSHER_APP_KEY = PUSHER_APP_KEY
    this.PUSHER_APP_CLUSTER = PUSHER_APP_CLUSTER
    this.USER_TOKEN = USER_TOKEN
    if (!PusherSingleton.instance) {
      // Initialize the Pusher client here
      this.client = new Pusher(this.PUSHER_APP_KEY, {
        cluster: this.PUSHER_APP_CLUSTER,
        authEndpoint: `${PUSHER_AUTH_ENDPOINT}`,
        auth: {
          headers: {
            'X-User-Token': this.USER_TOKEN,
          },
        },
      })
      console.log(this.client)
      PusherSingleton.instance = this
    }
  }

  public static getClient(
    PUSHER_APP_KEY: string,
    PUSHER_APP_CLUSTER: string,
    USER_TOKEN: string
  ): Pusher {
    if (!this.instance) {
      this.instance = new PusherSingleton(
        PUSHER_APP_KEY,
        PUSHER_APP_CLUSTER,
        USER_TOKEN
      )
    }
    return this.instance.client
  }
}

export { PusherSingleton }

export const getPusherChannel = (
  companyName: string,
  endUserId: string
): string => {
  return `private-${companyName}-${endUserId}`
}
