import { alpha } from '@mui/material'

export const MuiAppBar = (palette: any): object => {
  return {
    styleOverrides: {
      root: {
        background: palette.background.paper,
        color: palette.text.primary,
        padding: '16px 32px',
        borderBottom: `1px solid ${alpha(palette.common.black, 0.12)}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        minHeight: 72,
      },
    },
    defaultProps: {
      elevation: 0,
    },
  }
}
