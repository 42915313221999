import { Box, Button, Stack, Typography } from '@mui/material'
import { ToastContainer, ToastItem, toast } from 'react-toastify'
import React, { useEffect } from 'react'
import Header from '../Header/header'
import MainContent from './main-content'
import Meeting from '../../Meeting'
import useProfileStore from '../../../state/profile-store'
import LiveUsersPusher from '../../Pusher/LiveUsersPusher'
import EventPusher from '../../Pusher/EventPusher'
import InteractionWidgetPusher from '../../Pusher/InteractionWidgetPusher'
import useNotificationStore from '../../../state/notifications-store'
import ClearAllButton from '../InAppNotification/clear-all-button'
import TopBanners from '../TopBanner/top-banners'

export type LayoutProps = {
  children: React.ReactNode
}

function Layout(props: LayoutProps): JSX.Element {
  const { children } = props
  const loggedIn = useProfileStore((state) => state.loggedIn)
  const { name: organizationName } = useProfileStore(
    (state) => state.organization
  )

  useEffect(() => {
    if (!loggedIn) {
      toast.dismiss({ containerId: 'in-app-notification-container' })
    }
  }, [loggedIn])

  const notifications = useNotificationStore((state) => state.notifications)
  const removeNotification = useNotificationStore(
    (state) => state.removeNotification
  )

  useEffect(() => {
    const changeListener = toast.onChange((payload: ToastItem) => {
      if (payload.status === 'added') {
        if (notifications.length === 5) {
          const oldestNotification = notifications[0]
          removeNotification(oldestNotification.id)
          toast.dismiss({
            containerId: 'in-app-notification-container',
            id: oldestNotification.id,
          })
        } else if (notifications.length > 5) {
          const notificationsToRemove = notifications.slice(0, -5)
          notificationsToRemove.forEach((notification) => {
            removeNotification(notification.id)
            toast.dismiss({
              containerId: 'in-app-notification-container',
              id: notification.id,
            })
          })
        }
      } else if (payload.status === 'removed') {
        if (loggedIn) {
          removeNotification(payload.id as string)
        }
      }
    })
    return () => {
      changeListener()
    }
  }, [notifications, loggedIn])

  return (
    <Stack direction="column" height="100%">
      {/* <TopBanners /> */}
      <Header />
      <MainContent>{children}</MainContent>
      <Meeting />
      <ClearAllButton />
      <ToastContainer
        stacked
        autoClose={false}
        closeButton={false}
        hideProgressBar
        // newestOnTop
        containerId="in-app-notification-container"
      />
      {loggedIn && organizationName && (
        <>
          <LiveUsersPusher />
          <EventPusher />
          <InteractionWidgetPusher />
        </>
      )}
    </Stack>
  )
}

export default Layout
