import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Layout from '../components/partials/Layout/layout'
import useProfileStore from '../state/profile-store'
import FullScreenLoader from '../components/partials/Loaders/full-screen-loader'

// export type PublicRoutesProps = {
//   loggedIn: boolean
//   isProfileLoading: boolean
// }

export function PublicRoutes(): JSX.Element {
  // props: PublicRoutesProps
  // const { loggedIn, isProfileLoading } = props
  // Login
  const loggedIn = useProfileStore((state) => state.loggedIn)
  const isProfileLoading = useProfileStore((state) => state.isUserInfoLoading)

  if (isProfileLoading) {
    return <FullScreenLoader />
  }

  return loggedIn ? (
    <Navigate to="/dashboard" />
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  )
}
