import { Theme } from '@mui/material'

export const MuiSlider = (): object => {
  return {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        height: 6,
        '& .MuiSlider-thumb': {
          display: 'none',
          // height: 0,
          // width: 0,
          // '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          //   boxShadow: 'inherit',
          // },
          // '&::before': {
          //   display: 'none',
          // },
        },
        '& .MuiSlider-track': {
          border: 'none',
          height: 6,
          minWidth: 6,
        },
      }),
    },
    defaultProps: {},
  }
}
