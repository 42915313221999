import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { AuthStoreType, Auth, AuthStoreState } from './auth-store.type'
import useMeetingStore from '../meeting-store'
import { resetAllStores } from '../zustand-config'
import useProfileStore from '../profile-store'

const authStoreState = {
  auth: {
    accessToken: '',
    refreshToken: '',
    email: '',
  },
  isAuthLoading: false,
}

export const useAuthStore = create<AuthStoreType>()(
  persist(
    (set, get) => ({
      ...authStoreState,
      setAuth: (
        accessToken: AuthStoreState['auth']['accessToken'],
        refreshToken: AuthStoreState['auth']['refreshToken'],
        email: AuthStoreState['auth']['email']
      ) => {
        set({ auth: { accessToken, refreshToken, email } })
      },
      getAuth: () => {
        const auth = localStorage.getItem('auth')
        return auth ? JSON.parse(auth) : null
      },
      logout: () => {
        const { loggedIn, setLoggedIn, setIsUserInfoLoading } =
          useProfileStore.getState()
        resetAllStores()
        if (loggedIn) {
          setLoggedIn(false)
        }
        setIsUserInfoLoading(false)
        // try {
        //   window.localStorage.clear()
        // } catch (e) {
        //   console.log(e)
        // }
      },
      setIsAuthLoading: (isAuthLoading: AuthStoreState['isAuthLoading']) => {
        set({ isAuthLoading })
      },
      resetAuthStore: () => {
        set(authStoreState)
      },
    }),

    {
      name: 'auth', // name of the item in the local storage
      partialize: (state) => {
        const { auth } = state
        return { auth }
      },
    }
  )
)
