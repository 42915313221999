import { Avatar, Box, Stack, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import React, { useEffect } from 'react'

export type CallVideoParticipantTileProps = {
  dimensions?: {
    width?: number | string
    height?: number | string
    aspectRatio?: number | string
  }
  borderRadius?: 'rounded' | 'square' | 'extraRounded'
  participantId: string
  participantName?: string
  participantPicture?: string
  isSelf?: boolean
  videoEnabled: boolean
  videoTrack?: MediaStreamTrack
  audioEnabled: boolean
  audioTrack?: MediaStreamTrack
  hideBottomLabel?: boolean
}

function CallVideoParticipantTile(
  props: CallVideoParticipantTileProps
): JSX.Element {
  const {
    dimensions,
    participantId,
    participantName,
    participantPicture,
    isSelf,
    videoEnabled,
    videoTrack,
    audioEnabled,
    // Can be used for audio visualiser
    audioTrack,
    borderRadius,
    hideBottomLabel,
  } = props

  const borderStyles = {
    rounded: '8px',
    square: '0px',
    extraRounded: '16px',
  }

  useEffect(() => {
    if (participantId && videoEnabled && videoTrack) {
      const videoElem = document.getElementById(
        `video-${participantId}`
      ) as HTMLVideoElement
      const stream = new MediaStream()
      stream.addTrack(videoTrack)
      videoElem.srcObject = stream
    }
  }, [videoEnabled, videoTrack, participantId])

  useEffect(() => {
    if (participantId && !isSelf && audioEnabled && audioTrack) {
      const audioElem = document.getElementById(
        `audio-${participantId}`
      ) as HTMLAudioElement
      const stream = new MediaStream()
      stream.addTrack(audioTrack)
      audioElem.srcObject = stream
    }
  }, [isSelf, audioEnabled, audioTrack, participantId])

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        background: ({ palette }) =>
          isSelf
            ? `${alpha(palette.secondary.main, 0.12)}`
            : `${alpha(palette.primary.main, 0.12)}`,
        ...dimensions,
        borderRadius: borderRadius && borderStyles[borderRadius],
      }}
    >
      {audioEnabled && !isSelf && (
        <Box
          sx={{ width: '100%', objectFit: 'cover' }}
          component="audio"
          id={`audio-${participantId}`}
          autoPlay
          playsInline
        />
      )}
      {videoEnabled ? (
        <Box
          sx={{ width: '100%', objectFit: 'cover' }}
          component="video"
          id={`video-${participantId}`}
          autoPlay
          playsInline
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
          }}
        >
          <Box height="40%" sx={{ aspectRatio: 1, margin: 'auto' }}>
            <Avatar
              src={participantPicture || 'img-url'}
              alt={participantName}
              sx={{
                color: ({ palette }) => palette.text.primary,
                backgroundColor: ({ palette }) => palette.background.paper,
                width: '100%',
                height: '100%',
                aspectRatio: 1,
              }}
            />
          </Box>
        </Box>
      )}

      {!hideBottomLabel && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '4px',
            display: 'flex',
            color: ({ palette }) => palette.common.white,
          }}
        >
          <Stack
            direction="row"
            sx={{
              background: ({ palette }) => alpha(palette.common.black, 0.5),
              maxWidth: '50%',
              p: 0.5,
              borderRadius: ({ shape }) => `${shape.borderRadius}px`,
            }}
          >
            {audioEnabled ? (
              <MicIcon fontSize="small" />
            ) : (
              <MicOffIcon fontSize="small" />
            )}
            {participantName && (
              <Typography variant="subtitle2" noWrap>
                {participantName}
                {isSelf && ' (You)'}
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  )
}

CallVideoParticipantTile.defaultProps = {
  dimensions: {
    width: 200,
    aspectRatio: '16 / 9',
  },
  participantName: '',
  participantPicture: null,
  isSelf: false,
  borderRadius: 'rounded',
  videoTrack: undefined,
  audioTrack: undefined,
  hideBottomLabel: false,
}

export default CallVideoParticipantTile
