import React from 'react'
import {
  IconButton,
  InputBase,
  Paper,
  AppBar,
  Toolbar,
  TextField,
  Stack,
  alpha,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useLocation, useNavigate } from 'react-router-dom'
import useSearchStore from '../../../state/search-bar-store'
import useProfileStore from '../../../state/profile-store'
import { ONBOARDING_PATH, SEARCH_PATH } from '../../../constants/paths'
import { getDeviceDetails } from '../../../helpers/browser'
import { getSummary } from '../../../helpers/june-helpers'
import { PI_SEARCH, PI_SEARCH_CLEAR, HEADER } from '../../../constants/june_so'
import useJuneAnalyticsStore from '../../../state/june-store'

export default function SearchBar(): JSX.Element | null {
  const loggedIn = useProfileStore((state) => state.loggedIn)
  const location = useLocation()
  const pathsToExclude = [ONBOARDING_PATH]

  const searchText = useSearchStore((state) => state.searchText)
  const setSearchText = useSearchStore((state) => state.setSearchText)
  const clearSearchText = useSearchStore((state) => state.clearSearchText)
  const setShouldFetchSearchResults = useSearchStore(
    (state) => state.setShouldFetchSearchResults
  )

  const navigate = useNavigate()

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchText(event.target.value)
    // console.log('event.target.value', event.target.value)
  }

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { organization, userInfo } = useProfileStore.getState()
  const { token } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  const handleClearSearch = (): void => {
    // June.so Event
    analytics?.track(PI_SEARCH_CLEAR, {
      pathname: window.location.pathname,
      status: true,
      timestamp: new Date().toISOString(),
      event_type: PI_SEARCH_CLEAR,
      user_id: customerId,
      device: deviceDetails,
      summary: getSummary(PI_SEARCH_CLEAR, String(customerId)),
      view: window.location.pathname,
      subject: HEADER,
      object: {
        onboarded,
        search: searchText,
      },
      orgKey: token,
    })
    clearSearchText()
  }

  if (!loggedIn || pathsToExclude.includes(location.pathname)) {
    return null
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <Stack
        direction="row"
        width="100%"
        height="100%"
        alignItems="center"
        p={0.25}
        borderRadius={100}
        maxWidth={280}
        spacing={1}
        sx={{
          background: ({ palette }) =>
            `linear-gradient(270deg, ${alpha(palette.secondary.main, 0.05)} 2.49%, ${alpha(palette.secondary.main, 0.012)} 99.87%)`,
        }}
      >
        <IconButton
          onClick={() => {
            if (searchText) {
              navigate(SEARCH_PATH)
              setShouldFetchSearchResults(true)
            }
          }}
          color="inherit"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ flex: 1, fontSize: 14 }}
          placeholder="Search phone or email"
          value={searchText}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              if (searchText) {
                // June.so Event
                analytics?.track(PI_SEARCH, {
                  pathname: window.location.pathname,
                  status: true,
                  timestamp: new Date().toISOString(),
                  event_type: PI_SEARCH,
                  user_id: customerId,
                  device: deviceDetails,
                  summary: getSummary(PI_SEARCH, String(customerId)),
                  view: window.location.pathname,
                  subject: HEADER,
                  object: {
                    onboarded,
                    search: searchText,
                  },
                  orgKey: token,
                })
                navigate(SEARCH_PATH)
                setShouldFetchSearchResults(true)
              }
            }
          }}
        />
        {searchText && (
          <IconButton
            onClick={handleClearSearch}
            color="inherit"
            sx={{ m: '4px !important' }}
          >
            <CloseRoundedIcon
              sx={{
                height: 16,
                width: 16,
                border: ({ palette }) => `1px solid ${palette.text.primary}`,
                borderRadius: '50%',
              }}
            />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
