import { create } from 'zustand'

import { SearchStoreState, SearchStoreType } from './search-bar-store.type'
import { searchEndUsersList } from '../../side-effects/search-bar'
import { snakeToCamelCase } from '../../helpers/api-helpers'
import { UserDataType } from '../dashboard-store'

const searchStoreState: SearchStoreState = {
  searchText: '',
  isSearchResultsLoading: false,
  searchResults: [],
  shouldFetchSearchResults: true,
}

export const useSearchStore = create<SearchStoreType>((set, get) => ({
  // State
  ...searchStoreState,

  // Actions
  setSearchText: (searchText: SearchStoreState['searchText']) =>
    set({ searchText }),
  clearSearchText: () => set({ searchText: '' }),

  fetchSearchResults: async () => {
    set({ isSearchResultsLoading: true })
    try {
      // Assuming fetchResults is an async operation
      const response = await searchEndUsersList(get().searchText)
      const data = response.data?.data
      const updatedData = snakeToCamelCase(data)

      set({
        searchResults: updatedData.map((user: UserDataType) => ({
          // TODO: Ask to send ID and then remove random
          id: user.id,
          image: '',
          name: `${user.firstName} ${user.lastName}` || '',
          email: user.email || '',
          isSessionActive: user.isOnline || false,
          // TODO: Need to update this
          isTabActive: false,
          sessionStartedAt: user.lastSessionLogin || '',
          role: user.role || '',
          company: user.company || '',
          sessionsCount: user.sessions || 0,
          lastLogin: user.lastLogin || '',
          trialType: user.trailType || '',
          linkedInUrl: user.linkedin || '',
          isNew: user.isNew ?? false,
          // TODO: Need to update this
          newActivityCount: 0,
          checkinStatus: user.checkinStatus || 'not_applicable',
          unreadVoiceNotesCount: user.totalUnreadVoiceNotes || 0,
          phone: user.phone || '',
        })),
      })
      Promise.resolve(response)
    } catch (e) {
      console.error(e)
      Promise.reject(e)
    } finally {
      set({ isSearchResultsLoading: false })
      set({ shouldFetchSearchResults: false })
    }
  },

  setShouldFetchSearchResults: (shouldFetchSearchResults) =>
    set({ shouldFetchSearchResults }),
}))
