// hooks/useNotification.ts
import React, { useCallback } from 'react'
import { Id, ToastContent, ToastOptions, toast } from 'react-toastify'
import useNotificationStore from '../../state/notifications-store'
import { Notification } from '../../state/notifications-store/notifications-store.type'
import InAppNotification from '../../components/partials/InAppNotification/in-app-notification'
import useProfileStore from '../../state/profile-store'

const useNotification = (): { notify: (params: Notification) => void } => {
  const addNotification = useNotificationStore((state) => state.addNotification)
  const removeNotification = useNotificationStore(
    (state) => state.removeNotification
  )
  const saveNotifcation = useNotificationStore(
    (state) => state.saveNotification
  )

  const loggedIn = useProfileStore((state) => state.loggedIn)

  const notify = useCallback(
    ({
      id,
      title,
      message,
      timestamp,
      endUser,
      type,
      callBack,
    }: Notification) => {
      // Construct the notification object
      const notification: Notification = {
        id,
        title,
        message,
        timestamp,
        endUser,
        type,
        callBack,
      }

      // Add notification to the store
      addNotification(notification)

      const options = {
        toastId: id,
        containerId: 'in-app-notification-container',
        onClick: () => {
          callBack?.(id) // Call onClickCallback if it exists
          // saveNotifcation(id)
          // removeNotification(id)
        },
        onClose: () => {
          // removeNotification(id)
        },
        // hideProgressBar: true,
        style: {
          cursor: 'pointer',
        },
      } as ToastOptions

      // Display the toast with an onClick callback
      toast(
        // eslint-disable-next-line react/jsx-key
        <InAppNotification
          title={title}
          message={message}
          timestamp={timestamp}
          endUser={endUser}
        />,
        options
      )
      // switch (type) {
      //   case 'success':
      //     toast.success(message, options)
      //     break
      //   case 'error':
      //     toast.error(message, options)
      //     break
      //   case 'info':
      //     // eslint-disable-next-line react/jsx-key
      //     toast(<InAppNotification toastOptions={options} />)
      //     break
      //   case 'warning':
      //     toast.warning(message, options)
      //     break
      //   default:
      //     toast(message, options)
      // }
    },
    [loggedIn]
  )

  return { notify }
}

export default useNotification
