import { Box } from '@mui/material'
import React from 'react'

export type MainContentProps = {
  children: React.ReactNode
}

function MainContent(props: MainContentProps): JSX.Element {
  const { children } = props
  return (
    <Box component="main" flex={1} overflow="hidden" position="relative">
      <Box height="100%" sx={{ overflowY: 'auto' }}>
        {children}
      </Box>
    </Box>
  )
}

export default MainContent
