import { Theme, alpha } from '@mui/material'

export type MuiTabsCallbackProps = {
  theme: Theme
}

export const MuiToggleButton = (): object => {
  return {
    styleOverrides: {
      root: ({ theme }: MuiTabsCallbackProps) => ({
        background: `linear-gradient(126deg, ${alpha(theme.palette.primary.main, 0.06)} 15.93%, ${alpha(theme.palette.secondary.main, 0.06)} 93.42%)`,
        border: 'none !important',
        fontWeight: 400,
        color: theme.palette.text.primary,
        '&.Mui-selected': {
          background: `linear-gradient(126deg, ${theme.palette.primary.main} 15.93%, ${theme.palette.secondary.main} 93.42%)`,
          color: theme.palette.primary.contrastText,
          fontWeight: 500,
        },
      }),
    },
    defaultProps: {},
  }
}
