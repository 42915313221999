export const DEFAULT_PATH = '/'
export const LOGIN_PATH = `${DEFAULT_PATH}`
export const SIGNUP_PATH = `${DEFAULT_PATH}signup`
export const VERIFY_EMAIL_PATH = `${DEFAULT_PATH}verify-email`

export const DASHBOARD_PATH = `${DEFAULT_PATH}dashboard`
export const ONBOARDING_PATH = `${DEFAULT_PATH}onboarding`
export const USER_PROFILE_PATH = `${DEFAULT_PATH}profile`
export const RESET_PASSWORD_INIT_PATH_INTERAL = `${DEFAULT_PATH}reset-password-init-internal`
export const RESET_PASSWORD_PATH = `${DEFAULT_PATH}reset-password`
export const RESET_PASSWORD_EMAIL_SENT_PATH = `${DEFAULT_PATH}reset-password-email-sent`
export const RESET_PASSWORD_UPDATE_PATH = `${DEFAULT_PATH}update-password`
export const RESET_PASSWORD_DONE_PATH = `${DEFAULT_PATH}reset-password-done`
export const ACTIVITY_PATH = `${DEFAULT_PATH}activity`
export const SETTINGS_PATH = `${DEFAULT_PATH}settings`
export const SEARCH_PATH = `${DEFAULT_PATH}search`
