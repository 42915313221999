import { AppBar, Box, Button, Grid, Stack } from '@mui/material'
import React from 'react'
import Logo from './logo'
import ProfileDropdown from './profile-dropdown'
import SearchBar from './search-bar'
import NavLinks from './nav-links'

function Header(): JSX.Element {
  return (
    <AppBar position="relative">
      <Grid container>
        <Grid item xs={4}>
          <Stack direction="row" spacing={4} alignItems="center" height="100%">
            <Logo />
            <NavLinks />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <SearchBar />
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            height="100%"
            justifyContent="end"
          >
            <ProfileDropdown />
          </Stack>
        </Grid>
      </Grid>
    </AppBar>
  )
}

export default Header
