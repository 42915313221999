import usePusherStore from '../state/pusher-store'

export const encodeToBase64 = (str: string): string => {
  return btoa(str)
}

export const decodeFromBase64 = (str: string): string => {
  return atob(str)
}

export const checkEndUserTabActiveStatus = (
  userId: string | number
): boolean => {
  const { liveEndUsers } = usePusherStore.getState()

  if (liveEndUsers?.length) {
    return liveEndUsers?.includes(String(userId))
  }
  return false
}

export const checkLiveEndUsers = (userId: string | number): boolean => {
  const { sessionEndUsers } = usePusherStore.getState()

  if (sessionEndUsers?.length) {
    return sessionEndUsers.includes(String(userId))
  }

  return false
}
