import React, { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

/**
 * A wrapper component that clears state of its children based on the location state.
 * @param children - The child component to be rendered.
 * @returns The wrapped component with state clearing functionality.
 */
function RouteElement({ children }: { children: JSX.Element }): JSX.Element {
  const [clearStateCounter, setClearStateCounter] = React.useState(0)
  const location = useLocation()
  const state = location.state as { clearState: boolean }

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (state?.clearState) {
      setClearStateCounter((prev) => prev + 1)
      setSearchParams({}, { replace: true })
    }
  }, [state])

  return React.cloneElement(children, { key: clearStateCounter })
}

export default RouteElement
