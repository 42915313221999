import { Box, Stack, Typography, alpha } from '@mui/material'
import React from 'react'

export type CallOfflineParticipantTileProps = {
  dimensions?: {
    width?: number | string
    height?: number | string
    aspectRatio?: number | string
  }
  borderRadius?: 'rounded' | 'square' | 'extraRounded'
}

function CallOfflineParticipantTile(
  props: CallOfflineParticipantTileProps
): JSX.Element {
  const { dimensions, borderRadius } = props

  const borderStyles = {
    rounded: '8px',
    square: '0px',
    extraRounded: '16px',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        background: ({ palette }) => palette.background.paper,
        ...dimensions,
        borderRadius: borderRadius && borderStyles[borderRadius],
        border: ({ palette }) =>
          `1px solid ${alpha(palette.secondary.main, 0.54)}`,
      }}
    >
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        spacing={0.75}
      >
        <Typography fontSize={14} fontWeight={600} variant="body2" noWrap>
          User left the call
        </Typography>
        {/* <Typography fontSize={12} variant="body2" color="textSecondary" noWrap>
          This user is disconnected.
        </Typography> */}
      </Stack>
    </Box>
  )
}

CallOfflineParticipantTile.defaultProps = {
  dimensions: {
    width: 200,
    aspectRatio: '16 / 9',
  },
  borderRadius: 'rounded',
}

export default CallOfflineParticipantTile
