import React from 'react'
import useMeetingStore from '../../../state/meeting-store'
import CallOfflineParticipantTile from './call-offile-participant-tile'

function OfflineParticipantTile(): JSX.Element | null {
  const isMeetingStarted = useMeetingStore((state) => state.isMeetingStarted)
  const isCallRinging = useMeetingStore((state) => state.isCallRinging)
  const isUserJoined = useMeetingStore((state) => state.isUserJoined)
  const isUserDisconnected = useMeetingStore(
    (state) => state.isUserDisconnected
  )

  if (isMeetingStarted) {
    if (isUserJoined) {
      if (isUserDisconnected) {
        return (
          <CallOfflineParticipantTile
            dimensions={{
              width: '100%',
              aspectRatio: '16 / 9',
            }}
          />
        )
      }
    }
  }

  return null
}

export default OfflineParticipantTile
