import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

export const MuiSelect = (palette: any): object => {
  return {
    styleOverrides: {
      root: {
        fontSize: 14,
        '& .Mui-disabled': {
          WebkitTextFillColor: `${palette.text.primary} !important`,
          cursor: 'not-allowed !important',
        },
      },
    },
    defaultProps: {
      IconComponent: KeyboardArrowDownRoundedIcon,
      MenuProps: { PaperProps: { sx: { maxHeight: 250 } } },
    },
  }
}
