import { Stack, Typography } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify'
import useNotificationStore from '../../../state/notifications-store'
import useProfileStore from '../../../state/profile-store'
import useJuneAnalyticsStore from '../../../state/june-store'
import { getDeviceDetails } from '../../../helpers/browser'
import {
  NOTIFICATION,
  PI_IN_APP_NOTIFICATIONS_CLEAR_ALL,
} from '../../../constants/june_so'
import { getSummary } from '../../../helpers/june-helpers'

function ClearAllButton(): JSX.Element | null {
  const notifications = useNotificationStore((state) => state.notifications)
  const removeNotification = useNotificationStore(
    (state) => state.removeNotification
  )

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { organization, userInfo } = useProfileStore.getState()
  const { token: orgToken } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  const clearAllNotifications = (): void => {
    analytics?.track(PI_IN_APP_NOTIFICATIONS_CLEAR_ALL, {
      pathname: window.location.pathname,
      status: true,
      timestamp: new Date().toISOString(),
      event_type: PI_IN_APP_NOTIFICATIONS_CLEAR_ALL,
      user_id: customerId,
      device: deviceDetails,
      summary: getSummary(
        PI_IN_APP_NOTIFICATIONS_CLEAR_ALL,
        String(customerId)
      ),
      view: window.location.pathname,
      subject: NOTIFICATION,
      object: {
        onboarded,
      },
      orgKey: orgToken,
    })
    toast.dismiss({ containerId: 'in-app-notification-container' })
    notifications.forEach((notification) => {
      removeNotification(notification.id)
    })
  }
  const loggedIn = useProfileStore((state) => state.loggedIn)

  return notifications?.length && loggedIn ? (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        position: 'fixed',
        top: 86,
        width: 'var(--toastify-toast-width)',
        right: 'calc(max(var(--toastify-toast-right), 32px) - 4px)',
        zIndex: 'var(--toastify-z-index)',
      }}
    >
      <Typography variant="body2" fontWeight={700}>
        Recent Notifications
      </Typography>
      <Stack
        height={20}
        px={0.75}
        alignItems="center"
        justifyContent="center"
        borderRadius={10}
        fontSize={14}
        fontWeight={400}
        sx={{
          background: '#F2F1FC',
          cursor: 'pointer',
        }}
        onClick={clearAllNotifications}
      >
        Clear All
      </Stack>
    </Stack>
  ) : null
}

export default ClearAllButton
