import { AxiosResponse } from 'axios'
import { API } from '../services/api'
import {
  REQUEST_RESET_PASSWORD_ENDPOINT,
  RESET_PASSWORD_ADOCH_ENDPOINT,
  RESET_PASSWORD_WITH_TOKEN_ENDPOINT,
} from '../constants/api'
import { camelToSnakeCase, getResponse } from '../helpers/api-helpers'

export async function requestResetPassword(email: string): Promise<any> {
  return getResponse(API.post(REQUEST_RESET_PASSWORD_ENDPOINT, { email }))
}

export async function resetPasswordWithToken(
  password: string,
  token: string,
  uidb64: string
): Promise<AxiosResponse<any, any>> {
  const data = {
    password,
    token,
    uidb64,
  }
  return getResponse(API.put(RESET_PASSWORD_WITH_TOKEN_ENDPOINT, data))
}

export async function resetPasswordAdoch(newPassword: string): Promise<any> {
  try {
    const response = await API.post(
      RESET_PASSWORD_ADOCH_ENDPOINT,
      {
        password: newPassword,
      },
      { headers: { includeAuth: true } }
    )
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.resolve(error)
  }
}
