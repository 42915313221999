import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Theme,
  Typography,
} from '@mui/material'
import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core'
import React, { useEffect, useState } from 'react'
import useMeetingStore from '../../state/meeting-store'
import Conference from './conference'
import OnlineOfflineIndicator from '../partials/online-offline-indicator'
import CallRecordingIndicator from '../partials/Meeting/call-recording-indicator'
import useProfileStore from '../../state/profile-store'
import useCommonStore from '../../state/common-store'
import {
  checkEndUserTabActiveStatus,
  checkLiveEndUsers,
} from '../../utils/common'
import Draggable from 'react-draggable'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

const MeetingContainerStyles = (theme: Theme): object => {
  return {
    borderRadius: `${theme.shape.borderRadius}px`,
    zIndex: 1000000000,
    position: 'fixed',
    bottom: 20,
    right: 20,
    minWidth: 426,
    minHeight: 240,
    width: 426,
    height: 'fit-content',
    overflow: 'hidden',
    background: theme.palette.background.paper,
  }
}

function Meeting(): JSX.Element | null {
  const [meeting, initMeeting] = useDyteClient()

  const [isFullScreen, setIsFullScreen] = useState(false)
  const meetingContainerRef = React.createRef<HTMLDivElement>()

  const toggleFullScreen = (): void => {
    if (!isFullScreen && meetingContainerRef?.current?.requestFullscreen) {
      meetingContainerRef.current.requestFullscreen()
      setIsFullScreen(!isFullScreen)
    }
    if (isFullScreen && document?.exitFullscreen) {
      document.exitFullscreen()
      setIsFullScreen(!isFullScreen)
    }
  }
  useEffect(() => {
    const handleFullScreenChange = (): void => {
      setIsFullScreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullScreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange)
    }
  }, [])

  const meetingAuthToken = useMeetingStore((state) => state.meetingAuthToken)
  const isMeetingStarted = useMeetingStore((state) => state.isMeetingStarted)
  const setIsMeetingStarted = useMeetingStore(
    (state) => state.setIsMeetingStarted
  )
  const isUserJoined = useMeetingStore((state) => state.isUserJoined)
  const meetingType = useMeetingStore((state) => state.meetingInitType)

  const userDetails = useMeetingStore((state) => state.userDetails)
  const { name, isSessionActive, isTabActive, company, role, id } = userDetails

  const setIsRecordVoiceNotesOpen = useCommonStore(
    (state) => state.setIsRecordVoiceNotesOpen
  )

  useEffect(() => {
    if (meetingAuthToken) {
      initMeeting({
        authToken: meetingAuthToken,
        defaults: {
          audio: true,
          video: meetingType === 'video',
          screenShare: {
            displaySurface: 'browser',
          },
        },
      }).then((meetingData) => {
        if (meetingData) {
          setIsRecordVoiceNotesOpen(false)
          meetingData.join()
          setIsMeetingStarted(true)
        }
      })
    }
  }, [meetingAuthToken])

  if (meetingAuthToken && isMeetingStarted) {
    return (
      <Draggable bounds="body" cancel=".non-draggable">
        <Paper
          sx={(theme) => MeetingContainerStyles(theme)}
          ref={meetingContainerRef}
          elevation={5}
        >
          <DyteProvider value={meeting}>
            <Stack
              width="100%"
              height="100%"
              px="5%"
              py="5%"
              pt={!isFullScreen ? '6%' : '5%'}
              spacing={1}
              position="relative"
            >
              <Stack direction="row">
                <Stack flex={1}>
                  <Stack direction="row" alignItems="center" spacing={0.75}>
                    <OnlineOfflineIndicator
                      isOnline={checkLiveEndUsers(id)}
                      isTabActive={checkEndUserTabActiveStatus(id)}
                      color="dark"
                    />
                    <Typography variant="body1Bold" noWrap flex={1}>
                      {name}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="text.secondary">
                    {role || '-'}, {company || '-'}
                  </Typography>
                </Stack>
                <Box>
                  <IconButton
                    className="non-draggable"
                    onClick={toggleFullScreen}
                    color="inherit"
                  >
                    {isFullScreen ? (
                      <CloseFullscreenRoundedIcon
                        sx={{ width: 16, height: 16 }}
                      />
                    ) : (
                      <OpenInFullRoundedIcon sx={{ width: 16, height: 16 }} />
                    )}
                  </IconButton>
                </Box>
              </Stack>
              {!isFullScreen && (
                <DragIndicatorIcon
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%, -50%) rotate(90deg)',
                    top: '3%',
                    cursor: 'move',
                    opacity: 0.5,
                  }}
                  fontSize="small"
                />
              )}
              <Conference isFullScreen={isFullScreen} />
            </Stack>
          </DyteProvider>
        </Paper>
      </Draggable>
    )
  }
  return null
}

export default Meeting
