import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import Pusher from 'pusher-js'
import { PusherSingleton } from '../../utils/pusher'
import Envs from '../../services/envs'
import { PusherStoreType, Channel } from './pusher-store.type'

const { PUSHER_APP_KEY, PUSHER_APP_CLUSTER } = Envs

export const pusherStoreType = {
  pusher: Pusher || null,
  channels: Pusher.channels || null,
  endUsersInteracting: [],
  endUsers: [],
  liveEndUsers: [],
  sessionEndUsers: [],
}

export const usePusherStore = create<PusherStoreType>()(
  devtools((set, get) => ({
    channels: null,
    pusher: null,
    endUsersInteracting: [],
    endUsers: [],

    // Initialize and subscribe to a new channel
    initializeChannel: (channelName, channelType, userToken) => {
      if (!channelName || !channelType || !userToken) {
        console.warn('Missing parameters for initializing Pusher channel.')
        return
      }

      // Ensure pusher client is initialized
      let { pusher } = get()
      if (!pusher) {
        pusher = PusherSingleton.getClient(
          PUSHER_APP_KEY,
          PUSHER_APP_CLUSTER,
          userToken
        )
        set({ pusher })
      }

      // Subscribe to the channel
      const subscribedChannel = pusher.subscribe(
        `${channelType}-${channelName}`
      )
      set((state) => ({
        channels: {
          ...state.channels,
          [`${channelType}-${channelName}`]: subscribedChannel,
        },
      }))
    },

    // Bind an event to a channel
    bindEvent: (channelName, event, callback) => {
      const { channels } = get()
      const channel = channels[channelName]
      channel?.bind(event, callback)
    },

    // Trigger an event on a channel
    triggerEvent: (channelName, eventName, data) => {
      console.log('sending...', channelName, eventName, data)
      const { pusher } = get()
      // Note: For client events, the event name must be prefixed with 'client-'
      pusher?.send_event(`client-${eventName}`, data, channelName)
    },

    // Unbind an event from a channel
    unbindEvent: (channelName, event, callback) => {
      const { channels } = get()
      const channel = channels[channelName]
      channel?.unbind(event, callback)
    },

    // Cleanup a channel (unsubscribe and remove from the store)
    cleanupChannel: (channelName) => {
      const { channels } = get()
      const channel = channels[channelName]
      if (channel) {
        const { pusher } = get()
        pusher?.unsubscribe(channelName)
        channel.unsubscribe()
        set((state) => {
          const newChannels = { ...state.channels }
          delete newChannels[channelName]
          return { channels: newChannels }
        })
      }
    },

    // Optional: Cleanup all channels
    cleanupAllChannels: () => {
      const { channels } = get()
      Object.values(channels).forEach((channel: Channel) => {
        const { pusher } = get()
        pusher?.unsubscribe(channel?.name)
        channel.unsubscribe()
      })
      set({ channels: {} })
    },

    updateEndUsersInteracting: (data) => {
      set((state) => ({
        endUsersInteracting: data,
      }))
    },
    updateEndUsers: (data) => {
      set((state) => ({
        endUsers: [...pusherStoreType.endUsers, ...data],
      }))
    },
    getChannelMeta: (channelName) => {
      const { channels } = get()
      const channel = channels[channelName]
      return channel
    },
    updateLiveEndUsers: (data) => {
      set((state) => ({
        liveEndUsers: data,
      }))
    },
    updateSessionEndUsers: (data) => {
      set((state) => ({
        sessionEndUsers: data,
      }))
    },
  }))
)
