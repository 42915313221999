import trimEnd from 'lodash/trimEnd'
import trimStart from 'lodash/trimStart'
import Envs from '../services/envs'

/**
 * Constructs the API URL by appending the given path to the API base URL.
 * @param path - The path to be appended to the API base URL.
 * @returns The constructed API URL.
 */
export const API_URL = (path: string): string => {
  return `${trimEnd(Envs.API_BASE_URL, '/')}/api/${Envs.API_VERSION}/${trimStart(path, '/')}`
}

export const WS_URL = (path: string): string => {
  return `${trimEnd(Envs.API_BASE_URL, '/').replace(/^http/, 'ws')}/ws/${trimStart(path, '/')}`
}

export const LOGIN_ENDPOINT = API_URL('/user/login/')
export const USER_PROFILE_ENDPOINT = API_URL('/user/profile/client/')
export const REFRESH_TOKEN_ENDPOINT = API_URL('/user/refresh/')
export const REQUEST_RESET_PASSWORD_ENDPOINT = API_URL(
  '/user/request-password-reset-email/'
)
export const RESET_PASSWORD_WITH_TOKEN_ENDPOINT = API_URL(
  '/user/password-reset/'
)
export const RESET_PASSWORD_ADOCH_ENDPOINT = API_URL(
  '/user/reset-password-adhoc/'
)

export const POST_AGENT_ONLINE_STATUS_ENDPOINT = API_URL('/user/client')

// Dashboard
export const GET_DASHBOARD_TASKS_COUNT_ENDPOINT = API_URL('/home/client/tasks/')
export const GET_CHECKLIST_ITEMS_ENDPOINT = API_URL('/home/client/checklist')
export const GET_LIVE_END_USERS_ENDPOINT = API_URL(
  '/home/end-user/list?view_type=live'
)
export const SEARCH_END_USERS_ENDPOINT = API_URL('/user/end-users')
export const PUT_ENDUSER_CHECKIN_STATUS_ENDPOINT = API_URL('/user/end-users')
export const GET_ALL_END_USERS_ENDPOINT = API_URL(
  '/home/end-user/list?view_type=all'
)

export const UPLOAD_RECORDED_VOICE_NOTE_ENDPOINT = API_URL(
  `/home/client/activity/voice-note/upload/`
)

export const GET_END_USER_LIVE_SESSION_INFO_ENDPOINT = API_URL(
  '/user/enduser/session'
)

// Activity
export const GET_ACTIVITY_EVENTS_LIST_ENDPOINT = API_URL('/events/list_events/')

export const GET_END_USER_EVENTS_ENDPOINT = API_URL('/events/list_events')

export const ENDUSER_UNSEEN_EVENTS_ENDPOINT = API_URL('/events/unseen_events')

export const POST_MEETING_START_ENDPOINT = API_URL('/home/client/activity/call')
export const GET_MEETING_START_ENDPOINT = API_URL('meeting/get_auth_token')

export const UPDATE_CALL_EVENTS_ENDPOINT = API_URL('/home/client/activity/call')

// Settings
export const GET_WELCOME_NOTES_ENDPOINT = API_URL(
  '/user/onboarding_data/welcome_note/'
)
export const GET_OUT_OF_OFFICE_NOTES_ENDPOINT = API_URL(
  '/user/onboarding_data/out_of_office_note/'
)
export const GET_CALL_YOU_BACK_NOTES_ENDPOINT = API_URL(
  '/user/onboarding_data/call_you_back_note/'
)

export const POST_AUTO_SEND_WELCOME_NOTE_ENDPOINT = API_URL(
  '/user/onboarding/auto_send_welcome_note/'
)
export const POST_WELCOME_NOTES_ENDPOINT = API_URL(
  '/user/onboarding/welcome_note/'
)
export const POST_OUT_OF_OFFICE_NOTES_ENDPOINT = API_URL(
  '/user/onboarding/out_of_office_note/'
)
export const POST_CALL_YOU_BACK_NOTES_ENDPOINT = API_URL(
  '/user/onboarding/call_you_back_note/'
)
export const POST_ONBOARDED_BY_ENDPOINT = API_URL(
  '/user/onboarding/onboarded_by/'
)
export const POST_SEND_CODE_INSTRUCTIONS_ENDPOINT = API_URL(
  '/user/onboarding/share_code_instructions/'
)
export const GET_WIDGET_INTEGRATION_STATUS_ENDPOINT = API_URL(
  '/user/onboarding/integration_status/'
)
export const GET_SLACK_INTEGRATION_STATUS_ENDPOINT = API_URL(
  '/integrations/slack/get_status'
)
export const GET_GOOGLE_CALENDAR_INTEGRATION_STATUS_ENDPOINT = API_URL(
  '/integrations/google/'
)
export const GET_OUTLOOK_CALENDAR_INTEGRATION_STATUS_ENDPOINT = API_URL(
  '/integrations/outlook/'
)
export const SET_USER_ONBOARDED_STATUS_ENDPOINT = API_URL('/user/onboarding')

export const POST_USER_PROFILE_PIC_ENDPOINT = API_URL('/user/profile-pic')

// Dyte
export const GET_MEETING_TOKEN_ENDPOINT = API_URL('/meeting/get_meeting')
export const GET_CLIENT_AUTH_TOKEN_ENDPOINT = API_URL('/meeting/get_auth_token')

// Pusher
export const PUSHER_AUTH_ENDPOINT = API_URL('pusher/user-auth/')

export const POST_WIDGET_SETTINGS_ENDPOINT = API_URL('/user/onboarding/widget/')
export const POST_TEAM_NAME_AND_HOURS_ENDPOINT = API_URL(
  '/user/onboarding/team_office_hours/'
)
export const POST_INVITE_ADMIN_ENDPOINT = API_URL('/user/invite_teamate/admin')
export const POST_INVITE_STANDARD_MEMBER_ENDPOINT = API_URL(
  '/user/invite_teamate/standard_user'
)
export const ORG_MEMBERS_ENDPOINT = API_URL('/user/members')

export const POST_CHECK_IN_SETTINGS_ENDPOINT = API_URL(
  '/user/onboarding/check_in_feature/'
)

// Signup
export const SIGNUP_ENDPOINT = API_URL('/user/signup/first_time')
export const INVITEE_SIGNUP_ENDPOINT = API_URL('/user/signup/invitee')
export const SIGNUP_USER_VALIDATION_ENDPOINT = API_URL('/user/signup/check')
export const RESEND_VERIFICATION_EMAIL_ENDPOINT = API_URL(
  '/user/resend-verification-email/'
)
