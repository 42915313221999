import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { Box, Button, Stack, keyframes } from '@mui/material'
import React, { useEffect } from 'react'
import TimeElapsed from '../time-elapsed'
import useMeetingStore from '../../../state/meeting-store'

function CallRecordingIndicator(): JSX.Element {
  const roomJoined = useDyteSelector((m) => m.self.roomJoined)

  const recStartTimestamp = useMeetingStore((state) => state.recStartTimestamp)

  return (
    <Box>
      {roomJoined && Boolean(recStartTimestamp) && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <RadioButtonCheckedIcon
            color="error"
            sx={{
              height: 16,
              width: 16,
              animation:
                'blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate',
              '@keyframes blinker': {
                '0%': {
                  opacity: 1,
                },
                '100%': {
                  opacity: 0,
                },
              },
            }}
          />
          <TimeElapsed
            startTimestamp={recStartTimestamp}
            sx={(theme) => ({
              fontSize: 14,
              fontWeight: 500,
              color: 'text.secondary',
            })}
          />
        </Stack>
      )}
    </Box>
  )
}

export default CallRecordingIndicator
