import React, { useState, useEffect, useMemo } from 'react'
import Pusher from 'pusher-js/types/src/core/pusher'
import usePusherStore from '../../state/pusher-store/index'
import useNotification from '../../side-effects/hooks/useNotification'
import useProfileStore from '../../state/profile-store'
import { encodeToBase64 } from '../../utils/common'

export type UserInfo = {
  name: string
}

export type UserInfoData = {
  id: string
  info: UserInfo
}
function LiveUsersPusher(): JSX.Element {
  const { token: orgName } = useProfileStore((state) => state.organization)
  const { id } = useProfileStore((state) => state.userInfo)

  const name = useMemo(() => {
    return orgName.toLowerCase()
  }, [orgName])

  const updateEndUsers = usePusherStore((state) => state.updateEndUsers)
  const updateLiveEndUsers = usePusherStore((state) => state.updateLiveEndUsers)
  const updateSessionEndUsers = usePusherStore(
    (state) => state.updateSessionEndUsers
  )

  const liveEndUsers = usePusherStore((state) => state.liveEndUsers)
  const sessionEndUsers = usePusherStore((state) => state.sessionEndUsers)

  const channelSuffix = 'online'
  const liveChannelName = `${name}-${channelSuffix}`

  const isAgentOnline = useProfileStore((state) => state.userInfo.isAgentOnline)

  useEffect(() => {
    if (name && id) {
      usePusherStore
        .getState()
        .initializeChannel(liveChannelName, 'presence', encodeToBase64(id))

      // Presence channel for session active
      usePusherStore
        .getState()
        .initializeChannel(name, 'presence', encodeToBase64(id))

      // Subscription success
      usePusherStore
        .getState()
        .bindEvent(
          `presence-${liveChannelName}`,
          'pusher:subscription_succeeded',
          (members) => {
            updateLiveEndUsers(
              Object.keys(members.members).filter(
                (eve) => Number(eve) !== Number(id)
              )
            )
          }
        )

      usePusherStore
        .getState()
        .bindEvent(
          `presence-${name}`,
          'pusher:subscription_succeeded',
          (members) => {
            updateSessionEndUsers(
              Object.keys(members.members).filter(
                (eve) => Number(eve) !== Number(id)
              )
            )
          }
        )
    }

    return () => {
      usePusherStore.getState().cleanupChannel(`presence-${name}`)
      usePusherStore.getState().cleanupChannel(`presence-${liveChannelName}`)
    }
  }, [id, name, isAgentOnline])

  useEffect(() => {
    if (name && id) {
      // Member added
      usePusherStore
        .getState()
        .bindEvent(
          `presence-${liveChannelName}`,
          'pusher:member_added',
          (member) => {
            if (
              liveEndUsers?.length > 0 &&
              !liveEndUsers.includes(member.id) &&
              member.id !== id
            ) {
              updateLiveEndUsers([...liveEndUsers, member.id])
            } else if (!liveEndUsers?.length) {
              updateLiveEndUsers([member.id])
            }
          }
        )
      // usePusherStore
      //   .getState()
      //   .bindEvent(`presence-${name}`, 'pusher:member_added', (member) => {
      //     if (
      //       sessionEndUsers?.length > 0 &&
      //       !sessionEndUsers.includes(member.id) &&
      //       member.id !== id
      //     ) {
      //       updateSessionEndUsers([...sessionEndUsers, member.id])
      //     } else {
      //       updateSessionEndUsers([member.id])
      //     }
      //   })

      // Member removed
      usePusherStore
        .getState()
        .bindEvent(
          `presence-${liveChannelName}`,
          'pusher:member_removed',
          (member) => {
            if (liveEndUsers?.length > 0) {
              updateLiveEndUsers(
                liveEndUsers.filter((m) => Number(m) !== Number(member.id))
              )
            }
          }
        )
      // usePusherStore
      //   .getState()
      //   .bindEvent(`presence-${name}`, 'pusher:member_removed', (member) => {
      //     if (sessionEndUsers?.length > 0) {
      //       updateSessionEndUsers(
      //         sessionEndUsers.filter((m) => Number(m) !== Number(member.id))
      //       )
      //     }
      //   })
    }
  }, [name, id, liveEndUsers])

  useEffect(() => {
    if (name && id) {
      usePusherStore
        .getState()
        .bindEvent(`presence-${name}`, 'pusher:member_added', (member) => {
          if (
            sessionEndUsers?.length > 0 &&
            !sessionEndUsers.includes(member.id) &&
            member.id !== id
          ) {
            updateSessionEndUsers([...sessionEndUsers, member.id])
          } else if (!sessionEndUsers?.length) {
            updateSessionEndUsers([member.id])
          }
        })
      usePusherStore
        .getState()
        .bindEvent(`presence-${name}`, 'pusher:member_removed', (member) => {
          if (sessionEndUsers?.length > 0) {
            updateSessionEndUsers(
              sessionEndUsers.filter((m) => Number(m) !== Number(member.id))
            )
          }
        })
    }
  }, [name, id, sessionEndUsers])
  return <div> </div>
}

export default LiveUsersPusher
