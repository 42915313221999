import { Typography } from '@mui/material'
import React from 'react'

export type FormLabelProps = {
  label: string
  pb?: number
}

function FormLabel(props: FormLabelProps): JSX.Element {
  const { label, pb } = props
  return (
    <Typography variant="body1Bold" pb={pb}>
      {label}
    </Typography>
  )
}

FormLabel.defaultProps = {
  pb: 0.625,
}

export default FormLabel
