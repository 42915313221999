import { IconButton, Stack, alpha } from '@mui/material'
import MicRoundedIcon from '@mui/icons-material/MicRounded'
import React, { useEffect, useMemo } from 'react'
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import MeetingFooterBtn from '../partials/Meeting/meeting-footer-btn'
import CallMicToggle from '../partials/Meeting/call-mic-toggle'
import CallCameraToggle from '../partials/Meeting/call-camera-toggle'
import CallScreenShareToggle from '../partials/Meeting/call-screen-share-toggle'
import CallLeaveRoomBtn from '../partials/Meeting/call-leave-room-btn'
import CallGrid, { ParticipantDetails } from '../partials/Meeting/call-grid'
import CallScreenShareView from '../partials/Meeting/call-screen-share-view'
import CallVideoParticipantTile from '../partials/Meeting/call-video-participant-tile'
import CallRecordingIndicator from '../partials/Meeting/call-recording-indicator'
import useMeetingStore from '../../state/meeting-store'
import CallStatusIndicator from '../partials/Meeting/call-status-indicator'
import RingingVideoParticipantTile from '../partials/Meeting/ringing-video-participant-tile'
import OfflineParticipantTile from '../partials/Meeting/offline-participant-tile'
import useProfileStore from '../../state/profile-store'
import { putCallEvents } from '../../side-effects/dashboard'
import useNotificationSound from '../../side-effects/hooks/useNotificationSound'
import { OUTGOING_CALL_RINGING } from '../../constants/sounds'
import { getDeviceDetails } from '../../helpers/browser'
import { getSummary } from '../../helpers/june-helpers'
import {
  PI_CALL_SHARE_SCREEN_CLICK,
  CALL,
  OUTGOING_CALL_MISSED,
  NO_INTERACTION,
} from '../../constants/june_so'
import useJuneAnalyticsStore from '../../state/june-store'

export type ConferenceProps = {
  isFullScreen?: boolean
}

// Call Grid
const screenShareViewNode = (participant: ParticipantDetails): JSX.Element => {
  return (
    <CallScreenShareView
      key={participant.id}
      dimensions={{
        width: '100%',
        aspectRatio: '16 / 9',
      }}
      participantId={participant.id}
      participantName={participant.name}
      isSelf={participant.isSelf}
      screenShareEnabled={participant.screenShareEnabled}
      screenShareTracks={participant.screenShareTracks}
    />
  )
}
const videoParticipantTileNode = (
  participant: ParticipantDetails
): JSX.Element => (
  <CallVideoParticipantTile
    key={participant.id}
    dimensions={{
      width: '100%',
      aspectRatio: '16 / 9',
    }}
    isSelf={participant.isSelf}
    participantId={participant.id}
    participantName={participant.name}
    participantPicture={participant.picture}
    videoEnabled={participant.videoEnabled}
    videoTrack={participant.videoTrack}
    audioEnabled={participant.audioEnabled}
    audioTrack={participant.audioTrack}
  />
)

function Conference(props: ConferenceProps): JSX.Element {
  const { isFullScreen } = props
  const { meeting } = useDyteMeeting()
  const selfId = useDyteSelector((m) => m.self.id)
  const selfName = useDyteSelector((m) => m.self.name)
  const selfPicture = useDyteSelector((m) => m.self.picture)
  const videoEnabled = useDyteSelector((m) => m.self.videoEnabled)
  const videoTrack = useDyteSelector((m) => m.self.videoTrack)
  const audioEnabled = useDyteSelector((m) => m.self.audioEnabled)
  const audioTrack = useDyteSelector((m) => m.self.audioTrack)
  const screenShareEnabled = useDyteSelector((m) => m.self.screenShareEnabled)
  const screenShareTracks = useDyteSelector((m) => m.self.screenShareTracks)
  const participants = useDyteSelector((m) => m.participants.active.toArray())
  const ssParticipant = useDyteSelector(
    (m) =>
      m.participants.joined.toArray().filter((p) => p.screenShareEnabled)[0]
  )

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const organization = useProfileStore((state) => state.organization)
  const userInfo = useProfileStore((state) => state.userInfo)
  const { token: orgToken } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()
  const initCurrentTimestamp = new Date().toISOString()

  // Recording
  const recStartTimestamp = useMeetingStore((state) => state.recStartTimestamp)
  const setRecStartTimestamp = useMeetingStore(
    (state) => state.setRecStartTimestamp
  )
  const recordingState = useDyteSelector((m) => m.recording.recordingState)
  useEffect(() => {
    if (recordingState === 'RECORDING' && !recStartTimestamp) {
      const date = new Date()
      setRecStartTimestamp(date.toISOString())
    }
  }, [recordingState])

  // Screen Share Participant Details
  const screenShareParticipantDetails = useMemo(() => {
    if (ssParticipant) {
      return { ...ssParticipant, isSelf: false }
    }
    return {
      id: selfId,
      name: selfName,
      picture: selfPicture,
      videoEnabled,
      videoTrack,
      audioEnabled,
      audioTrack,
      screenShareEnabled,
      screenShareTracks,
      isSelf: true,
    }
  }, [
    ssParticipant,
    selfId,
    selfName,
    selfPicture,
    videoEnabled,
    videoTrack,
    audioEnabled,
    audioTrack,
    screenShareEnabled,
    screenShareTracks,
  ])

  // Call Grid Participants
  const callGridParticipants = useMemo(() => {
    const selfParticipant = {
      id: selfId,
      name: selfName,
      picture: selfPicture,
      isSelf: true,
      videoEnabled,
      videoTrack,
      audioEnabled,
      audioTrack,
      screenShareEnabled,
      screenShareTracks,
    }
    const otherParticipants = participants.map((obj) => ({
      ...obj,
      isSelf: false,
    }))
    return [...otherParticipants, selfParticipant]
  }, [
    participants,
    selfId,
    selfName,
    selfPicture,
    videoEnabled,
    videoTrack,
    audioEnabled,
    audioTrack,
    screenShareEnabled,
    screenShareTracks,
  ])

  // Call Status
  const isCallRinging = useMeetingStore((state) => state.isCallRinging)
  const isUserJoined = useMeetingStore((state) => state.isUserJoined)
  const endMeeting = useMeetingStore((state) => state.endMeeting)
  const callId = useMeetingStore((state) => state.callId)
  useEffect(() => {
    let endCall: NodeJS.Timeout
    if (!isUserJoined && isCallRinging) {
      endCall = setTimeout(() => {
        if (!isUserJoined) {
          // June.so event
          analytics?.track(OUTGOING_CALL_MISSED, {
            pathname: window.location.pathname,
            status: true,
            timestamp: new Date().toISOString(),
            event_type: OUTGOING_CALL_MISSED,
            user_id: customerId,
            device: deviceDetails,
            summary: getSummary(OUTGOING_CALL_MISSED, String(customerId)),
            view: window.location.pathname,
            subject: NO_INTERACTION,
            object: {
              meeting: {
                callId,
              },
            },
            orgToken,
          })
          putCallEvents('mark_as_missed').finally(() => {
            meeting.leave().then(() => {
              endMeeting()
            })
          })
        }
      }, 30000)
    }
    return () => clearTimeout(endCall)
  }, [isCallRinging, isUserJoined])

  const endCall = useMeetingStore((state) => state.endCall)
  useEffect(() => {
    if (endCall) {
      meeting.leave().then(() => {
        endMeeting()
      })
    }
  }, [endCall])

  const ringingSound = useNotificationSound(OUTGOING_CALL_RINGING, {
    loop: true,
  })
  useEffect(() => {
    if (isCallRinging) {
      ringingSound.playSound()
    } else {
      ringingSound.stopSound()
    }
  }, [isCallRinging])

  // Set if enduser Joined or Disconnected
  const participantsLength = useDyteSelector((m) => m.participants.active.size)
  const isUserDisconnected = useMeetingStore(
    (state) => state.isUserDisconnected
  )
  const setIsUserJoined = useMeetingStore((state) => state.setIsUserJoined)
  const setIsUserDisconnected = useMeetingStore(
    (state) => state.setIsUserDisconnected
  )
  const callInitiatedBy = useMeetingStore((state) => state.callInitiatedBy)

  useEffect(() => {
    const markEventAsMissed = (): void => {
      if (isCallRinging && callInitiatedBy === 'agent') {
        putCallEvents('mark_as_missed', callId)
      }
    }
    window.addEventListener('beforeunload', markEventAsMissed)
    return () => {
      window.removeEventListener('beforeunload', markEventAsMissed)
    }
  }, [isCallRinging, callInitiatedBy, callId])

  useEffect(() => {
    if (participantsLength) {
      if (isUserDisconnected) {
        setIsUserDisconnected(false)
      }
      setIsUserJoined(true)
      if (callInitiatedBy === 'agent') {
        putCallEvents('call_accepted')
      }
    } else if (isUserJoined) {
      setIsUserDisconnected(true)
    }
  }, [participantsLength])

  // Leave Meeting on logout
  const loggedIn = useProfileStore((state) => state.loggedIn)
  useEffect(() => {
    if (!loggedIn && meeting) {
      meeting.leave().then(() => {
        endMeeting()
      })
    }
  }, [loggedIn])

  return (
    <Stack spacing={2} flex={1} position="relative">
      <Stack flex={1} alignItems="center" justifyContent="center">
        <CallGrid
          participants={callGridParticipants}
          videoParticipantTileNode={(participant) =>
            videoParticipantTileNode(participant)
          }
          ringingVideoParticipantTile={RingingVideoParticipantTile()}
          offlineParticipantTile={OfflineParticipantTile()}
          screenShareParticipant={
            (screenShareEnabled || ssParticipant) &&
            screenShareParticipantDetails
          }
          screenShareViewNode={(participant) =>
            screenShareViewNode(participant)
          }
        />
      </Stack>
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        spacing={3}
        direction="row"
      >
        <CallMicToggle />
        <CallCameraToggle />
        <CallScreenShareToggle
          analytics={analytics}
          deviceDetails={deviceDetails}
          initCurrentTimestamp={initCurrentTimestamp}
          eventOne={PI_CALL_SHARE_SCREEN_CLICK}
          getSummary={getSummary}
          callId={callId}
          commontEvent={CALL}
          orgKey={orgToken}
          customerId={customerId}
          onboarded={onboarded}
        />
        <CallLeaveRoomBtn />
      </Stack>
      <Stack position="absolute" bottom={10} left={4}>
        <CallStatusIndicator />
      </Stack>
      <Stack position="absolute" bottom={8} right={4}>
        <CallRecordingIndicator />
      </Stack>
    </Stack>
  )
}

Conference.defaultProps = {
  isFullScreen: false,
}

export default Conference
