import { alpha } from '@mui/material'

export const MuiSwitch = (palette: any): object => {
  return {
    styleOverrides: {
      root: {
        width: 28,
        height: 16,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: palette.common.white,
            '& + .MuiSwitch-track': {
              backgroundColor: palette.primary.main,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: palette.primary.main,
            border: `6px solid ${palette.common.white}`,
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: palette.grey[100],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          boxShadow: 'none',
          width: 12,
          height: 12,
        },
        '& .MuiSwitch-track': {
          borderRadius: 16 / 2,
          backgroundColor: alpha(palette.text.primary, 0.5),
          opacity: 1,
          transition: 'background-color 500ms',
        },
      },
    },
    defaultProps: {},
  }
}
