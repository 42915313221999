import { IconButton, Theme, alpha } from '@mui/material'
import React from 'react'

export type MeetingFooterBtnProps = {
  btnMode: 'on' | 'off'
  children: React.ReactNode
  onBtnClick: () => void
  disabled?: boolean
}

function MeetingFooterBtn(props: MeetingFooterBtnProps): JSX.Element {
  const { btnMode, children, onBtnClick, disabled } = props

  const btnOnStyles = (theme: Theme): object => ({
    color: `${theme.palette.secondary.contrastText} !important`,
    background: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      background: `${alpha(theme.palette.secondary.main, 0.9)} !important`,
    },
  })

  const btnOffStyles = (theme: Theme): object => ({
    background: `${alpha(theme.palette.secondary.main, 0.12)} !important`,
    '&:hover': {
      background: `${alpha(theme.palette.secondary.main, 0.24)} !important`,
    },
  })

  return (
    <IconButton
      size="small"
      color="secondary"
      sx={(theme) =>
        btnMode === 'on' ? btnOnStyles(theme) : btnOffStyles(theme)
      }
      onClick={onBtnClick}
      disabled={disabled}
    >
      {children}
    </IconButton>
  )
}

MeetingFooterBtn.defaultProps = {
  disabled: false,
}

export default MeetingFooterBtn
