import React from 'react'
import useMeetingStore from '../../../state/meeting-store'
import CallVideoParticipantTile from './call-video-participant-tile'

function RingingVideoParticipantTile(): JSX.Element | null {
  const isMeetingStarted = useMeetingStore((state) => state.isMeetingStarted)
  const isCallRinging = useMeetingStore((state) => state.isCallRinging)
  const isUserJoined = useMeetingStore((state) => state.isUserJoined)

  const userDetails = useMeetingStore((state) => state.userDetails)
  const participant = {
    id: userDetails.id as string,
    name: userDetails.name,
    picture: undefined,
    isSelf: false,
    videoEnabled: false,
    videoTrack: undefined,
    audioEnabled: false,
    audioTrack: undefined,
    screenShareEnabled: false,
    screenShareTracks: {},
  }

  if (isMeetingStarted) {
    if (!isUserJoined) {
      // if (isCallRinging) {
      //   meetingLabel = 'Ringing...'
      // } else {
      //   meetingLabel = 'Connecting...'
      // }
      return (
        <CallVideoParticipantTile
          dimensions={{
            width: '100%',
            aspectRatio: '16 / 9',
          }}
          isSelf={participant.isSelf}
          participantId={participant.id}
          participantName={participant.name}
          participantPicture={participant.picture}
          videoEnabled={participant.videoEnabled}
          videoTrack={participant.videoTrack}
          audioEnabled={participant.audioEnabled}
          audioTrack={participant.audioTrack}
          hideBottomLabel
        />
      )
    }
  }

  return null
}

export default RingingVideoParticipantTile
