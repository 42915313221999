export const MuiFormHelperText = (palette: any): object => {
  return {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
    defaultProps: {},
  }
}
