// Helpers for all june tracking related
import {
  LOGIN,
  ACTIVE,
  PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_TRUE,
  PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_FALSE,
  PI_SETTINGS_VOICENOTES_WN_CHANGED,
  PI_SETTINGS_VOICENOTES_WCYBN_CHANGED,
  PI_SETTINGS_VOICENOTES_OOO_CHANGED,
  PI_ACTIVITY_MARK_AS_DONE,
  PI_PROFILE_DROPDOWN_LOGOUT,
  PI_ENDUSERPROFILE_VOICENOTE_REDONE,
  PI_RESET_PASSWORD_INTERNAL_REQUEST,
  PI_RESET_PASSWORD_INTERNAL_SUCCESS,
  PI_RESET_PASSWORD_INTERNAL_FAILURE,
  PI_PROFILE_PHOTO_UPLOADED,
  PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_OFF,
  PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_ON,
  PI_SEARCH,
  PI_SEARCH_ENDUSER_PROFILE_CLICK,
  PI_HOME_TASKS_SCHEDULED_CALLS_CLICK,
  PI_HOME_TASKS_MISSED_CALLS_CLICK,
  PI_HOME_TASKS_NEW_VOICE_NOTES_CLICK,
  PI_HOME_USERS_LIVE_TAB_CLICK,
  PI_HOME_USERS_OPPORTUNITIES_TAB_CLICK,
  PI_HOME_USERS_ALL_TAB_CLICK,
  PI_HOME_USERS_CONVERTED_TAB_CLICK,
  PI_ACTIVITY_ALL_TAB_CLICK,
  PI_ACTIVITY_VOICENOTES_TAB_CLICK,
  PI_ACTIVITY_SCHEDULED_CALLS_TAB_CLICK,
  PI_ACTIVITY_MISSED_CALLS_TAB_CLICK,
  PI_ACTIVITY_CALLS_TAB_CLICK,
  PI_ACTIVITY_SESSIONS_TAB_CLICK,
  PI_IN_APP_NOTIFICATIONS_CLICK,
  PI_IN_APP_NOTIFICATIONS_CLOSE,
  PI_INCOMING_CALL_ACCEPTED,
  PI_INCOMING_CALL_DECLINED,
  PI_TIMELINE_CALL_RECORDING_LISTENED,
  PI_CALL_SHARE_SCREEN_CLICK,
  PI_HOME_USERS_LIVE_EMPTY_STATE,
  PI_HOME_USERS_OPPORTUNITIES_EMPTY_STATE,
  PI_HOME_USERS_CONVERTED_EMPTY_STATE,
  PI_HOME_USERS_ALL_EMPTY_STATE,
  PI_ENDUSERPROFILE_LINKEDIN_CLICK,
  PI_ENDUSERPROFILE_AUDIO_CALL_CLICK,
  PI_ENDUSERPROFILE_VIDEO_CALL_CLICK,
  PI_ENDUSERPROFILE_SEND_VOICENOTE_CLICK,
  PI_ENDUSERPROFILE_VOICENOTE_RECORD_CLICK,
  PI_ENDUSERPROFILE_VOICENOTE_RECORD_STOP_CLICK,
  PI_ENDUSERPROFILE_VOICENOTE_SEND_CLICK,
  PI_TIMELINE_VOICE_NOTE_LISTENED,
  PI_PROFILE_DROPDOWN,
  PI_PROFILE_DROPDOWN_PROFILE,
  PI_PROFILE_DROPDOWN_SETTINGS,
  PI_ENDUSERPROFILE_CALL_CLICK,
  PI_SEARCH_ENDUSER_PROFILE_CLOSE,
  PI_DASHBOARD_LOGO_CLICK,
  PI_DASHBOARD_HOME_CLICK,
  PI_DASHBOARD_ACTIVITY_CLICK,
  PI_SEARCH_CLEAR,
  PI_ENDUSER_PROFILE_CLICK,
  PI_ENDUSER_PROFILE_CLOSE,
  PI_TIMELINE_VOICE_NOTE_LISTENED_EXTERNAL,
  PI_ONBOARDING_CONTINUE_BUTTON_CLICK,
  PI_ONBOARDING_BACK_BUTTON_CLICK,
  PI_ONBOARDING_TEXT_INPUT_CLICK,
  PI_ONBOARDING_DROPDOWN_CLICK_OPEN,
  PI_ONBOARDING_DROPDOWN_ITEM_CLICK,
  PI_ONBOARDING_SWITCH_TOGGLE_CLICK,
  PI_LAUNCHER_AVATAR_CHANGE_CLICK,
  PI_LAUNCHER_POSITION_CHANGE_CLICK,
  PI_ONBOARDING_CHECKBOX_TOGGLE_CLICK,
  PI_ONBOARDING_VOICE_NOTE_PLAY_CLICK,
  PI_ONBOARDING_VOICE_NOTE_SELECT_CLICK,
  PI_ONBOARDING_SNIPPET_COPY_CLICK,
  PI_ONBOARDING_INSTRUCTIONS_SEND_BUTTON_CLICK,
  PI_ONBOARDING_WIDGET_INSTALL_REFRESH_BUTTON_CLICK,
  PI_ONBOARDING_HYPERLINK_CLICK,
  PI_IN_APP_NOTIFICATIONS_CLEAR_ALL,
  PI_ONBOARDING_VIDEO_PLAY_BUTTON_CLICK,
  PI_ONBOARDING_ONBOARDED_BY_ME_CLICK,
  PI_ONBOARDING_ONBOARDED_BY_MY_COLLEAGUE_CLICK,
  PI_ONBOARDING_ONBOARDED_BY_SEND_INVITE_CLICK,
  PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_OFF,
  PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_ON,
  PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_OFF,
  PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_ON,
  PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_OFF,
  PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_ON,
  PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_OFF,
  PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_ON,
} from '../constants/june_so'

export function getSummary(eventType: string, userId: string): string {
  switch (eventType) {
    case LOGIN:
      return `Agent with id ${userId} Logged in`
    case ACTIVE:
      return `Agent with id ${userId} Active`
    case PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_TRUE:
      return `Agent with id ${userId} Enabled auto send for welcome note`
    case PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_FALSE:
      return `Agent with id ${userId} Disabled auto send for welcome note`
    case PI_SETTINGS_VOICENOTES_WN_CHANGED:
      return `Agent with id ${userId} Changed welcome note`
    case PI_SETTINGS_VOICENOTES_WCYBN_CHANGED:
      return `Agent with id ${userId} Changed we will call you back note`
    case PI_SETTINGS_VOICENOTES_OOO_CHANGED:
      return `Agent with id ${userId} Changed out of office note`
    case PI_ACTIVITY_MARK_AS_DONE:
      return `Agent with id ${userId} Marked activities as done`
    case PI_PROFILE_DROPDOWN_LOGOUT:
      return `Agent with id ${userId} Logged out`
    case PI_ENDUSERPROFILE_VOICENOTE_REDONE:
      return `Agent with id ${userId} Redone voicenote`
    case PI_RESET_PASSWORD_INTERNAL_REQUEST:
      return `Agent with id ${userId} Requested for reset password`
    case PI_RESET_PASSWORD_INTERNAL_SUCCESS:
      return `Agent with id ${userId} Reset password successfully`
    case PI_RESET_PASSWORD_INTERNAL_FAILURE:
      return `Agent with id ${userId} Reset password failed`
    case PI_PROFILE_PHOTO_UPLOADED:
      return `Agent with id ${userId} Uploaded profile photo`
    case PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_OFF:
      return `Agent with id ${userId} Toggled off master switch`
    case PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_ON:
      return `Agent with id ${userId} Toggled on master switch`
    case PI_SEARCH:
      return `Agent with id ${userId} Searched`
    case PI_SEARCH_ENDUSER_PROFILE_CLICK:
      return `Agent with id ${userId} Clicked on end user profile in search page`
    case PI_HOME_TASKS_SCHEDULED_CALLS_CLICK:
      return `Agent with id ${userId} Clicked on scheduled calls in home tasks`
    case PI_HOME_TASKS_MISSED_CALLS_CLICK:
      return `Agent with id ${userId} Clicked on missed calls in home tasks`
    case PI_HOME_TASKS_NEW_VOICE_NOTES_CLICK:
      return `Agent with id ${userId} Clicked on new voice notes in home tasks`
    case PI_HOME_USERS_LIVE_TAB_CLICK:
      return `Agent with id ${userId} Clicked on live tab in home users`
    case PI_HOME_USERS_OPPORTUNITIES_TAB_CLICK:
      return `Agent with id ${userId} Clicked on opportunities tab in home users`
    case PI_HOME_USERS_ALL_TAB_CLICK:
      return `Agent with id ${userId} Clicked on all tab in home users`
    case PI_HOME_USERS_CONVERTED_TAB_CLICK:
      return `Agent with id ${userId} Clicked on converted tab in home users`
    case PI_ACTIVITY_ALL_TAB_CLICK:
      return `Agent with id ${userId} Clicked on all tab in activity`
    case PI_ACTIVITY_VOICENOTES_TAB_CLICK:
      return `Agent with id ${userId} Clicked on voicenotes tab in activity`
    case PI_ACTIVITY_SCHEDULED_CALLS_TAB_CLICK:
      return `Agent with id ${userId} Clicked on scheduled calls tab in activity`
    case PI_ACTIVITY_MISSED_CALLS_TAB_CLICK:
      return `Agent with id ${userId} Clicked on missed calls tab in activity`
    case PI_ACTIVITY_CALLS_TAB_CLICK:
      return `Agent with id ${userId} Clicked on calls tab in activity`
    case PI_ACTIVITY_SESSIONS_TAB_CLICK:
      return `Agent with id ${userId} Clicked on sessions tab in activity`
    case PI_IN_APP_NOTIFICATIONS_CLICK:
      return `Agent with id ${userId} Clicked on in app notifications`
    case PI_IN_APP_NOTIFICATIONS_CLOSE:
      return `Agent with id ${userId} Closed in app notifications`
    case PI_IN_APP_NOTIFICATIONS_CLEAR_ALL:
      return `Agent with id ${userId} Cleared all in app notifications`
    case PI_INCOMING_CALL_ACCEPTED:
      return `Agent with id ${userId} Accepted incoming call`
    case PI_INCOMING_CALL_DECLINED:
      return `Agent with id ${userId} Declined incoming call`
    case PI_TIMELINE_CALL_RECORDING_LISTENED:
      return `Agent with id ${userId} Listened to call recording`
    case PI_CALL_SHARE_SCREEN_CLICK:
      return `Agent with id ${userId} Clicked on share screen`
    case PI_HOME_USERS_LIVE_EMPTY_STATE:
      return `Agent with id ${userId} Reached live empty state`
    case PI_HOME_USERS_OPPORTUNITIES_EMPTY_STATE:
      return `Agent with id ${userId} Reached opportunities empty state`
    case PI_HOME_USERS_CONVERTED_EMPTY_STATE:
      return `Agent with id ${userId} Reached converted empty state`
    case PI_HOME_USERS_ALL_EMPTY_STATE:
      return `Agent with id ${userId} Reached all empty state`
    case PI_ENDUSERPROFILE_LINKEDIN_CLICK:
      return `Agent with id ${userId} Clicked on linkedin in end user profile`
    case PI_ENDUSERPROFILE_AUDIO_CALL_CLICK:
      return `Agent with id ${userId} Clicked on audio call in end user profile`
    case PI_ENDUSERPROFILE_VIDEO_CALL_CLICK:
      return `Agent with id ${userId} Clicked on video call in end user profile`
    case PI_ENDUSERPROFILE_SEND_VOICENOTE_CLICK:
      return `Agent with id ${userId} Clicked on send voicenote in end user profile`
    case PI_ENDUSERPROFILE_VOICENOTE_RECORD_CLICK:
      return `Agent with id ${userId} Clicked on record voicenote in end user profile`
    case PI_ENDUSERPROFILE_VOICENOTE_RECORD_STOP_CLICK:
      return `Agent with id ${userId} Clicked on stop record voicenote in end user profile`
    case PI_ENDUSERPROFILE_VOICENOTE_SEND_CLICK:
      return `Agent with id ${userId} Clicked on send voicenote in end user profile`
    case PI_TIMELINE_VOICE_NOTE_LISTENED:
      return `Agent with id ${userId} Listened to voicenote`
    case PI_PROFILE_DROPDOWN:
      return `Agent with id ${userId} Clicked on profile dropdown`
    case PI_PROFILE_DROPDOWN_PROFILE:
      return `Agent with id ${userId} Clicked on profile in profile dropdown`
    case PI_PROFILE_DROPDOWN_SETTINGS:
      return `Agent with id ${userId} Clicked on settings in profile dropdown`
    case PI_ENDUSERPROFILE_CALL_CLICK:
      return `Agent with id ${userId} Clicked on call in end user profile`
    case PI_SEARCH_ENDUSER_PROFILE_CLOSE:
      return `Agent with id ${userId} Closed end user profile in search page`
    case PI_DASHBOARD_LOGO_CLICK:
      return `Agent with id ${userId} Clicked on dashboard logo`
    case PI_DASHBOARD_HOME_CLICK:
      return `Agent with id ${userId} Clicked on dashboard home`
    case PI_DASHBOARD_ACTIVITY_CLICK:
      return `Agent with id ${userId} Clicked on dashboard activity`
    case PI_SEARCH_CLEAR:
      return `Agent with id ${userId} Cleared search`
    case PI_ENDUSER_PROFILE_CLICK:
      return `Agent with id ${userId} Clicked on end user profile`
    case PI_ENDUSER_PROFILE_CLOSE:
      return `Agent with id ${userId} Closed end user profile`
    case PI_TIMELINE_VOICE_NOTE_LISTENED_EXTERNAL:
      return `Agent with id ${userId} Listened to voicenote externally`
    case PI_ONBOARDING_CONTINUE_BUTTON_CLICK:
      return `Agent with id ${userId} Clicked on continue button in onboarding`
    case PI_ONBOARDING_BACK_BUTTON_CLICK:
      return `Agent with id ${userId} Clicked on back button in onboarding`
    case PI_ONBOARDING_TEXT_INPUT_CLICK:
      return `Agent with id ${userId} Clicked on text input in onboarding`
    case PI_ONBOARDING_DROPDOWN_CLICK_OPEN:
      return `Agent with id ${userId} Clicked on dropdown in onboarding`
    case PI_ONBOARDING_DROPDOWN_ITEM_CLICK:
      return `Agent with id ${userId} Clicked on dropdown item in onboarding`
    case PI_ONBOARDING_SWITCH_TOGGLE_CLICK:
      return `Agent with id ${userId} Clicked on switch toggle in onboarding`
    case PI_LAUNCHER_AVATAR_CHANGE_CLICK:
      return `Agent with id ${userId} Clicked on avatar change`
    case PI_LAUNCHER_POSITION_CHANGE_CLICK:
      return `Agent with id ${userId} Clicked on position change`
    case PI_ONBOARDING_CHECKBOX_TOGGLE_CLICK:
      return `Agent with id ${userId} Clicked on checkbox toggle in onboarding`
    case PI_ONBOARDING_VOICE_NOTE_PLAY_CLICK:
      return `Agent with id ${userId} Clicked on play pause toggle in onboarding`
    case PI_ONBOARDING_VOICE_NOTE_SELECT_CLICK:
      return `Agent with id ${userId} Clicked on voice note select in onboarding`
    case PI_ONBOARDING_SNIPPET_COPY_CLICK:
      return `Agent with id ${userId} Clicked on snippet copy in onboarding`
    case PI_ONBOARDING_INSTRUCTIONS_SEND_BUTTON_CLICK:
      return `Agent with id ${userId} Clicked on send instructions button in onboarding`
    case PI_ONBOARDING_WIDGET_INSTALL_REFRESH_BUTTON_CLICK:
      return `Agent with id ${userId} Clicked on refresh button to check if widget is installed or not in onboarding`
    case PI_ONBOARDING_HYPERLINK_CLICK:
      return `Agent with id ${userId} Clicked on hyperlink in onboarding`
    case PI_ONBOARDING_VIDEO_PLAY_BUTTON_CLICK:
      return `Agent with id ${userId} Clicked on play button of video in onboarding`
    case PI_ONBOARDING_ONBOARDED_BY_ME_CLICK:
      return `Agent with id ${userId} Clicked on onboarded by Me in onboarding`
    case PI_ONBOARDING_ONBOARDED_BY_MY_COLLEAGUE_CLICK:
      return `Agent with id ${userId} Clicked on onboarded by My Colleague in onboarding`
    case PI_ONBOARDING_ONBOARDED_BY_SEND_INVITE_CLICK:
      return `Agent with id ${userId} Clicked on send invite to onboard button in onboarding`
    case PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_OFF:
      return `Agent with id ${userId} Toggled off master switch in settings`
    case PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_ON:
      return `Agent with id ${userId} Toggled on master switch in settings`
    case PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_OFF:
      return `Agent with id ${userId} Toggled off skip switch in settings`
    case PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_ON:
      return `Agent with id ${userId} Toggled on skip switch in settings`
    case PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_OFF:
      return `Agent with id ${userId} Toggled off master switch in onboarding`
    case PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_ON:
      return `Agent with id ${userId} Toggled on master switch in onboarding`
    case PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_OFF:
      return `Agent with id ${userId} Toggled off skip switch in onboarding`
    case PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_ON:
      return `Agent with id ${userId} Toggled on skip switch in onboarding`

    default:
      return ''
  }
}
