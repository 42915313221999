// const headers: string[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
// const bodyVariants: string[] = ['body1', 'body2']
// const subtitleVariants: string[] = ['subtitle1', 'subtitle2']

import { alpha } from '@mui/material'
import { theme } from '.'

// const getStyleObject = (arrayOfKeys: string[], objToAdd: object): object => {
//   return {
//     ...arrayOfKeys.reduce((acc: object, keyName: string) => {
//       return {
//         ...acc,
//         [keyName]: objToAdd,
//       }
//     }, {}),
//   }
// }

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true
    caption2: true
  }
}

export const typography = (palette: any): object => {
  return {
    fontFamily: [
      '"Inter"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    // fontSize: 14,
    // ...getStyleObject(headers, {
    //   fontFamily: "'Lato', san-serif",
    // }),
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    button: {
      fontWeight: 600,
      textTransform: 'Capitalize',
    },
    body1Bold: {
      fontSize: '1rem',
      lineHeight: '1.5',
      letterSpacing: '0.00938em',
      fontWeight: 600,
    },
    caption2: {
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: alpha(palette.text.primary, 0.45),
    },
    // ...getStyleObject(bodyVariants, {
    //   lineHeight: '2em',
    // }),
    // ...getStyleObject(subtitleVariants, {
    //   lineHeight: '2.25em',
    // }),
  }
}
