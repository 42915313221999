import { Box, Theme, alpha } from '@mui/material'
import React from 'react'

function OnlineOfflineIndicator({
  isOnline,
  isTabActive,
  color,
}: {
  isOnline: boolean
  isTabActive: boolean
  color?: 'dark' | 'light'
}): JSX.Element {
  const getIndicatorColor = (palette: Theme['palette']): string => {
    if (isOnline) {
      if (isTabActive) {
        return palette.primary.main
      }
      return palette.tertiary.main
      // :TODO: Uncomment this line to return 'initial'
      // return 'initial'
    }
    return color === 'dark'
      ? palette.text.secondary
      : alpha(palette.secondary.contrastText, 0.54)
  }

  const getIndicatorBorder = (palette: Theme['palette']): string => {
    // TODO: Uncomment later
    // if (isOnline && !isTabActive) {
    //   return color === 'dark'
    //     ? `1px solid ${alpha(palette.secondary.main, 0.54)}`
    //     : `1px solid ${alpha(palette.secondary.contrastText, 0.54)}`
    // }
    return 'none'
  }

  return (
    <Box
      width={8}
      height={8}
      borderRadius="50%"
      alignSelf="center"
      sx={{
        background: ({ palette }) => getIndicatorColor(palette),
        border: ({ palette }) => getIndicatorBorder(palette),
      }}
    />
  )
}

OnlineOfflineIndicator.defaultProps = {
  color: 'dark',
}

export default OnlineOfflineIndicator
