import { AxiosResponse } from 'axios'
import { API } from '../services/api'
import {
  INVITEE_SIGNUP_ENDPOINT,
  LOGIN_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  RESEND_VERIFICATION_EMAIL_ENDPOINT,
  SIGNUP_ENDPOINT,
  SIGNUP_USER_VALIDATION_ENDPOINT,
} from '../constants/api'
import useAuthStore from '../state/auth-store'
import { camelToSnakeCase, getResponse } from '../helpers/api-helpers'

export async function login(email: string, password: string): Promise<any> {
  return getResponse(
    API.post(
      LOGIN_ENDPOINT,
      { email, password },
      { headers: { includeAuth: false } }
    )
  )
}

export async function refreshAccessToken(refresh: string): Promise<any> {
  try {
    const response = await API.post(`${REFRESH_TOKEN_ENDPOINT}`, {
      refresh,
    })
    return Promise.resolve(response)
  } catch (error) {
    return Promise.resolve(error)
  }
}

export const UpdateAccessToken = (accessToken: string): void => {
  // const setAuth = useAuthStore((state) => state.setAuth)
  const { email, refreshToken } = useAuthStore.getState().auth

  if (email && refreshToken) {
    const { setAuth } = useAuthStore.getState()
    setAuth(accessToken, refreshToken, email)
  }
}

export async function signupUserValidation(
  email: string,
  company: string
): Promise<AxiosResponse<any, any>> {
  const params = camelToSnakeCase({
    email,
    company,
  })
  return getResponse(
    API.get(SIGNUP_USER_VALIDATION_ENDPOINT, {
      params,
    })
  )
}

export async function userSignup(
  isInvitee: boolean,
  email: string,
  password: string,
  company: string
): Promise<AxiosResponse<any, any>> {
  const data = camelToSnakeCase({
    email,
    phone: '',
    password,
    company,
  })
  const url = isInvitee ? INVITEE_SIGNUP_ENDPOINT : SIGNUP_ENDPOINT
  return getResponse(
    API.post(url, data, {
      headers: { includeAuth: false },
    })
  )
}

export async function resendVerificationEmail(
  email: string
): Promise<AxiosResponse<any, any>> {
  const data = camelToSnakeCase({
    email,
  })
  return getResponse(
    API.post(RESEND_VERIFICATION_EMAIL_ENDPOINT, data, {
      headers: { includeAuth: false },
    })
  )
}
