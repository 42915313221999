import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined'
import { Box } from '@mui/material'
import React from 'react'
import MeetingFooterBtn from './meeting-footer-btn'

function CallScreenShareToggle(props: any): JSX.Element {
  const {
    analytics,
    deviceDetails,
    initCurrentTimestamp,
    eventOne,
    getSummary,
    callId,
    commontEvent,
    orgKey,
    customerId,
    onboarded,
  } = props
  const { meeting } = useDyteMeeting()

  const roomJoined = useDyteSelector((m) => m.self.roomJoined)
  const screenShareEnabled = useDyteSelector((m) => m.self.screenShareEnabled)
  const screenSharingParticipants = useDyteSelector((m) =>
    m.participants.joined.toArray().filter((p) => p.screenShareEnabled)
  )

  const handleCallScreenShareToggle = async (): Promise<void> => {
    let response: void
    if (screenShareEnabled) {
      response = await meeting.self.disableScreenShare()
    } else {
      response = await meeting.self.enableScreenShare()
      // June.so Tracking
      analytics?.track(eventOne, {
        pathname: window.location.pathname,
        status: true,
        timestamp: new Date().toISOString(),
        event_type: eventOne,
        user_id: customerId,
        device: deviceDetails,
        summary: getSummary(eventOne, String(customerId)),
        view: window.location.pathname,
        subject: commontEvent,
        object: {
          onboarded,
          call: {
            id: callId,
            eventTimestamp: initCurrentTimestamp,
          },
        },
        orgKey,
      })
    }
    return response
  }

  return (
    <Box className="non-draggable">
      {roomJoined && (
        <MeetingFooterBtn
          btnMode={screenShareEnabled ? 'on' : 'off'}
          disabled={Boolean(screenSharingParticipants?.length)}
          onBtnClick={handleCallScreenShareToggle}
        >
          {screenShareEnabled ? (
            <ScreenShareOutlinedIcon />
          ) : (
            <ScreenShareOutlinedIcon />
          )}
        </MeetingFooterBtn>
      )}
    </Box>
  )
}

export default CallScreenShareToggle
