import { IconButton } from '@mui/material'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import React from 'react'

export type CloseDetailButtonProps = {
  onCloseDetail: (e: React.MouseEvent<HTMLElement>) => void
}

function CloseDetailButton(props: CloseDetailButtonProps): JSX.Element {
  const { onCloseDetail } = props
  return (
    <IconButton
      className="Toastify__custom-close-button"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translate(50%, -50%)',
        background: ({ palette }) => `${palette.common.white} !important`,
        overflow: 'visible',
        p: 0,
        m: '0px !important',
      }}
      onClick={(e) => onCloseDetail(e)}
    >
      <HighlightOffRoundedIcon sx={{ fontSize: 20 }} />
    </IconButton>
  )
}

export default CloseDetailButton
