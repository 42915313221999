import { Box, Typography, alpha } from '@mui/material'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  ACTIVITY_PATH,
  DASHBOARD_PATH,
  ONBOARDING_PATH,
} from '../../../constants/paths'
import {
  NavBarActivityIconComponent,
  NavBarHomeIconComponent,
} from '../../../services/image-service'
import SvgIconReactComponent from '../svg-icon-react-component'
import useProfileStore from '../../../state/profile-store'
import { getDeviceDetails } from '../../../helpers/browser'
import { getSummary } from '../../../helpers/june-helpers'
import {
  PI_DASHBOARD_HOME_CLICK,
  PI_DASHBOARD_ACTIVITY_CLICK,
  NAVBAR,
} from '../../../constants/june_so'
import useJuneAnalyticsStore from '../../../state/june-store'

export type NavLinkItemType = {
  title: string
  path: string
  icon: JSX.Element
}
export type NavLinkItemProps = {
  navLinkItem: NavLinkItemType
}

function NavLinkItem({ navLinkItem }: NavLinkItemProps): JSX.Element {
  const location = useLocation()

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { userInfo, organization } = useProfileStore.getState()
  const { token } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  return (
    <Box
      component={NavLink}
      to={navLinkItem.path}
      state={{ clearState: location.pathname === navLinkItem.path }}
      onClick={() => {
        const finalEventType =
          navLinkItem.path === DASHBOARD_PATH
            ? PI_DASHBOARD_HOME_CLICK
            : PI_DASHBOARD_ACTIVITY_CLICK

        analytics?.track(finalEventType, {
          pathname: window.location.pathname,
          status: true,
          timestamp: new Date().toISOString(),
          event_type: finalEventType,
          user_id: customerId,
          device: deviceDetails,
          summary: getSummary(finalEventType, String(customerId)),
          view: window.location.pathname,
          subject: NAVBAR,
          object: {
            onboarded,
          },
          orgKey: token,
        })
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '12px 16px',
        color: 'text.primary',
        textDecoration: 'none',
        borderRadius: '100px',
        ...(location.pathname === navLinkItem.path && {
          background: ({ palette }) =>
            `linear-gradient(90deg, ${alpha(palette.primary.main, 0.014)} 0%, ${alpha(palette.primary.main, 0.1)} 100%)`,
        }),
        '&:hover': {
          background: ({ palette }) =>
            `linear-gradient(90deg, ${alpha(palette.primary.main, 0.014)} 0%, ${alpha(palette.primary.main, 0.1)} 100%)`,
        },
      }}
    >
      {navLinkItem.icon}
      <Typography variant="body2" fontWeight={600}>
        {navLinkItem.title}
      </Typography>
    </Box>
  )
}

function NavLinks(): JSX.Element | null {
  const navLinks: NavLinkItemType[] = [
    {
      title: 'Home',
      path: DASHBOARD_PATH,
      icon: (
        <SvgIconReactComponent>
          <NavBarHomeIconComponent />
        </SvgIconReactComponent>
      ),
    },
    {
      title: 'Activity',
      path: ACTIVITY_PATH,
      icon: (
        <SvgIconReactComponent>
          <NavBarActivityIconComponent />
        </SvgIconReactComponent>
      ),
    },
  ]

  const loggedIn = useProfileStore((state) => state.loggedIn)
  const location = useLocation()
  const pathsToExclude = [ONBOARDING_PATH]
  if (!loggedIn || pathsToExclude.includes(location.pathname)) {
    return null
  }
  return (
    <>
      {navLinks.map((navLink) => (
        <NavLinkItem key={navLink.title} navLinkItem={navLink} />
      ))}
    </>
  )
}

export default NavLinks
