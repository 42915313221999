import { Box } from '@mui/material'
import React from 'react'
import {
  CheckboxCheckedIconComponent,
  CheckboxUncheckedIconComponent,
} from '../../services/image-service'
import SvgIconReactComponent from '../../components/partials/svg-icon-react-component'

// function CheckboxIcon(icon: string): React.ReactNode {
//   return <Box component="img" src={icon} width={16} height={16} />
// }

export const MuiCheckbox = (palette: any): object => {
  return {
    styleOverrides: {
      root: {},
    },
    defaultProps: {
      icon: (
        <SvgIconReactComponent>
          <CheckboxUncheckedIconComponent style={{ color: 'inherit' }} />
        </SvgIconReactComponent>
      ),
      checkedIcon: (
        <SvgIconReactComponent>
          <CheckboxCheckedIconComponent style={{ color: 'inherit' }} />
        </SvgIconReactComponent>
      ),
    },
  }
}
