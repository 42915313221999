import { create } from 'zustand'

import { ResetPasswordState, ResetPasswordType } from './reset-password.type'

const resetPasswordState: ResetPasswordState = {
  isLoading: false,
}

export const useResetPasswordStore = create<ResetPasswordType>((set) => ({
  // State
  ...resetPasswordState,

  // Actions
  setIsLoading: (isLoading: ResetPasswordState['isLoading']) =>
    set({ isLoading }),
}))
