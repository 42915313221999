export const LOGIN = 'LOGIN'
export const ACTIVE = 'ACTIVE'
export const AGENT = 'AGENT'
export const INTERACTION = 'INTERACTION'
export const PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_TRUE =
  'PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_TRUE'
export const PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_FALSE =
  'PI_SETTINGS_VOICENOTES_WELCOMENOTE_AUTO_SEND_FALSE'
export const PI_SETTINGS_VOICENOTES_WN_CHANGED =
  'PI_SETTINGS_VOICENOTES_WELCOMENOTE_CHANGED'
export const PI_SETTINGS_VOICENOTES_WCYBN_CHANGED =
  'PI_SETTINGS_VOICENOTES_WCYBN_CHANGED'
export const PI_SETTINGS_VOICENOTES_OOO_CHANGED =
  'PI_SETTINGS_VOICENOTES_OOO_CHANGED'
export const PI_ACTIVITY_MARK_AS_DONE = 'PI_ACTIVITY_MARK_AS_DONE'
export const PI_PROFILE_DROPDOWN = 'PI_PROFILE_DROPDOWN'
export const PI_PROFILE_DROPDOWN_LOGOUT = 'PI_PROFILE_DROPDOWN_LOGOUT'
export const PI_PROFILE_DROPDOWN_PROFILE = 'PI_PROFILE_DROPDOWN_PROFILE'
export const PI_PROFILE_DROPDOWN_SETTINGS = 'PI_PROFILE_DROPDOWN_SETTINGS'
export const PI_ENDUSERPROFILE_VOICENOTE_REDONE =
  'PI_ENDUSERPROFILE_VOICENOTE_REDONE'
export const PI_RESET_PASSWORD_INTERNAL_REQUEST =
  'PI_RESET_PASSWORD_INTERNAL_REQUEST'
export const PI_RESET_PASSWORD_INTERNAL_SUCCESS =
  'PI_RESET_PASSWORD_INTERNAL_SUCCESS'
export const PI_RESET_PASSWORD_INTERNAL_FAILURE =
  'PI_RESET_PASSWORD_INTERNAL_FAILURE'
export const PI_PROFILE_PHOTO_UPLOADED = 'PI_PROFILE_PHOTO_UPLOADED'
export const PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_OFF =
  'PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_OFF'
export const PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_ON =
  'PI_SETTINGS_LAUNCHER_MASTERSWITCH_TOGGLE_ON'
export const PI_SEARCH = 'PI_SEARCH'
export const PI_SEARCH_ENDUSER_PROFILE_CLICK = 'PI_SEARCH_ENDUSER_PROFILE_CLICK'
export const PI_SEARCH_ENDUSER_PROFILE_CLOSE = 'PI_SEARCH_ENDUSER_PROFILE_CLOSE'
export const PI_HOME_TASKS_SCHEDULED_CALLS_CLICK =
  'PI_HOME_TASKS_SCHEDULED_CALLS_CLICK'
export const PI_HOME_TASKS_MISSED_CALLS_CLICK =
  'PI_HOME_TASKS_MISSED_CALLS_CLICK'
export const PI_HOME_TASKS_NEW_VOICE_NOTES_CLICK =
  'PI_HOME_TASKS_NEW_VOICE_NOTES_CLICK'
export const PI_HOME_USERS_LIVE_TAB_CLICK = 'PI_HOME_USERS_LIVE_TAB_CLICK'
export const PI_HOME_USERS_OPPORTUNITIES_TAB_CLICK =
  'PI_HOME_USERS_OPPORTUNITIES_TAB_CLICK'
export const PI_HOME_USERS_ALL_TAB_CLICK = 'PI_HOME_USERS_ALL_TAB_CLICK'
export const PI_HOME_USERS_CONVERTED_TAB_CLICK =
  'PI_HOME_USERS_CONVERTED_TAB_CLICK'
export const PI_HOME_USERS_CHECKIN_TAB_CLICK = 'PI_HOME_USERS_CHECKIN_TAB_CLICK'
export const PI_ACTIVITY_ALL_TAB_CLICK = 'PI_ACTIVITY_ALL_TAB_CLICK'
export const PI_ACTIVITY_VOICENOTES_TAB_CLICK =
  'PI_ACTIVITY_VOICENOTES_TAB_CLICK'
export const PI_ACTIVITY_SCHEDULED_CALLS_TAB_CLICK =
  'PI_ACTIVITY_SCHEDULED_CALLS_TAB_CLICK'
export const PI_ACTIVITY_MISSED_CALLS_TAB_CLICK =
  'PI_ACTIVITY_MISSED_CALLS_TAB_CLICK'
export const PI_ACTIVITY_CALLS_TAB_CLICK = 'PI_ACTIVITY_CALLS_TAB_CLICK'
export const PI_ACTIVITY_SESSIONS_TAB_CLICK = 'PI_ACTIVITY_SESSIONS_TAB_CLICK'
export const PI_ACTIVITY_SESSION_RECORDINGS_TAB_CLICK =
  'PI_ACTIVITY_SESSION_RECORDINGS_TAB_CLICK'
export const PI_IN_APP_NOTIFICATIONS_CLICK = 'PI_IN_APP_NOTIFICATIONS_CLICK'
export const PI_IN_APP_NOTIFICATIONS_CLOSE = 'PI_IN_APP_NOTIFICATIONS_CLOSE'
export const PI_IN_APP_NOTIFICATIONS_CLEAR_ALL =
  'PI_IN_APP_NOTIFICATIONS_CLEAR_ALL'
export const PI_INCOMING_CALL_ACCEPTED = 'PI_INCOMING_CALL_ACCEPTED'
export const PI_INCOMING_CALL_DECLINED = 'PI_INCOMING_CALL_DECLINED'
export const PI_TIMELINE_CALL_RECORDING_LISTENED =
  'PI_TIMELINE_CALL_RECORDING_LISTENED'
export const PI_TIMELINE_VOICE_NOTE_LISTENED = 'PI_TIMELINE_VOICE_NOTE_LISTENED'
export const PI_TIMELINE_VOICE_NOTE_LISTENED_EXTERNAL =
  'PI_TIMELINE_VOICE_NOTE_LISTENED_EXTERNAL'
export const PI_CALL_SHARE_SCREEN_CLICK = 'PI_CALL_SHARE_SCREEN_CLICK'
export const PI_HOME_USERS_LIVE_EMPTY_STATE = 'PI_HOME_USERS_LIVE_EMPTY_STATE'
export const PI_HOME_USERS_OPPORTUNITIES_EMPTY_STATE =
  'PI_HOME_USERS_OPPORTUNITIES_EMPTY_STATE'
export const PI_HOME_USERS_CONVERTED_EMPTY_STATE =
  'PI_HOME_USERS_CONVERTED_EMPTY_STATE'
export const PI_HOME_USERS_ALL_EMPTY_STATE = 'PI_HOME_USERS_ALL_EMPTY_STATE'
export const PI_ENDUSERPROFILE_LINKEDIN_CLICK =
  'PI_ENDUSERPROFILE_LINKEDIN_CLICK'
export const PI_ENDUSERPROFILE_CALL_CLICK = 'PI_ENDUSERPROFILE_CALL_CLICK'
export const PI_ENDUSERPROFILE_AUDIO_CALL_CLICK =
  'PI_ENDUSERPROFILE_AUDIO_CALL_CLICK'
export const PI_ENDUSERPROFILE_VIDEO_CALL_CLICK =
  'PI_ENDUSERPROFILE_VIDEO_CALL_CLICK'
export const PI_ENDUSERPROFILE_SEND_VOICENOTE_CLICK =
  'PI_ENDUSERPROFILE_SEND_VOICENOTE_CLICK'
export const PI_ENDUSERPROFILE_VOICENOTE_RECORD_CLICK =
  'PI_ENDUSERPROFILE_VOICENOTE_RECORD_CLICK'
export const PI_ENDUSERPROFILE_VOICENOTE_RECORD_STOP_CLICK =
  'PI_ENDUSERPROFILE_VOICENOTE_RECORD_STOP_CLICK'
export const PI_ENDUSERPROFILE_VOICENOTE_SEND_CLICK =
  'PI_ENDUSERPROFILE_VOICENOTE_SEND_CLICK'
export const PI_DASHBOARD_LOGO_CLICK = 'PI_DASHBOARD_LOGO_CLICK'
export const PI_DASHBOARD_HOME_CLICK = 'PI_DASHBOARD_HOME_CLICK'
export const PI_DASHBOARD_ACTIVITY_CLICK = 'PI_DASHBOARD_ACTIVITY_CLICK'
export const PI_SEARCH_CLEAR = 'PI_SEARCH_CLEAR'
export const PI_ENDUSER_PROFILE_CLICK = 'PI_ENDUSER_PROFILE_CLICK'
export const PI_ENDUSER_PROFILE_CLOSE = 'PI_ENDUSER_PROFILE_CLOSE'
export const PI_ONBOARDING_CONTINUE_BUTTON_CLICK =
  'PI_ONBOARDING_CONTINUE_BUTTON_CLICK'
export const PI_ONBOARDING_BACK_BUTTON_CLICK = 'PI_ONBOARDING_BACK_BUTTON_CLICK'
export const PI_ONBOARDING_TEXT_INPUT_CLICK = 'PI_ONBOARDING_TEXT_INPUT_CLICK'
export const PI_ONBOARDING_DROPDOWN_CLICK_OPEN =
  'PI_ONBOARDING_DROPDOWN_CLICK_OPEN'
export const PI_ONBOARDING_DROPDOWN_ITEM_CLICK =
  'PI_ONBOARDING_DROPDOWN_ITEM_CLICK'
export const PI_ONBOARDING_SWITCH_TOGGLE_CLICK =
  'PI_ONBOARDING_SWITCH_TOGGLE_CLICK'
export const PI_LAUNCHER_AVATAR_CHANGE_CLICK = 'PI_LAUNCHER_AVATAR_CHANGE_CLICK'
export const PI_LAUNCHER_POSITION_CHANGE_CLICK =
  'PI_LAUNCHER_POSITION_CHANGE_CLICK'
export const PI_ONBOARDING_CHECKBOX_TOGGLE_CLICK =
  'PI_ONBOARDING_CHECKBOX_TOGGLE_CLICK'
export const PI_ONBOARDING_VOICE_NOTE_PLAY_CLICK =
  'PI_ONBOARDING_VOICE_NOTE_PLAY_CLICK'
export const PI_ONBOARDING_VOICE_NOTE_SELECT_CLICK =
  'PI_ONBOARDING_VOICE_NOTE_SELECT_CLICK'
export const PI_ONBOARDING_SNIPPET_COPY_CLICK =
  'PI_ONBOARDING_SNIPPET_COPY_CLICK'
export const PI_ONBOARDING_INSTRUCTIONS_SEND_BUTTON_CLICK =
  'PI_ONBOARDING_INSTRUCTIONS_SEND_BUTTON_CLICK'
export const PI_ONBOARDING_WIDGET_INSTALL_REFRESH_BUTTON_CLICK =
  'PI_ONBOARDING_WIDGET_INSTALL_REFRESH_BUTTON_CLICK'
export const PI_ONBOARDING_HYPERLINK_CLICK = 'PI_ONBOARDING_HYPERLINK_CLICK'
export const PI_ONBOARDING_VIDEO_PLAY_BUTTON_CLICK =
  'PI_ONBOARDING_VIDEO_PLAY_BUTTON_CLICK'
export const PI_ONBOARDING_ONBOARDED_BY_ME_CLICK =
  'PI_ONBOARDING_ONBOARDED_BY_ME_CLICK'
export const PI_ONBOARDING_ONBOARDED_BY_MY_COLLEAGUE_CLICK =
  'PI_ONBOARDING_ONBOARDED_BY_MY_COLLEAGUE_CLICK'
export const PI_ONBOARDING_ONBOARDED_BY_SEND_INVITE_CLICK =
  'PI_ONBOARDING_ONBOARDED_BY_SEND_INVITE_CLICK'
export const OUTGOING_CALL_MISSED = 'OUTGOING_CALL_MISSED'

export const PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_OFF =
  'PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_OFF'
export const PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_ON =
  'PI_SETTINGS_CHECKIN_MASTER_SWITCH_TOGGLE_ON'
export const PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_OFF =
  'PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_OFF'
export const PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_ON =
  'PI_SETTINGS_CHECKIN_SKIP_SWITCH_TOGGLE_ON'
export const PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_OFF =
  'PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_OFF'
export const PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_ON =
  'PI_ONBOARDING_CHECKIN_MASTER_SWITCH_TOGGLE_ON'
export const PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_OFF =
  'PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_OFF'
export const PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_ON =
  'PI_ONBOARDING_CHECKIN_SKIP_SWITCH_TOGGLE_ON'
// Subjects
export const VOICENOTE = 'VOICENOTE'
export const ACTIVITY = 'ACTIVITY'
export const PROFILE = 'PROFILE'
export const SETTINGS = 'SETTINGS'
export const HEADER = 'HEADER'
export const SEARCH = 'SEARCH'
export const TASKS = 'TASKS'
export const USERS = 'USERS'
export const NOTIFICATION = 'NOTIFICATION'
export const CALL = 'CALL'
export const TIMELINE = 'TIMELINE'
export const ENDUSERPROFILE = 'ENDUSERPROFILE'
export const NAVBAR = 'NAVBAR'
export const DASHBOARD = 'DASHBOARD'
export const ONBOARDING = 'ONBOARDING'
export const NO_INTERACTION = 'NO_INTERACTION'

// Views

// Onboarding
export const ONBOARDING_WELCOME_VIDEO_VIEW = 'ONBOARDING_WELCOME_VIDEO_VIEW'
export const ONBOARDING_SETUP_PROFILE_VIEW = 'ONBOARDING_SETUP_PROFILE_VIEW'
export const ONBOARDING_SETUP_TEAM_OFFICE_HOURS_VIEW =
  'ONBOARDING_SETUP_TEAM_OFFICE_HOURS_VIEW'
export const ONBOARDING_SETUP_LAUNCHER_SETTINGS_VIEW =
  'ONBOARDING_SETUP_LAUNCHER_SETTINGS_VIEW'
export const ONBOARDING_SETUP_CHECKIN_SETTINGS_VIEW =
  'ONBOARDING_SETUP_CHECKIN_SETTINGS_VIEW'
export const ONBOARDING_SETUP_WELCOME_VOICE_NOTE_VIEW =
  'ONBOARDING_SETUP_WELCOME_VOICE_NOTE_VIEW'
export const ONBOARDING_SETUP_CALL_YOU_BACK_VOICE_NOTE_VIEW =
  'ONBOARDING_SETUP_CALL_YOU_BACK_VOICE_NOTE_VIEW'
export const ONBOARDING_SETUP_OUT_OF_OFFICE_VOICE_NOTE_VIEW =
  'ONBOARDING_SETUP_OUT_OF_OFFICE_VOICE_NOTE_VIEW'
export const ONBOARDING_INTEGRATIONS_VIEW = 'ONBOARDING_INTEGRATIONS_VIEW'
export const ONBOARDING_WIDGET_INTEGRATION_VIEW =
  'ONBOARDING_WIDGET_INTEGRATION_VIEW'
export const ONBOARDING_ONBOARDED_BY_VIEW = 'ONBOARDING_ONBOARDED_BY_VIEW'
export const ONBOARDING_ALLOW_PERMISSIONS_VIEW =
  'ONBOARDING_ALLOW_PERMISSIONS_VIEW'
