import { Stack, Typography } from '@mui/material'
import React from 'react'
import useMeetingStore from '../../../state/meeting-store'

function CallStatusIndicator(): JSX.Element | null {
  const isMeetingStarted = useMeetingStore((state) => state.isMeetingStarted)
  const isCallRinging = useMeetingStore((state) => state.isCallRinging)
  const isUserJoined = useMeetingStore((state) => state.isUserJoined)

  let meetingLabel = ''

  if (isMeetingStarted) {
    if (!isUserJoined) {
      if (isCallRinging) {
        meetingLabel = 'Ringing...'
      } else {
        meetingLabel = 'Connecting...'
      }
    }
  }
  if (meetingLabel) {
    return (
      <Stack direction="row" maxWidth={120}>
        <Typography
          fontSize={12}
          fontWeight={500}
          variant="body2"
          color="text.secondary"
          noWrap
        >
          {meetingLabel}
        </Typography>
      </Stack>
    )
  }
  return null
}

export default CallStatusIndicator
