import { grey } from '@mui/material/colors'
import {
  PaletteColorOptions,
  PaletteOptions,
  alpha,
} from '@mui/material/styles'
import { Mode } from './mui-theme.type'

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
  }

  interface PaletteColor {
    lighter?: string
  }
  interface SimplePaletteColorOptions {
    lighter?: string
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
  }
}

export const colorPalette = (
  mode: Mode,
  defaultPalette: PaletteOptions
): object => {
  return {
    ...defaultPalette,
    mode,
    // Palette values for both

    ...(mode === 'light'
      ? {
          // Palette values for light mode
          primary: {
            lighter: '#F3FAF7',
            main: '#34A974',
          },
          secondary: {
            lighter: '#F5F4F8',
            main: '#524C91',
          },
          tertiary: {
            main: '#F5B700',
          },
          error: {
            main: '#E01D5A',
          },
          tonalOffset: {
            light: 0.1,
            dark: 0.1,
          },
          text: {
            primary: '#0D0C22',
            secondary: alpha('#0D0C22', 0.54),
            disabled: alpha('#0D0C22', 0.37),
          },
          contrastThreshold: 2.96,
        }
      : {
          // Palette values for dark mode
          primary: {
            main: '#34A974',
          },
          secondary: {
            main: '#524C91',
          },
          text: {
            primary: '#fff',
            secondary: grey[200],
          },
        }),
  }
}
