import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import CallEndRoundedIcon from '@mui/icons-material/CallEndRounded'
import { Box, IconButton, alpha } from '@mui/material'
import React from 'react'
import useMeetingStore from '../../../state/meeting-store'
import { putCallEvents } from '../../../side-effects/dashboard'

function CallLeaveRoomBtn(): JSX.Element {
  const { meeting } = useDyteMeeting()

  const roomJoined = useDyteSelector((m) => m.self.roomJoined)
  const endMeeting = useMeetingStore((state) => state.endMeeting)

  const callInitiatedBy = useMeetingStore((state) => state.callInitiatedBy)
  const callId = useMeetingStore((state) => state.callId)
  const isCallRinging = useMeetingStore((state) => state.isCallRinging)
  const handleCallLeaveRoomBtn = async (): Promise<void> => {
    if (callInitiatedBy === 'agent' && callId) {
      if (isCallRinging) {
        putCallEvents('mark_as_missed', callId)
      } else {
        putCallEvents('discard_call', callId)
      }
    }
    const response = await meeting.leave()
    endMeeting()
    return response
  }

  return (
    <Box className="non-draggable">
      {roomJoined && (
        <IconButton
          size="small"
          color="secondary"
          sx={(theme) => ({
            color: `${theme.palette.error.contrastText} !important`,
            background: `${theme.palette.error.main} !important`,
            '&:hover': {
              background: `${alpha(theme.palette.error.main, 0.9)} !important`,
            },
          })}
          onClick={handleCallLeaveRoomBtn}
        >
          <CallEndRoundedIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default CallLeaveRoomBtn
