import React from 'react'
import { Box, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PingBaseNavLogo } from '../../../services/image-service'
import { DASHBOARD_PATH } from '../../../constants/paths'
import { getDeviceDetails } from '../../../helpers/browser'
import { getSummary } from '../../../helpers/june-helpers'
import { PI_DASHBOARD_LOGO_CLICK, NAVBAR } from '../../../constants/june_so'
import useJuneAnalyticsStore from '../../../state/june-store'
import useProfileStore from '../../../state/profile-store'

function Logo(): JSX.Element {
  const navigate = useNavigate()

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { userInfo, organization } = useProfileStore.getState()
  const { token } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  return (
    <Stack
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        // June.so Tracking
        analytics?.track(PI_DASHBOARD_LOGO_CLICK, {
          pathname: window.location.pathname,
          status: true,
          timestamp: new Date().toISOString(),
          event_type: PI_DASHBOARD_LOGO_CLICK,
          user_id: customerId,
          device: deviceDetails,
          summary: getSummary(PI_DASHBOARD_LOGO_CLICK, String(customerId)),
          view: window.location.pathname,
          subject: NAVBAR,
          object: {
            onboarded,
          },
          orgKey: token,
        })
        navigate(DASHBOARD_PATH, {
          state: { clearState: true },
        })
      }}
    >
      <Box component="img" src={PingBaseNavLogo} alt="PingBase" width={120} />
    </Stack>
  )
}

export default Logo
