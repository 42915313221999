import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useMemo } from 'react'

import Form from '../partials/Form/form'
import FormLabel from '../partials/Form/form-label'

export type ResetPasswordInternalFormProps = {
  onSubmit: (email: string) => void
  errorMessage: string
  isLoading: boolean
}

function ResetPasswordInternalForm(
  props: ResetPasswordInternalFormProps
): JSX.Element {
  const { onSubmit, errorMessage, isLoading } = props
  const defaultFieldValues = {
    password: '',
  }

  const passwordRuleCheck = (password: string): boolean => {
    const hasUpperCase = /[A-Z]/.test(password)
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password)
    const hasMinLength = password.length >= 8
    return hasUpperCase && hasSpecialCharacter && hasMinLength
  }
  const [showPassword, setShowPassword] = React.useState(false)
  const passwordRules = [
    '8 characters min.',
    '1 uppercase',
    '1 special character',
  ]
  const [password, setPassword] = React.useState(defaultFieldValues.password)

  const passwordIsValid = useMemo(() => passwordRuleCheck(password), [password])

  const isFromFilled = password && passwordIsValid

  const handleResetPassword = (e: React.FormEvent<HTMLFormElement>): void => {
    onSubmit(password)
  }
  const handleClickShowPassword = (): void => setShowPassword((show) => !show)

  return (
    <Form onSubmit={handleResetPassword}>
      <Stack direction="column" spacing={4} width="100%">
        <FormControl>
          <FormLabel label="Password" />
          <TextField
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value)
            }}
            // focused={Boolean(password && passwordIsValid)}
            error={Boolean(password && !passwordIsValid)}
            type={showPassword ? 'text' : 'password'}
            required
            placeholder="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            py={1}
            color={({ palette }) => alpha(palette.text.primary, 0.38)}
          >
            {passwordRules.map((rule) => (
              <Stack
                direction="row"
                spacing={0.625}
                key={`password-rule-${rule}`}
              >
                <CheckCircleOutlineRoundedIcon fontSize="small" />
                <Typography variant="body2">{rule}</Typography>
              </Stack>
            ))}
          </Stack>
          {password && !passwordIsValid && (
            <FormHelperText error>
              <Typography variant="body2" component="span">
                Password doesn&apos;t match required criteria.
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
        {errorMessage && errorMessage ? (
          <FormHelperText error component="span">
            <Typography variant="body2">{errorMessage}</Typography>
          </FormHelperText>
        ) : null}
        {isLoading ? (
          <Button variant="contained">
            <CircularProgress color="inherit" />
          </Button>
        ) : (
          <Button type="submit" variant="contained" disabled={!isFromFilled}>
            Set Password
          </Button>
        )}
      </Stack>
    </Form>
  )
}

export default ResetPasswordInternalForm
