import { Box, Stack } from '@mui/material'
import React from 'react'
import { LoaderFullScreenGif } from '../../../services/image-service'

export type FullScreenLoaderProps = {
  width?: string | number
}

function FullScreenLoader(props: FullScreenLoaderProps): JSX.Element {
  const { width } = props
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        component="img"
        sx={{ width }}
        src={LoaderFullScreenGif}
        alt="Loading..."
      />
    </Stack>
  )
}

FullScreenLoader.defaultProps = {
  width: 100,
}

export default FullScreenLoader
