import ReactDOM from 'react-dom/client'
import React, { useState, useEffect, useRef } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import usePusherStore from '../../state/pusher-store/index'
import useNotification from '../../side-effects/hooks/useNotification'
import useProfileStore from '../../state/profile-store'
import { encodeToBase64 } from '../../utils/common'
import { ACTIVITY_PATH } from '../../constants/paths'
import IncomingCallPopup, {
  IncomingCallPopupProps,
  incomingCallPopupBaseHTML,
} from '../partials/incoming-call-popup'
import useNotificationSound from '../../side-effects/hooks/useNotificationSound'
import {
  INCOMING_CALL_RINGING,
  NOTIFICATION_SOUND,
} from '../../constants/sounds'
import { ENDUSER_LIVE, CHECKIN_ENDUSER_LIVE } from '../../constants/end_user'
import { putCallEvents } from '../../side-effects/dashboard'
import useMeetingStore from '../../state/meeting-store'
import { getDeviceDetails } from '../../helpers/browser'
import { getSummary } from '../../helpers/june-helpers'
import {
  CALL,
  PI_INCOMING_CALL_ACCEPTED,
  PI_INCOMING_CALL_DECLINED,
} from '../../constants/june_so'
import useJuneAnalyticsStore from '../../state/june-store'

export type CommonPusherType = {
  source_event_type: string
  id: string
  storage_url: string
  sender: string
  company?: string
  role?: string
  timestamp?: string
  unique_id: string
}

export type VoiceNotePusherType = CommonPusherType & {
  phone: string
}
export type CallPusherType = CommonPusherType & {
  meeting_id: string
}
export type MissedCallPusherType = CommonPusherType
export type CallAcceptedPusherType = CommonPusherType & {
  meeting_id: string
}
export type CallRejectedPusherType = CommonPusherType & {
  call_id: string
}
export type LoginPusherType = {
  source_event_type: string
  id: string
  sender: string
  check_in?: string
  company?: string
  role?: string
  timestamp?: string
}

export type MeetingPusherType = {
  source_event_type: string
  id: string
  scheduled_time: string
  sender: string
  company?: string
  role?: string
  timestamp?: string
}

// // ---------- POPUP ----------
// const popupWindows: { [uniquePopupIdentifier: string]: Window | null } = {}
// // On unload, close all the popup windows

// const removeAllPopups = (): void => {
//   Object.keys(popupWindows).forEach((uniquePopupIdentifier) => {
//     if (popupWindows[uniquePopupIdentifier]) {
//       popupWindows[uniquePopupIdentifier]?.close()
//       delete popupWindows[uniquePopupIdentifier]
//     }
//   })
// }
// window.onbeforeunload = removeAllPopups
// // ---------- POPUP ----------

function EventPusher(): JSX.Element {
  const organization = useProfileStore((state) => state.organization)
  const user = useProfileStore((state) => state.userInfo)
  const notificationSound = useNotificationSound(NOTIFICATION_SOUND)
  const incomingCallSound = useNotificationSound(INCOMING_CALL_RINGING, {
    loop: true,
    preventReplay: true,
  })

  const startMeeting = useMeetingStore((state) => state.startMeeting)
  const setUserDetails = useMeetingStore((state) => state.setUserDetails)
  const setUserInfo = useProfileStore((state) => state.setUserInfo)
  const setOrganizationKeyValue = useProfileStore(
    (state) => state.setOrganizationKeyValue
  )

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const { token: orgToken } = organization
  const { id: customerId, onboarded: userOnboarded } = user
  const deviceDetails = getDeviceDetails()

  // ---------- POPUP ----------
  const [popupWindows, setPopupWindows] = useState<{
    [uniquePopupIdentifier: string]: Window | null
  }>({})

  const addPopupWindow = (identifier: string, window: Window | null): void => {
    setPopupWindows((prevState) => ({
      ...prevState,
      [identifier]: window,
    }))
  }
  const removePopupWindow = (identifier: string): void => {
    setPopupWindows((prevState) => {
      const newState = { ...prevState }
      delete newState[identifier]
      return newState
    })
  }
  const closePopup = (uniquePopupIdentifier: string): void => {
    if (popupWindows[uniquePopupIdentifier]) {
      popupWindows[uniquePopupIdentifier]?.close()
    }
    removePopupWindow(uniquePopupIdentifier)
  }
  const removeAllPopups = (): void => {
    Object.keys(popupWindows).forEach((uniquePopupIdentifier) => {
      if (popupWindows[uniquePopupIdentifier]) {
        popupWindows[uniquePopupIdentifier]?.close()
      }
    })
    setPopupWindows({})
  }

  useEffect(() => {
    if (Object.keys(popupWindows).length === 0) {
      incomingCallSound.stopSound()
    } else {
      incomingCallSound.playSound()
    }
    window.addEventListener('beforeunload', removeAllPopups)
    return () => {
      window.removeEventListener('beforeunload', removeAllPopups)
    }
  }, [popupWindows])
  // ---------- POPUP ----------

  // ---------- OPEN POPUP WINDOW ----------
  // const [counter, setCounter] = useState(0)
  const openPopup = (
    endUserDetails: IncomingCallPopupProps['endUserDetails'],
    uniquePopupIdentifier: string,
    callId: string,
    meetingId: string
  ): void => {
    if (!popupWindows[uniquePopupIdentifier]) {
      // Open a new window with specified dimensions and no toolbar
      const newWindow = window.open(
        '',
        '_blank',
        'width=400,height=345,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no'
      )
      // setCounter(counter + 1)

      if (newWindow) {
        newWindow?.document.write(incomingCallPopupBaseHTML)
        // Write content into the popup window
        const root = ReactDOM.createRoot(
          newWindow?.document.getElementById('ping-base') as HTMLElement
        )
        root.render(
          <IncomingCallPopup
            endUserDetails={endUserDetails}
            windowOpener={window}
            popupWindow={newWindow as Window}
            uniquePopupIdentifier={uniquePopupIdentifier}
            callId={callId}
            meetingId={meetingId}
          />
        )
      }

      addPopupWindow(uniquePopupIdentifier, newWindow)
    }
  }
  // ---------- OPEN POPUP WINDOW ----------

  // ---------- POPUP ACTION CALLBACK ----------
  useEffect(() => {
    const handlePopupMessage = (event: MessageEvent): void => {
      if (event.data?.type === 'pb-incoming-call') {
        switch (event.data?.action) {
          case 'accept':
            // June.so tracking
            analytics?.track(PI_INCOMING_CALL_ACCEPTED, {
              pathname: window.location.pathname,
              status: true,
              timestamp: new Date().toISOString(),
              event_type: PI_INCOMING_CALL_ACCEPTED,
              user_id: customerId,
              device: deviceDetails,
              summary: getSummary(
                PI_INCOMING_CALL_ACCEPTED,
                String(customerId)
              ),
              view: window.location.pathname,
              subject: CALL,
              object: {
                onboarded: userOnboarded,
                callId: event.data.callId,
                meetingId: event.data.meetingId,
                endUser: event.data.endUserDetails,
              },
              orgKey: orgToken,
            })
            removeAllPopups()
            putCallEvents('we_accepted_the_call', event.data.callId)
            startMeeting('get', event.data.meetingId)
            setUserDetails(event.data.endUserDetails)
            break
          case 'reject':
            // June.so tracking
            analytics?.track(PI_INCOMING_CALL_DECLINED, {
              pathname: window.location.pathname,
              status: true,
              timestamp: new Date().toISOString(),
              event_type: PI_INCOMING_CALL_DECLINED,
              user_id: customerId,
              device: deviceDetails,
              summary: getSummary(
                PI_INCOMING_CALL_DECLINED,
                String(customerId)
              ),
              view: window.location.pathname,
              subject: CALL,
              object: {
                onboarded: userOnboarded,
                callId: event.data.callId,
                meetingId: event.data.meetingId,
              },
              orgKey: orgToken,
            })
            break
          default:
            closePopup(event.data?.uniquePopupIdentifier)
          // console.log('Call closed', event.data?.uniquePopupIdentifier)
        }
      }
    }
    // Add event listener to handle messages from popup window
    window.addEventListener('message', handlePopupMessage)

    // Remove event listener when component is unmounted
    return (): void => {
      window.removeEventListener('message', handlePopupMessage)
    }
  }, [popupWindows])
  // ---------- POPUP ACTION CALLBACK ----------

  const { token: orgName, onboarded: onboardedOrg } = organization
  const name = orgName.toLowerCase()
  const { id, onboarded } = user

  const navigate = useNavigate()

  const { notify } = useNotification()

  const AlertVoiceNoteReceived = (data: VoiceNotePusherType): void => {
    notificationSound.playSound()
    const interactionId = data.id
    const endUserName = data.sender
    const endUserCompany = data.company || ''
    const timestamp = data.timestamp || ''
    const phone = data.phone || ''
    notify({
      id: `voice-note-received-${interactionId}`,
      title: 'Voice Note Received',
      type: 'default',
      timestamp,
      endUser: {
        name: phone,
        company: endUserCompany,
      },
      callBack: () => {
        navigate(`${ACTIVITY_PATH}?tab=2&eventId=${interactionId}`)
      },
    })
  }

  const meetingAuthToken = useMeetingStore((state) => state.meetingAuthToken)
  const isMeetingStarted = useMeetingStore((state) => state.isMeetingStarted)
  const callId = useMeetingStore((state) => state.callId)
  const isAgentOnline = useProfileStore((state) => state.userInfo.isAgentOnline)

  const AlertIncomingCall = (data: CallPusherType): void => {
    if (
      !meetingAuthToken &&
      !isMeetingStarted &&
      !popupWindows[data.unique_id] &&
      isAgentOnline
    ) {
      openPopup(
        {
          id: data.unique_id,
          name: data.sender,
          role: data.role || '-',
          company: data.company || '-',
        },
        data.unique_id,
        data.id,
        data.meeting_id
      )
    }
  }
  useEffect(() => {
    if (meetingAuthToken && isMeetingStarted) {
      removeAllPopups()
    }
  }, [meetingAuthToken, isMeetingStarted])

  const AlertMissedCall = (data: MissedCallPusherType): void => {
    notificationSound.playSound()
    const interactionId = data.id
    const endUserName = data.sender
    const endUserCompany = data.company || ''
    const timestamp = data.timestamp || ''
    notify({
      id: `missed-call-received-${interactionId}`,
      title: 'Missed call',
      type: 'default',
      timestamp,
      endUser: {
        name: endUserName,
        company: endUserCompany,
      },
      callBack: () => {
        navigate(`${ACTIVITY_PATH}?tab=4&eventId=${interactionId}`)
      },
    })
  }

  const AlertLogin = (data: LoginPusherType): void => {
    // Not using in voice
    // notificationSound.playSound()
    // const interactionId = data.id
    // const endUserName = data.sender
    // const endUserCompany = data.company || ''
    // const timestamp = data.timestamp || ''
    // const checkInStatus = data.check_in || ''
    // let finalTitle = ENDUSER_LIVE
    // if (checkInStatus === 'pending') {
    //   finalTitle = CHECKIN_ENDUSER_LIVE
    // }
    // notify({
    //   id: `new-login-${interactionId}`,
    //   title: finalTitle,
    //   type: 'default',
    //   timestamp,
    //   endUser: {
    //     name: endUserName,
    //     company: endUserCompany,
    //   },
    //   callBack: () => {
    //     navigate(`${ACTIVITY_PATH}?tab=7&eventId=${interactionId}`)
    //   },
    // })
  }

  const AlertMeetingScheduled = (data: MeetingPusherType): void => {
    notificationSound.playSound()
    const interactionId = data.id
    const endUserName = data.sender
    const endUserCompany = data.company || ''
    const timestamp = data.timestamp || ''

    notify({
      id: `meeting-scheduled-${interactionId}`,
      title: 'Meeting Scheduled',
      type: 'default',
      timestamp,
      endUser: {
        name: endUserName,
        company: endUserCompany,
      },
      callBack: () => {
        navigate(`${ACTIVITY_PATH}?tab=3&eventId=${interactionId}`)
      },
    })
  }

  const [popupIdToRemove, setPopupIdToRemove] = useState<string | null>(null)
  useEffect(() => {
    if (!popupIdToRemove) return
    closePopup(popupIdToRemove)
    setPopupIdToRemove(null)
  }, [popupIdToRemove])

  const [callIdToEnd, setCallIdToEnd] = useState<string | null>(null)
  useEffect(() => {
    if (!callIdToEnd) return
    if (callId === callIdToEnd) {
      useMeetingStore.setState({ endCall: true })
    }
    setCallIdToEnd(null)
  }, [callIdToEnd])
  const GenericAlert = (
    data:
      | VoiceNotePusherType
      | CallPusherType
      | MissedCallPusherType
      | CallAcceptedPusherType
      | LoginPusherType
      | CallRejectedPusherType
  ): void => {
    // call the required alert functions based on data.source_event_type
    if (data.source_event_type === 'voice_note') {
      AlertVoiceNoteReceived(data as VoiceNotePusherType)
    } else if (data.source_event_type === 'call') {
      AlertIncomingCall(data as CallPusherType)
    } else if (data.source_event_type === 'missed-call') {
      const { unique_id: uniqueId } = data as MissedCallPusherType
      setPopupIdToRemove(uniqueId)
      AlertMissedCall(data as MissedCallPusherType)
    } else if (data.source_event_type === 'call_accepted') {
      const { unique_id: uniqueId } = data as CallAcceptedPusherType
      setPopupIdToRemove(uniqueId)
    } else if (data.source_event_type === 'login') {
      AlertLogin(data as LoginPusherType)
    } else if (data.source_event_type === 'scheduled_meeting') {
      AlertMeetingScheduled(data as MeetingPusherType)
    } else if (data.source_event_type === 'declined-call') {
      const { call_id: declinedCallId } = data as CallRejectedPusherType
      setCallIdToEnd(declinedCallId)
    }
  }

  // useEffect(() => {
  //   // Private channel
  //   if (name && id && onboardedOrg && onboarded) {
  //     usePusherStore
  //       .getState()
  //       .initializeChannel(name, 'private', encodeToBase64(id))
  //   }
  //   return () => {
  //     usePusherStore.getState().cleanupChannel(`private-${name}`)
  //   }
  // }, [name, id, onboardedOrg, onboarded])

  useEffect(() => {
    // Private channel
    if (name && id && onboardedOrg && onboarded) {
      usePusherStore
        .getState()
        .initializeChannel(name, 'private', encodeToBase64(id))
    }
    usePusherStore
      .getState()
      .bindEvent(`private-${name}`, 'pusher:subscription_succeeded', (data) => {
        // console.log('subscription_succeeded', data)
      })

    // Bind to different sets of events
    // 1) If any of the enduser sends a voice note to client
    usePusherStore
      .getState()
      .bindEvent(`private-${name}`, 'enduser-event', GenericAlert)

    usePusherStore
      .getState()
      .bindEvent(`private-${name}`, 'client-event', (data) => {
        if (data.source_event_type === 'call') {
          // Do nothing
        } else if (data.source_event_type === 'voice_note') {
          // Do nothing
        } else if (data.source_event_type === 'missed-call') {
          // Do nothing
        } else if (data.source_event_type === 'client_status_change') {
          if (String(id) === String(data.user_id)) {
            setUserInfo('isAgentOnline', data.is_active)
          }
        } else if (data.source_event_type === 'banner_status_change') {
          setOrganizationKeyValue(
            'banners',
            organization.banners.map((bannerObj) => {
              if (String(bannerObj.id) === String(data.banner_id)) {
                return {
                  ...bannerObj,
                  isActive: data.is_active,
                }
              }
              return { ...bannerObj }
            })
          )
        }
      })
    return () => {
      usePusherStore.getState().cleanupChannel(`private-${name}`)
    }
  }, [name, id, onboardedOrg, onboarded, isAgentOnline, organization])

  return (
    <div>
      {/* <Button
        onClick={() =>
          openPopup(
            {
              name: 'Test User',
              role: 'Test Role',
              company: 'Test Company',
            },
            counter ? '123' : '12345'
          )
        }
      >
        Open
      </Button>
      <Button onClick={() => closePopup('12345')}>Close</Button> */}
    </div>
  )
}

export default EventPusher
