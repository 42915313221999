import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core'
import MicRoundedIcon from '@mui/icons-material/MicRounded'
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded'
import { Box } from '@mui/material'
import React from 'react'
import MeetingFooterBtn from './meeting-footer-btn'

function CallMicToggle(): JSX.Element {
  const { meeting } = useDyteMeeting()

  const audioEnabled = useDyteSelector((m) => m.self.audioEnabled)

  const handleCallMicToggle = async (): Promise<void> => {
    let response: void
    if (audioEnabled) {
      response = await meeting.self.disableAudio()
    } else {
      response = await meeting.self.enableAudio()
    }
    return response
  }

  return (
    <Box className="non-draggable">
      <MeetingFooterBtn
        btnMode={audioEnabled ? 'on' : 'off'}
        onBtnClick={handleCallMicToggle}
      >
        {audioEnabled ? <MicRoundedIcon /> : <MicOffRoundedIcon />}
      </MeetingFooterBtn>
    </Box>
  )
}

export default CallMicToggle
