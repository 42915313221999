import {
  isBrowser,
  isMobile,
  isTablet,
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
} from 'react-device-detect'

export type DeviceDetails = {
  type: string
  browser: string
  browser_version: string
  os: string
  os_version: string
  is_mobile: boolean
  is_tablet: boolean
  is_browser: boolean
}

export function getDeviceDetails(): DeviceDetails {
  return {
    type: deviceType,
    browser: browserName,
    browser_version: browserVersion,
    os: osName,
    os_version: osVersion,
    is_mobile: isMobile,
    is_tablet: isTablet,
    is_browser: isBrowser,
  }
}
