import { Theme, alpha } from '@mui/material'

export type MuiTabsCallbackProps = {
  theme: Theme
}

export const MuiToggleButtonGroup = (): object => {
  return {
    styleOverrides: {
      root: ({ theme }: MuiTabsCallbackProps) => ({
        '& .MuiToggleButtonGroup-firstButton': {
          borderTopLeftRadius: 100,
          borderBottomLeftRadius: 100,
        },
        '& .MuiToggleButtonGroup-lastButton': {
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
        },
      }),
    },
    defaultProps: {},
  }
}
