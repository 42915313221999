import { create as _create } from 'zustand'
import type { StateCreator } from 'zustand'
import useOnboardingStore from './onboarding-store'
import useAuthStore from './auth-store'
import useProfileStore from './profile-store'
import useCommonStore from './common-store'
import useDashboardStore from './dashboard-store'
import useActivityStore from './activity-store'

const storeResetFns = new Set<() => void>()

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const create = (<T extends unknown>() => {
  return (stateCreator: StateCreator<T>) => {
    const store = _create(stateCreator)
    const initialState = store.getState()
    storeResetFns.add(() => {
      store.setState(initialState, true)
    })
    return store
  }
}) as typeof _create

export const resetAllStores = (): void => {
  storeResetFns.forEach((resetFn) => {
    resetFn()
  })

  // useOnboardingStore.getState().resetOnboardingStore()
  useActivityStore.getState().resetActivityStore()
  useAuthStore.getState().resetAuthStore()
  useProfileStore.getState().resetProfileStore()
  useCommonStore.getState().resetCommonStore()
  useDashboardStore.getState().resetDashboardStore()
  // TODO: Add other stores reset functions here
}
