import { Button, alpha } from '@mui/material'
import React, { useEffect } from 'react'

export const incomingCallPopupBaseHTML = `<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <link
      rel="icon"
      href="https://pingbaselrsstorage.blob.core.windows.net/pingbase-client/assets/images/common/logo.png"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="PingBase UserHub - Improve your trial to free conversion rate."
    />
    <!-- <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /> -->
    <!-- <link rel="manifest" href="%PUBLIC_URL%/manifest.json" /> -->
    <meta name="robots" content="noindex, nofollow" /> <!-- Prevent indexing -->
    <title>PingBase UserHub</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
      rel="stylesheet"
    />
    <style>
      * {
        box-sizing: border-box;
      }
      body {
        font-family: 'Inter', sans-serif;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: linear-gradient(121deg, #F2F1FC -7.85%, #FFF 101.09%);
        color: #0d0c22;
      }
      #ping-base {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    </style>
  </head>
  <body>
    <div id="ping-base"></div>
  </body>
</html>`

const incomingCallPopupScript = `
<script>
      // Add event listeners to buttons
      document
        .getElementById('accept-button')
        .addEventListener('click', function () {
          // Set the acceptedCall flag
          window.notRejected = true
          // Trigger API call or any action on accepting call
          // Close the popup window
          window.opener.postMessage('accept', '*')
          window.opener.focus()
          window.close()
        })
      document
        .getElementById('reject-button')
        .addEventListener('click', function () {
          // Close the popup window
          // window.opener.postMessage('reject', '*');
          window.close()
        })
      // setTimeout(function() {
      //   if (!window.notRejected) {
      //     window.notRejected = true;
      //     window.close();
      //   }
      // }, 3000);

      // Listen for the beforeunload event
      window.onbeforeunload = function () {
        // Treat closing the window as rejecting the call if it hasn't been accepted
        if (!window.notRejected) {
          window.opener.postMessage('reject', '*')
        }
      }
    </script>
`

export type IncomingCallPopupProps = {
  endUserDetails: {
    name: string
    role: string
    company: string
    id: string
  }
  windowOpener: Window
  popupWindow: Window
  uniquePopupIdentifier: string
  callId: string
  meetingId: string
}

function IncomingCallPopup(props: IncomingCallPopupProps): JSX.Element {
  const {
    endUserDetails,
    windowOpener,
    popupWindow,
    uniquePopupIdentifier,
    callId,
    meetingId,
  } = props

  // const [notRejected, setNotRejected] = React.useState(false)

  const acceptButtonHandler = (): void => {
    // setNotRejected(true)
    windowOpener.postMessage(
      {
        type: 'pb-incoming-call',
        action: 'accept',
        uniquePopupIdentifier,
        callId,
        meetingId,
        endUserDetails,
      },
      '*'
    )
    windowOpener.focus()
    popupWindow.close()
  }
  // useEffect(() => {
  //   if (notRejected) {
  //     popupWindow.close()
  //   }
  // }, [notRejected])

  const rejectButtonHandler = (): void => {
    windowOpener.postMessage(
      {
        type: 'pb-incoming-call',
        action: 'reject',
        uniquePopupIdentifier,
        callId,
        meetingId,
      },
      '*'
    )
    windowOpener.focus()
    popupWindow.close()
  }

  const beforeUnloadHandler = (): void => {
    // if (!notRejected) {
    //   windowOpener.postMessage('reject', '*')
    // }
    windowOpener.postMessage(
      {
        type: 'pb-incoming-call',
        action: 'close',
        uniquePopupIdentifier,
        callId,
        meetingId,
      },
      '*'
    )
    popupWindow.close()
  }

  useEffect(
    () => {
      popupWindow.addEventListener('beforeunload', beforeUnloadHandler)

      // Cleanup function to remove event listeners
      return () => {
        popupWindow.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    },
    [
      // notRejected
    ]
  )

  const containerStyles: string | React.CSSProperties | undefined = {
    width: '100%',
    maxWidth: '400px',
    height: '100%',
    maxHeight: '345px',
    boxShadow: '0px 4px 80px 0px rgba(13, 12, 34, 0.24)',
  }
  const incomingCallStyles: string | React.CSSProperties | undefined = {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  }
  const incomingCallTextStyles: string | React.CSSProperties | undefined = {
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '-0.12px',
    marginTop: '0px',
    marginBottom: '24px',
  }
  const commonBtnStyles: string | React.CSSProperties | undefined = {
    padding: '10px 20px',
    margin: '0 10px',
    border: 'none',
    borderRadius: '100px',
    cursor: 'pointer',
    color: '#fff',
  }
  const callerAcceptRejectContainerStyles:
    | string
    | React.CSSProperties
    | undefined = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    marginBottom: '8px',
  }
  // button {
  //   padding: 10px 20px;
  //   margin: 0 10px;
  //   border: none;
  //   border-radius: 5px;
  //   background-color: #007bff;
  //   color: #fff;
  //   cursor: pointer;
  // }
  // button:hover {
  //   background-color: #0056b3;
  // }

  const initials = endUserDetails.name
    .split(' ')
    .map((name) => name[0])
    .slice(0, 2)
    .join('')

  return (
    <div className="container" style={containerStyles}>
      <div className="incoming-call" style={incomingCallStyles}>
        <p className="incoming-call-text" style={incomingCallTextStyles}>
          Incoming Voice Call from...
        </p>
        <div style={callerAcceptRejectContainerStyles}>
          <p
            className="incoming-call-text"
            style={{
              margin: 0,
              fontSize: '14px',
              fontWeight: '700',
              width: '100%',
            }}
          >
            {endUserDetails.name}
          </p>
          <p
            style={{
              margin: 0,
              fontSize: '12px',
              opacity: 0.54,
              width: '100%',
            }}
          >
            {endUserDetails.role || '-'}, {endUserDetails.company || '-'}
          </p>
          <div
            style={{
              width: 200,
              height: 112,
              borderRadius: 8,
              display: 'flex',
              background: `linear-gradient(90deg, ${alpha('#34A974', 0.12)} 0%, ${alpha('#34A974', 0.12)} 100%)`,
            }}
          >
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: 40,
                background: '#fff',
                margin: 'auto',
                display: 'flex',
              }}
            >
              <span
                style={{
                  margin: 'auto',
                  fontSize: '20px',
                  fontWeight: '500',
                }}
              >
                {initials}
              </span>
            </div>
          </div>
          <button
            id="accept-button"
            style={{ ...commonBtnStyles, background: '#34A974' }}
            type="button"
            onClick={acceptButtonHandler}
          >
            Accept
          </button>
          <button
            id="reject-button"
            style={{ ...commonBtnStyles, background: '#E01D5A' }}
            type="button"
            onClick={rejectButtonHandler}
          >
            Reject
          </button>
        </div>
        <p
          style={{
            margin: 0,
            fontSize: '12px',
            textAlign: 'center',
            opacity: 0.54,
          }}
        >
          Decline to send automated voice note
        </p>
      </div>
    </div>
  )
}

export default IncomingCallPopup
