import { create } from 'zustand'
import {
  DashboardStoreState,
  DashboardStoreType,
  UserDataType,
} from './dashboard-store.type'
import {
  getDashboardTasksCount,
  getEndUsersList,
} from '../../side-effects/dashboard'
import { snakeToCamelCase } from '../../helpers/api-helpers'

const dashboardStoreState: DashboardStoreState = {
  isTasksDataLoading: false,
  isLiveEndUsersDataLoading: false,
  isAllEndUsersDataLoading: false,
  // isTimelineDataLoading: false,
  tasks: {
    meetings: 0,
    calls: 0,
    voiceNotes: 0,
  },
  liveEndUsers: [],
  allEndUsers: [],
  // endUserTimelineEvents: [],
}

export const useDashboardStore = create<DashboardStoreType>((set) => ({
  // State
  ...dashboardStoreState,

  // Actions
  // tasks actions
  fetchDashboardTasksCount: async (noLoading) => {
    if (!noLoading) {
      set({ isTasksDataLoading: true })
    }
    try {
      const response = await getDashboardTasksCount()
      const data = response.data?.data
      const updatedData = snakeToCamelCase(data)
      set({ tasks: updatedData })
    } catch (e) {
      console.error(e)
      set({ tasks: dashboardStoreState.tasks })
    } finally {
      if (!noLoading) {
        set({ isTasksDataLoading: false })
      }
    }
  },

  // List Actions
  // fetchLiveEndUsers: async () => {
  //   set({ isLiveEndUsersDataLoading: true })

  //   try {
  //     const response = await getEndUsersList('all')
  //     const data = response.data?.data
  //     const updatedData = snakeToCamelCase(data)

  //     set({
  //       liveEndUsers: updatedData.map((user: UserDataType) => ({
  //         // TODO: Ask to send ID and then remove random
  //         id: user.id,
  //         image: '',
  //         name: `${user.firstName} ${user.lastName}` || '',
  //         email: user.email || '',
  //         isSessionActive: user.isOnline || false,
  //         // TODO: Need to update this
  //         isTabActive: false,
  //         sessionStartedAt: user.lastSessionLogin || '',
  //         role: user.role || '',
  //         company: user.company || '',
  //         sessionsCount: user.sessions || 0,
  //         lastLogin: user.lastLogin || '',
  //         trialType: user.trailType || '',
  //         linkedInUrl: user.linkedin || '',
  //         // TODO: Need to update this
  //         newActivityCount: 0,
  //       })),
  //     })
  //   } catch (e) {
  //     console.error(e)
  //     set({ liveEndUsers: dashboardStoreState.liveEndUsers })
  //   } finally {
  //     set({ isLiveEndUsersDataLoading: false })
  //   }
  // },
  fetchAllEndUsers: async (disableLoading) => {
    if (!disableLoading) {
      set({ isAllEndUsersDataLoading: true })
      set({ isLiveEndUsersDataLoading: true })
    }

    try {
      const response = await getEndUsersList('all')
      const data = response.data?.data
      const updatedData = snakeToCamelCase(data)

      set({
        allEndUsers: updatedData.map((user: UserDataType) => ({
          // TODO: Ask to send ID and then remove random
          id: user.id,
          image: '',
          name:
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : '',
          phone: user.phone || '',
          email: user.email || '',
          isSessionActive: user.isOnline || false,
          // TODO: Need to update this
          isTabActive: false,
          sessionStartedAt: user.lastSessionLogin || '',
          role: user.role || '',
          company: user.company || '',
          sessionsCount: user.sessions || 0,
          lastLogin: user.lastLogin || '',
          trialType: user.trailType || '',
          linkedInUrl: user.linkedin || '',
          isNew: user.isNew ?? false,
          // TODO: Need to update this
          newActivityCount: 0,
          checkinStatus: user.checkinStatus || 'not_applicable',
          unreadVoiceNotesCount: user.totalUnreadVoiceNotes || 0,
        })),
      })
      set({
        liveEndUsers: updatedData.map((user: UserDataType) => ({
          // TODO: Ask to send ID and then remove random
          id: user.id,
          image: '',
          name:
            user.firstName && user.lastName
              ? `${user.firstName} ${user.lastName}`
              : '',
          phone: user.phone || '',
          email: user.email || '',
          isSessionActive: user.isOnline || false,
          // TODO: Need to update this
          isTabActive: false,
          sessionStartedAt: user.lastSessionLogin || '',
          role: user.role || '',
          company: user.company || '',
          sessionsCount: user.sessions || 0,
          lastLogin: user.lastLogin || '',
          trialType: user.trailType || '',
          linkedInUrl: user.linkedin || '',
          isNew: user.isNew ?? false,
          // TODO: Need to update this
          newActivityCount: 0,
          checkinStatus: user.checkinStatus || 'not_applicable',
        })),
      })
    } catch (e) {
      console.error(e)
      set({ allEndUsers: dashboardStoreState.allEndUsers })
    } finally {
      if (!disableLoading) {
        set({ isAllEndUsersDataLoading: false })
        set({ isLiveEndUsersDataLoading: false })
      }
    }
  },
  resetDashboardStore: () => set(dashboardStoreState),
}))
