import { Box, Theme, Tooltip } from '@mui/material'
import React from 'react'

function DisabledWrapper({
  children,
  isDisabled,
  extraSx,
}: {
  children: React.ReactElement
  isDisabled: boolean
  extraSx?: (theme: Theme) => any
}): JSX.Element {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isDisabled) {
      event.stopPropagation() // Prevent event propagation
      event.preventDefault() // Prevent default behavior
    }
  }

  // Clone each child to apply styles and event handler
  const modifiedChildren = React.Children.map(
    children,
    (child: React.ReactElement) =>
      React.cloneElement(child, {
        onClick: handleClick,
        style: {
          ...child.props.style,
          pointerEvents: isDisabled ? 'none' : 'auto',
          opacity: isDisabled ? 0.5 : 1,
        },
      })
  )

  return isDisabled ? (
    <Tooltip
      title="This feature is not included in your current plan."
      followCursor
      arrow
      placement="top"
    >
      <Box
        sx={(theme) => ({
          ...extraSx?.(theme),
        })}
      >
        {modifiedChildren}
      </Box>
    </Tooltip>
  ) : (
    <>{children}</>
  )
}

export default DisabledWrapper
