import { create } from 'zustand'
import { ActivityStoreState, ActivityStoreType } from './activity-store.type'
import { getEventList } from '../../side-effects/activity'
import { snakeToCamelCase } from '../../helpers/api-helpers'
import { eventTimelineTypeMapping } from '../../components/partials/EndUserDetails/timeline-item'
import usePusherStore from '../pusher-store'

const activityStoreState: ActivityStoreState = {
  usersList: [],
  allActivityListLoading: false,
  allActivityList: [],
}

export const useActivityStore = create<ActivityStoreType>((set, get) => ({
  // State
  ...activityStoreState,

  // Actions
  setUsersList: (usersList) => set({ usersList }),
  addUser: (user) => {
    if (get().usersList.find((u) => u.id === user.id)) {
      set((state) => ({
        usersList: [...state.usersList.filter((u) => u.id !== user.id), user],
      }))
    } else {
      set((state) => ({ usersList: [...state.usersList, user] }))
    }
  },
  fetchAllActivityList: async (noLoading = false) => {
    if (!noLoading) {
      set({ allActivityListLoading: true })
    }

    try {
      const response = await getEventList()
      const data = response.data?.data
      const updatedData = snakeToCamelCase(data)

      const getEventTypeDisplayName = (
        type: string,
        isReceived: boolean,
        eventName?: string
      ): string => {
        switch (eventTimelineTypeMapping[type]) {
          case 'loggedIn':
            return 'Logged In'
          case 'callScheduled':
            return 'Call Scheduled'
          case 'scheduledCallHeld':
            return 'Scheduled Call Held'
          case 'callHeld':
            return isReceived ? 'Called Us' : 'Answered Our Call'
          case 'missedCall':
            return isReceived ? 'Missed Their Call' : 'Missed Our Call'
          case 'voiceNote':
            return isReceived ? 'Voice Note Received' : 'Voice Note Sent'
          case 'declinedCall':
            return isReceived ? '' : 'Declined Our Call'
          case 'sessionRecording':
            return `${eventName}`
          case 'checkInSkipped':
            return 'Check-In Skipped'
          case 'checkInCompleted':
            return 'Check-In Completed'
          case 'checkInNotApplicable':
            return 'Check-In Not Applicable'
          default:
            return ''
        }
      }

      const updatedEvents = updatedData
        .filter((event: any) =>
          Object.keys(eventTimelineTypeMapping).includes(event.eventType)
        )
        .map((event: any) => {
          const isReceived = !event.isParent
          const endUserId = isReceived
            ? event.sourceUserId
            : event.destinationUserId
          const agentUserId = isReceived
            ? event.destinationUserId
            : event.sourceUserId
          const eventType = getEventTypeDisplayName(
            event.eventType,
            isReceived,
            event.name
          )
          // TODO: Add filtering based on pusher live end users list
          const isLiveUser = usePusherStore
            .getState()
            .liveEndUsers?.includes(String(event.enduserId))
          const isSessionActiveUser = usePusherStore
            .getState()
            .sessionEndUsers?.includes(String(event.enduserId))
          get().addUser({
            id: event.enduserId,
            image: '',
            name:
              event.enduserFirstName && event.enduserLastName
                ? `${event.enduserFirstName} ${event.enduserLastName}`
                : '',
            phone: event.enduserPhone || '',
            email: event.enduserEmail || '',
            isSessionActive: isSessionActiveUser,
            isTabActive: isLiveUser,
            sessionStartedAt: event.enduserLastSessionLogin || '',
            role: event.enduserRole || '',
            company: event.enduserCompany || '',
            sessionsCount: event.enduserSessions || 0,
            lastLogin: event.enduserLastLogin || '',
            trialType: event.enduserTrailType || '',
            linkedInUrl: event.enduserLinkedin || '',
            isNew: event.enduserIsNew ?? false,
            // TODO: Need to update this
            newActivityCount: 0,
            checkinStatus: event.enduserCheckinStatus || 'not_applicable',
          })
          return {
            // id: event.interactionId,
            // isReceived,
            // timestamp: event.timestamp,
            // sender: event.sourceUsername,
            // receiver: event.destinationUsername,
            // type: event.eventType,
            // isAutoSent: event.initiatedBy === 'AUTOMATIC',
            // frontendScreen: event.frontendScreen,
            // isAudioPlayed: event.isPlayed,
            // isAudio: Boolean(event.storageUrl),
            // audioSrc: event.storageUrl,
            id: event.interactionId,
            endUserId,
            eventType,
            isUnread: event.isUnread,
            eventTimestamp: event.timestamp,
            type: event.eventType,
            subEventType: event.subEventType,
            agentUserId,
          }
        })
      set({ allActivityList: updatedEvents })
    } catch (e) {
      console.error(e)
      set({ allActivityList: activityStoreState.allActivityList })
    } finally {
      if (!noLoading) {
        set({ allActivityListLoading: false })
      }
    }
  },
  resetActivityStore: () => set(activityStoreState),
}))
