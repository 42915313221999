import React from 'react'

export type FormProps = {
  children: React.ReactNode
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  formProps?: {
    id?: string
  }
}

function Form(props: FormProps): JSX.Element {
  const { children, onSubmit, formProps } = props
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    onSubmit(e)
  }
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <form {...formProps} onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

Form.defaultProps = {
  formProps: {},
}

export default Form
