import React from 'react'
import { Box, Stack, Typography, Grid, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import BackButton from '../partials/Onboarding/back-button'

function ResetPasswordDone(): JSX.Element {
  const navigate = useNavigate()
  const handleButtonClick = (): void => {
    navigate('/profile')
  }

  return (
    <Box sx={{ height: '85vh', display: 'flex', flexDirection: 'column' }}>
      <Grid
        container
        spacing={5}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        style={{ flexGrow: 1 }}
      >
        <Grid item>
          <Stack
            spacing={3}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box pl={12} pt={0.4} position="relative" width="100%">
              <BackButton onBackBtnClick={handleButtonClick} />
              <Typography variant="h4">New Password</Typography>
              {/* TODO add password changed icon */}
              <Typography variant="h6">Password changed</Typography>

              <Typography>
                Your password has been changed successfully.
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack
            spacing={3}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start  "
          >
            <Button variant="contained" onClick={handleButtonClick}>
              Go Back
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ResetPasswordDone
