import { create } from 'zustand'
import { MeetingStoreState, MeetingStoreType } from './meeting-store.type'
import { getStartMeeting, postStartMeeting } from '../../side-effects/dashboard'
import { snakeToCamelCase } from '../../helpers/api-helpers'

const meetingStoreState: MeetingStoreState = {
  // This is used to end the call from outside of DyteProvider
  endCall: false,
  // When the user clicks on Audio/Video button or Accept Call button,
  // this will be set
  meetingId: '',
  callId: '',
  meetingAuthToken: '',
  userDetails: {
    id: '',
    name: '',
    email: '',
    isSessionActive: false,
    isTabActive: false,
    sessionStartedAt: '',
    sessionsCount: 0,
    lastLogin: '',
    trialType: '',
    newActivityCount: 0,
  },
  // When the user gets auth token, this will be set to true
  isMeetingStarted: false,
  // This is to show notified state in b/w isMeetingStarted and isUserJoined
  // This will be set to true automatically after 10 seconds of isMeetingStarted
  isCallRinging: false,
  // When the user joins the meeting, this will be set to true.
  // This is to show ringing state in b/w isCallRinging and isUserJoined
  // For incoming call, as soon as the user accepts,
  // both isMeetingStarted and isUserJoined will be true
  isUserJoined: false,
  // Will be set to true when the user disconnects from the meeting
  // i.e, tab is closed. Not updating isUserJoined because the customer user is
  // is still in the call
  isUserDisconnected: false,
  meetingInitType: 'audio',
  recStartTimestamp: '',
  callInitiatedBy: 'endUser',
}

export const useMeetingStore = create<MeetingStoreType>((set, get) => ({
  // State
  ...meetingStoreState,

  // Actions
  setMeetingAuthToken: (
    meetingAuthToken: MeetingStoreState['meetingAuthToken']
  ) => {
    set({ meetingAuthToken })
    if (meetingAuthToken) {
      set({ isMeetingStarted: true })
    }
  },
  setIsMeetingStarted: (
    isMeetingStarted: MeetingStoreState['isMeetingStarted']
  ) => {
    set({ isMeetingStarted })
    // if (isMeetingStarted && get().callInitiatedBy === 'agent') {
    //   setTimeout(() => {
    //     set({ isCallRinging: true })
    //   }, 10000)
    // }
    // else {
    //   setTimeout(() => {
    //     set({ isCallRinging: false })
    //   }, 10000)
    // }
  },
  setIsUserJoined: (isUserJoined: MeetingStoreState['isUserJoined']) => {
    set({ isUserJoined })
    if (isUserJoined) {
      set({ isCallRinging: false })
    }
  },
  setIsUserDisconnected: (
    isUserDisconnected: MeetingStoreState['isUserDisconnected']
  ) => set({ isUserDisconnected }),
  setUserDetails: (userDetails: MeetingStoreState['userDetails']) =>
    set({ userDetails }),
  setMeetingInitType: (meetingInitType: MeetingStoreState['meetingInitType']) =>
    set({ meetingInitType }),
  setRecStartTimestamp: (
    recStartTimestamp: MeetingStoreState['recStartTimestamp']
  ) => set({ recStartTimestamp }),
  endMeeting: () => {
    set({ endCall: meetingStoreState.endCall })
    set({ meetingAuthToken: meetingStoreState.meetingAuthToken })
    set({ isMeetingStarted: meetingStoreState.isMeetingStarted })
    set({ isUserJoined: meetingStoreState.isMeetingStarted })
    set({ userDetails: meetingStoreState.userDetails })
    set({ meetingInitType: meetingStoreState.meetingInitType })
    set({ recStartTimestamp: meetingStoreState.recStartTimestamp })
    set({ isCallRinging: meetingStoreState.isCallRinging })
    set({ isUserDisconnected: meetingStoreState.isUserDisconnected })
    set({ meetingId: meetingStoreState.meetingId })
    set({ callId: meetingStoreState.callId })
    set({ callInitiatedBy: meetingStoreState.callInitiatedBy })
  },
  startMeeting: async (type, id) => {
    const endUserId = id
    const meetingId = id
    // API call to start the meeting
    // set({ isTasksDataLoading: true })
    try {
      const response =
        type === 'post'
          ? await postStartMeeting(endUserId)
          : await getStartMeeting(meetingId)
      const data = response.data?.data
      const updatedData = snakeToCamelCase(data)

      set({ meetingId: updatedData?.meetingId })
      if (updatedData?.callId) {
        set({ callId: updatedData?.callId })
      }
      set({
        meetingAuthToken:
          type === 'post'
            ? updatedData?.authToken
            : updatedData?.clientAuthToken,
      })
      set({ callInitiatedBy: type === 'post' ? 'agent' : 'endUser' })
      // if (updatedData?.meetingAuthToken) {
      //   set({ isMeetingStarted: true })
      // }
    } catch (e) {
      console.error(e)
      get().endMeeting()
    } finally {
      // set({ isTasksDataLoading: false })
    }
  },

  setCallInitiatedBy: (callInitiatedBy: MeetingStoreState['callInitiatedBy']) =>
    set({ callInitiatedBy }),
}))
