import { create } from 'zustand'
import { CommonStoreState, CommonStoreType } from './common-store.type'
import useMeetingStore from '../meeting-store'

const commonStoreState: CommonStoreState = {
  themeMode: 'light',
  userVolume: 100,

  isRecordVoiceNotesOpen: false,

  agentStatusBannerDropdownAnchorEl: null,
  agentStatusBannerDropdownOpen: false,

  verifyEmail: {
    email: '',
  },
}

export const useCommonStore = create<CommonStoreType>((set) => ({
  // State
  ...commonStoreState,

  // Actions
  // themeMode actions
  setThemeMode: (themeMode: CommonStoreState['themeMode']) =>
    set({ themeMode }),
  toggleThemeMode: () =>
    set((state) => ({
      themeMode: state.themeMode === 'light' ? 'dark' : 'light',
    })),
  // userVolume actions
  setUserVolume: (userVolume: CommonStoreState['userVolume']) =>
    set({ userVolume }),
  setIsRecordVoiceNotesOpen: (
    isRecordVoiceNotesOpen: CommonStoreState['isRecordVoiceNotesOpen']
  ) => {
    if (
      !isRecordVoiceNotesOpen ||
      !useMeetingStore.getState().isMeetingStarted
    ) {
      set({ isRecordVoiceNotesOpen })
    }
  },
  setVerifyEmail: (verifyEmail) => set({ verifyEmail }),
  resetCommonStore: () => set(commonStoreState),
}))
