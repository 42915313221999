import { Box, Theme, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { secToMinAndSec } from '../../helpers/date-helpers'

export type TimeElapsedProps = {
  startTimestamp: string
  sx?: (theme: Theme) => object
}

function TimeElapsed(props: TimeElapsedProps): JSX.Element {
  const { startTimestamp, sx } = props
  const [elapsedTime, setElapsedTime] = useState('00:00')

  useEffect(() => {
    const setTimer = (): void => {
      const currentTime = Date.now()
      const startTime = new Date(startTimestamp).getTime()
      const newElapsedTime = Math.floor((currentTime - startTime) / 1000)
      setElapsedTime(secToMinAndSec(newElapsedTime))
    }
    // Start the timer interval
    const timerInterval = setInterval(() => {
      setTimer()
    }, 1000)

    // Set the initial time
    setTimer()

    return () => clearInterval(timerInterval)
  }, [startTimestamp])

  return (
    <Box>
      <Typography variant="body2" sx={(theme) => (sx ? sx(theme) : {})}>
        {elapsedTime}s
      </Typography>
    </Box>
  )
}

TimeElapsed.defaultProps = {
  sx: () => ({}),
}

export default TimeElapsed
