import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Layout from '../components/partials/Layout/layout'
import { DASHBOARD_PATH, LOGIN_PATH, ONBOARDING_PATH } from '../constants/paths'
import useProfileStore from '../state/profile-store'
import FullScreenLoader from '../components/partials/Loaders/full-screen-loader'

// export type PrivateRoutesProps = {
//   loggedIn: boolean
//   isProfileLoading: boolean
// }

export function PrivateRoutes(): JSX.Element {
  // props: PrivateRoutesProps
  // Login
  const loggedIn = useProfileStore((state) => state.loggedIn)
  const isProfileLoading = useProfileStore((state) => state.isUserInfoLoading)

  const location = useLocation()
  const isOnboardingPage = location.pathname.includes(ONBOARDING_PATH)

  const { onboarded: isUserOnboardingDone } = useProfileStore(
    (state) => state.userInfo
  )
  const { onboarded: isOrgOnboardingDone } = useProfileStore(
    (state) => state.organization
  )

  const children =
    !isOrgOnboardingDone || !isUserOnboardingDone ? (
      <Navigate to={ONBOARDING_PATH} />
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    )
  const onboardingChildren =
    isOrgOnboardingDone && isUserOnboardingDone ? (
      <Navigate to={DASHBOARD_PATH} />
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    )

  const component = isOnboardingPage ? onboardingChildren : children

  if (isProfileLoading) {
    return <FullScreenLoader />
  }
  return loggedIn ? component : <Navigate to={LOGIN_PATH} />
}
