import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import {
  OnboardingStoreState,
  OnboardingStoreType,
} from './onboarding-store.type'
import { daysOfWeek } from '../../helpers/date-helpers'

export const DEFAULT_DAYS_OFFICE_HOURS = daysOfWeek.reduce((acc, day) => {
  const isSatOrSun = day === 'Saturday' || day === 'Sunday'
  const isOpen = !isSatOrSun
  return { ...acc, [day]: { isOpen, start: '9:00 AM', end: '5:00 PM' } }
}, {})

const onboardingStoreState: OnboardingStoreState = {
  onboardingBy: {
    selectedButton: '',
    email: '',
  },
  welcomeVideoSeen: false,
  totalSteps: 10,
  isOnStep: 1,
  completedSteps: [],
  profile: {
    firstName: '',
    lastName: '',
    // TODO: Get email and company from profile store
    email: '',
    company: '',
    department: '',
    jobTitle: '',
  },
  teamDetails: {
    teamName: '',
    selectedTimezone: '',
    daysOfficeHours: DEFAULT_DAYS_OFFICE_HOURS,
  },
  launcherSettings: {
    avatarSelected: '1',
    positionSelected: '1',
  },
  welcomeNotes: {
    sendAutomatically: true,
    sendAutomaticallyAfter: '30',
    selectedAudio: null,
  },
  callYouBackNotes: {
    selectedAudio: null,
  },
  outOfOfficeNotes: {
    selectedAudio: null,
  },
  integrations: {
    slack: {
      isIntegrated: false,
      redirectLink: '',
      isNotUsing: false,
    },
    googleCalendar: {
      isIntegrated: false,
      redirectLink: '',
    },
    outlookCalendar: {
      isIntegrated: false,
      redirectLink: '',
    },
  },
  checkIn: {
    CheckInMasterSwitch: false,
    CheckInSkip: false,
    CheckInSupportEmail: '',
  },
}

export const useOnboardingStore = create<OnboardingStoreType>()(
  persist(
    (set, get) => ({
      // State
      ...onboardingStoreState,

      // Actions
      // loggedIn actions
      setIsOnStep: (isOnStep) => set({ isOnStep }),
      setCompletedSteps: (step, type) => {
        const index = get().completedSteps.indexOf(step)
        if (type === 'remove') {
          if (index !== -1) {
            set((state) => ({
              completedSteps: [...state.completedSteps].splice(index, 1),
            }))
          }
        } else if (type === 'add' && index === -1) {
          set((state) => ({
            completedSteps: [...state.completedSteps, step],
          }))
        }
      },
      setTotalSteps: (totalSteps) => set({ totalSteps }),
      setProfile: (key, value) =>
        set((state) => ({ profile: { ...state.profile, [key]: value } })),
      setTeamDetails: (key, value) =>
        set((state) => ({
          teamDetails: { ...state.teamDetails, [key]: value },
        })),
      setLauncherSettings: (key, value) =>
        set((state) => ({
          launcherSettings: { ...state.launcherSettings, [key]: value },
        })),
      setWelcomeNotes: (key, value) =>
        set((state) => ({
          welcomeNotes: { ...state.welcomeNotes, [key]: value },
        })),
      setCallYouBackNotes: (key, value) =>
        set((state) => ({
          callYouBackNotes: { ...state.callYouBackNotes, [key]: value },
        })),
      setOutOfOfficeNotes: (key, value) =>
        set((state) => ({
          outOfOfficeNotes: { ...state.outOfOfficeNotes, [key]: value },
        })),
      setOnboardingBy: (key, value) =>
        set((state) => ({
          onboardingBy: { ...state.onboardingBy, [key]: value },
        })),
      setIntegrations: (key, value) =>
        set((state) => ({
          integrations: { ...state.integrations, [key]: value },
        })),
      setCheckIn: (key, value) =>
        set((state) => ({
          checkIn: { ...state.checkIn, [key]: value },
        })),
      toggleWelcomeVideoSeen: () =>
        set((state) => ({ welcomeVideoSeen: !state.welcomeVideoSeen })),
      resetOnboardingStore: () => {
        set(onboardingStoreState)
      },
    }),
    {
      name: 'pb-onboarding-store',
      partialize: (state) => {
        const {
          onboardingBy,
          welcomeVideoSeen,
          isOnStep,
          totalSteps,
          completedSteps,
          profile,
          teamDetails,
          launcherSettings,
          welcomeNotes,
          callYouBackNotes,
          outOfOfficeNotes,
          integrations,
          checkIn,
        } = state
        return {
          onboardingBy,
          welcomeVideoSeen,
          isOnStep,
          totalSteps,
          completedSteps,
          profile,
          teamDetails,
          launcherSettings,
          welcomeNotes,
          callYouBackNotes,
          outOfOfficeNotes,
          integrations,
          checkIn,
        }
      },
    }
  )
)
