import { AxiosResponse } from 'axios'
import { GET_ACTIVITY_EVENTS_LIST_ENDPOINT } from '../constants/api'
import { getResponse } from '../helpers/api-helpers'
import { API } from '../services/api'

export async function getEventList(
  type: 'all' = 'all'
): Promise<AxiosResponse<any, any>> {
  const typeSlugMapping = {
    all: 'all',
    // TODO: Add more mappings
  }
  const url = `${GET_ACTIVITY_EVENTS_LIST_ENDPOINT}${typeSlugMapping[type]}`
  return getResponse(
    API.get(url, {
      headers: { includeAuth: true },
    })
  )
}
