// This component is used to listen to the presence channel and check if end-user can be callable or not.
import React, { useState, useEffect } from 'react'
import Pusher from 'pusher-js/types/src/core/pusher'
import usePusherStore from '../../state/pusher-store/index'
import useNotification from '../../side-effects/hooks/useNotification'
import useProfileStore from '../../state/profile-store'
import { encodeToBase64 } from '../../utils/common'

export type UserInfoData = {
  id: string
}
function InteractionWidgetPusher(): JSX.Element {
  const organization = useProfileStore((state: any) => state.organization)
  const user = useProfileStore((state: any) => state.userInfo)
  const endUsersInteracting = usePusherStore(
    (state: any) => state.endUsersInteracting
  )

  const updateEndUsersInteracting = usePusherStore(
    (state: any) => state.updateEndUsersInteracting
  )

  const { token: orgName } = organization
  const name = orgName.toLowerCase()
  const { id } = user

  const channelSuffix = 'widget'

  const finalChannelName = `${name}-${channelSuffix}`

  useEffect(() => {
    // Presence channel
    usePusherStore
      .getState()
      .initializeChannel(finalChannelName, 'presence', encodeToBase64(id))

    usePusherStore
      .getState()
      .bindEvent(
        `presence-${finalChannelName}`,
        'pusher:subscription_succeeded',
        (members) => {
          updateEndUsersInteracting(
            Object.keys(members.members).filter(
              (eve) => Number(eve) !== Number(id)
            )
          )
        }
      )

    return () => {
      usePusherStore.getState().cleanupChannel(`presence-${finalChannelName}`)
    }
  }, [name, id])

  useEffect(() => {
    if (id && name) {
      usePusherStore
        .getState()
        .bindEvent(
          `presence-${finalChannelName}`,
          'pusher:member_added',
          (member) => {
            if (
              endUsersInteracting?.length > 0 &&
              !endUsersInteracting.includes(member.id) &&
              member.id !== id
            ) {
              updateEndUsersInteracting([...endUsersInteracting, member.id])
            } else {
              updateEndUsersInteracting([member.id])
            }
          }
        )

      usePusherStore
        .getState()
        .bindEvent(
          `presence-${finalChannelName}`,
          'pusher:member_removed',
          (member) => {
            console.log('member removed', member, endUsersInteracting)
            if (endUsersInteracting?.length > 0) {
              console.log(
                'check',
                endUsersInteracting.filter(
                  (m) => Number(m) !== Number(member.id)
                )
              )
              updateEndUsersInteracting(
                endUsersInteracting.filter(
                  (m) => Number(m) !== Number(member.id)
                )
              )
            }
          }
        )
    }
  }, [id, name, endUsersInteracting])

  return <div> </div>
}

export default InteractionWidgetPusher
