import { Stack, Theme } from '@mui/material'
import React from 'react'

export type SvgIconReactComponentProps = {
  width?: number | string
  height?: number | string
  background?: (theme: Theme) => string
  borderRadius?: string
  children: React.ReactNode
  onClick?: () => void
}

function SvgIconReactComponent(props: SvgIconReactComponentProps): JSX.Element {
  const { width, height, background, borderRadius, children, onClick } = props
  return (
    <Stack
      width={width}
      height={height}
      sx={{
        background: (theme) => (background ? background(theme) : 'inherit'),
        cursor: onClick ? 'pointer' : 'auto',
      }}
      justifyContent="center"
      alignItems="center"
      borderRadius={borderRadius || 'initial'}
      onClick={onClick}
    >
      {children}
    </Stack>
  )
}

SvgIconReactComponent.defaultProps = {
  width: 16,
  height: 16,
  background: null,
  borderRadius: null,
  onClick: null,
}

export default SvgIconReactComponent
