import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import {
  Notification,
  NotificationStoreState,
  NotificationStoreType,
} from './notifications-store.type'

const notificationStoreState: NotificationStoreState = {
  notifications: [],
  email: '',
  savedNotification: {},
}

export const useNotificationStore = create<NotificationStoreType>()(
  persist(
    (set, get) => ({
      ...notificationStoreState,
      setEmail: (email) => set({ email }),
      addNotification: (notification: Notification) => {
        if (
          !get().notifications.find(
            (notificationObj) => notificationObj.id === notification.id
          )
        ) {
          set((state) => ({
            notifications: [...state.notifications, notification],
          }))
        }
        return notification.id
      },
      removeNotification: (id: string) => {
        set((state) => ({
          notifications: state.notifications.filter(
            (notification) => notification.id !== id
          ),
        }))
      },
      saveNotification: (id: string) =>
        set((state) => {
          const requiredNotification = state.notifications.find(
            (notification) => notification.id === id
          )
          if (requiredNotification) {
            return {
              savedNotification: {
                ...state.savedNotification,
                [id]: requiredNotification,
              },
            }
          }
          return state // Add this line to return the updated state
        }),
    }),
    {
      name: 'saved-notification', // name of the item in the local storage
      partialize: (state) => {
        const { notifications, email } = state
        return { notifications, email }
      },
    }
  )
)
