import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import moment from 'moment-timezone'
import {
  ProfileStoreState,
  ProfileStoreType,
  UserInfo,
  Organization,
} from './profile-store.type'

import { getProfile, putAgentStatus } from '../../side-effects/profile'
import { snakeToCamelCase } from '../../helpers/api-helpers'
import { daysOfWeek } from '../../helpers/date-helpers'
import useOnboardingStore from '../onboarding-store'

const profileStoreState: ProfileStoreState = {
  loggedIn: false,
  userInfo: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    department: '',
    jobTitle: '',
    photo: '',
    role: '',
    onboarded: true,
    isAgentOnline: true,
  },
  organization: {
    name: '',
    token: '',
    website: '',
    teamName: '',
    onboarded: true,
    widget: {
      avatar: '',
      position: '',
      isActive: true,
    },
    welcomeNote: null,
    callYouBackNote: null,
    outOfOfficeNote: null,
    officeHours: [],
    timezone: '',
    autoSendWelcomeNote: false,
    autoSentAfter: '',
    onboardedBy: '',
    banners: [],
    checkIn: {
      masterSwitch: false,
      skipSwitch: false,
      supportEmail: '',
    },
  },
  isUserInfoLoading: true,
  isUpdateProfileLoading: false,
}

export const useProfileStore = create<ProfileStoreType>()(
  // persist(
  (set, get) => ({
    // State
    ...profileStoreState,

    // Actions
    // loggedIn actions
    setLoggedIn: (loggedIn) => {
      set({ loggedIn })
    },
    setUserInfo: (key, value) =>
      set((state) => ({
        userInfo: { ...state.userInfo, [key]: value },
      })),
    setOrganizationKeyValue: (key, value) =>
      set((state) => ({
        organization: { ...state.organization, [key]: value },
      })),
    setOrganization: (organization) => set(() => ({ organization })),
    getUserInfo: async () => {
      set(() => ({ isUserInfoLoading: true }))

      try {
        const userInfo = await getProfile()
        // set(() => ({ loggedIn: true }))

        const { data } = userInfo
        const { user, organization } = data

        const updatedOrganization = snakeToCamelCase(organization)

        const newUserInfo: UserInfo = {
          id: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          company: updatedOrganization.name,
          department: data.department,
          jobTitle: data.job_title,
          photo: user.photo,
          role: data.role,
          onboarded: data.onboarded,
          isAgentOnline: data.is_client_online ?? true,
        }

        const newOrganization = {
          ...updatedOrganization,
          autoSentAfter: updatedOrganization.autoSentAfter || '',
        }

        set(() => ({ userInfo: newUserInfo }))
        set(() => ({ organization: newOrganization }))

        const newEmail = newUserInfo.email
        const oldOnboardingEmail = useOnboardingStore.getState().profile.email
        // TODO: Also add if the old onboarding email is empty
        if (
          newEmail !== oldOnboardingEmail &&
          (!data.onboarded || !newOrganization.onboarded)
        ) {
          useOnboardingStore.getState().resetOnboardingStore()
        }
        // if (!get().loggedIn) {
        //   set(() => ({ loggedIn: true }))
        // }
        return Promise.resolve(userInfo)
      } catch (error) {
        console.error(error)
        set(() => ({ userInfo: profileStoreState.userInfo }))
        return Promise.reject(error)
      } finally {
        set(() => ({ isUserInfoLoading: false }))
      }
    },
    setAgentOnlineStatus: async (active, timeDelta) => {
      const currentTime = new Date()
      let futureTimeUTC = null
      if (!active) {
        if (timeDelta) {
          // get().setUserInfo('isAgentOnline', false)
          const futureTime = new Date(currentTime.getTime() + timeDelta)
          futureTimeUTC = futureTime.toISOString()
          // False with timer
        }
      } else if (active && timeDelta) {
        // get().setUserInfo('isAgentOnline', true)
        const futureTime = new Date(currentTime.getTime() + timeDelta)
        futureTimeUTC = futureTime.toISOString()
        // True with timer
      } else {
        // get().setUserInfo('isAgentOnline', true)
        // True without timer
      }
      try {
        const response = await putAgentStatus(active, futureTimeUTC)
        Promise.resolve(response)
      } catch (error) {
        Promise.reject(error)
      }
    },
    clearProfileStore: () => set({ ...profileStoreState }),
    setIsUpdateProfileLoading: (isUpdateProfileLoading) =>
      set(() => ({ isUpdateProfileLoading })),
    setIsUserInfoLoading: (isUserInfoLoading) =>
      set(() => ({ isUserInfoLoading })),
    resetProfileStore: () => {
      set(profileStoreState)
    },
  })
  //   ,{
  //     name: 'pb-profile-store',
  //     partialize: (state) => {
  //       const { loggedIn, userInfo } = state
  //       return { loggedIn, userInfo }
  //     },
  //   }
  // )
)
