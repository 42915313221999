import React from 'react'
import {
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  alpha,
  Link,
} from '@mui/material'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import { UserEvent } from './user-timeline'
import AudioControls from '../Audio/audio-controls'
import {
  TimelineCallHeldIconComponent,
  TimelineCallScheduledIconComponent,
  TimelineDeclinedCallIconComponent,
  TimelineLoggedInIconComponent,
  TimelineMicIconComponent,
  TimelineMissedCallIconComponent,
  TimelineCheckInIconComponent,
  TimelineSessionRecordingIconComponent,
} from '../../../services/image-service'
import SvgIconReactComponent from '../svg-icon-react-component'
import { getDeviceDetails } from '../../../helpers/browser'
import { getSummary } from '../../../helpers/june-helpers'
import {
  PI_TIMELINE_CALL_RECORDING_LISTENED,
  PI_TIMELINE_VOICE_NOTE_LISTENED,
  PI_TIMELINE_VOICE_NOTE_LISTENED_EXTERNAL,
  TIMELINE,
} from '../../../constants/june_so'
import useJuneAnalyticsStore from '../../../state/june-store'
import useProfileStore from '../../../state/profile-store'
import { secToMinAndSec } from '../../../helpers/date-helpers'

export const eventTimelineTypeMapping: {
  [key: string]:
    | 'callScheduled'
    | 'scheduledCallHeld'
    | 'callHeld'
    | 'missedCall'
    | 'voiceNote'
    | 'loggedIn'
    | 'declinedCall'
    | 'checkInSkipped'
    | 'checkInCompleted'
    | 'checkInNotApplicable'
    | 'sessionRecording'
} = {
  CALL_SCHEDULED: 'callScheduled',
  SCHEDULED_CALL_HELD: 'scheduledCallHeld',
  CALLED_US: 'callHeld',
  ANSWERED_OUR_CALL: 'callHeld',
  MISSED_OUR_CALL: 'missedCall',
  MISSED_THEIR_CALL: 'missedCall',
  SENT_US_AUDIO_NOTE: 'voiceNote',
  WE_SENT_AUDIO_NOTE: 'voiceNote',
  LOGGED_IN: 'loggedIn',
  DECLINED_CALL: 'declinedCall',
  CHECKIN_SKIPPED: 'checkInSkipped',
  CHECKIN_COMPLETED: 'checkInCompleted',
  CHECKIN_NOT_APPLICABLE: 'checkInNotApplicable',
  SESSION_RECORDING: 'sessionRecording',
}

export const receivedEventTypes = [
  'CALL_SCHEDULED',
  'CALLED_US',
  'MISSED_THEIR_CALL',
  'SENT_US_AUDIO_NOTE',
  'LOGGED_IN',
  'SESSION_RECORDING',
]

function TimelineItem(props: {
  event: UserEvent
  currentPlayingIndex: string | null
  handlePlay: (index: string) => void
  setCurrentPlayingIndex: () => void
  itemRefs: Record<string | number, React.RefObject<HTMLLIElement>>
  highlightedRow?: boolean
  onEventClick?: (event: UserEvent) => void
}): JSX.Element {
  const {
    event,
    currentPlayingIndex,
    handlePlay,
    setCurrentPlayingIndex,
    itemRefs,
    highlightedRow,
    onEventClick,
  } = props

  // June.so Required Config
  const { getAnalytics } = useJuneAnalyticsStore.getState()
  const analytics = getAnalytics()
  const organization = useProfileStore((state) => state.organization)
  const userInfo = useProfileStore((state) => state.userInfo)
  const { token: orgToken } = organization
  const { id: customerId, onboarded } = userInfo
  const deviceDetails = getDeviceDetails()

  const dateObject = new Date(event.timestamp)
  const formattedTime = dateObject.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  const formattedDate = dateObject.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
  const formattedDateWithTime = `${formattedTime}, ${formattedDate}`

  //   export const TimelineCallHeldIcon = TimelineCallHeld
  // export const TimelineCallScheduledIcon = TimelineCallScheduled
  // export const TimelineLoggedInIcon = TimelineLoggedIn
  // export const TimelineMicIconComponent = TimelineMicIcon
  // export const TimelineMissedCallIcon = TimelineMissedCall

  const icons: { [key: string]: React.ReactNode } = {
    loggedIn: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineLoggedInIconComponent />
      </SvgIconReactComponent>
    ),
    callScheduled: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineCallScheduledIconComponent />
      </SvgIconReactComponent>
    ),
    callHeld: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineCallHeldIconComponent style={{ transform: 'scale(90%)' }} />
      </SvgIconReactComponent>
    ),
    scheduledCallHeld: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineCallHeldIconComponent style={{ transform: 'scale(90%)' }} />
      </SvgIconReactComponent>
    ),
    missedCall: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineMissedCallIconComponent />
      </SvgIconReactComponent>
    ),
    voiceNote: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineMicIconComponent />
      </SvgIconReactComponent>
    ),
    declinedCall: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineDeclinedCallIconComponent />
      </SvgIconReactComponent>
    ),
    checkInSkipped: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineCheckInIconComponent />
      </SvgIconReactComponent>
    ),
    checkInCompleted: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineCheckInIconComponent />
      </SvgIconReactComponent>
    ),
    checkInNotApplicable: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineCheckInIconComponent />
      </SvgIconReactComponent>
    ),
    sessionRecording: (
      <SvgIconReactComponent width={20} height={20}>
        <TimelineSessionRecordingIconComponent />
      </SvgIconReactComponent>
    ),
  }

  const getTitle = (type: string, isReceived: boolean): string => {
    switch (eventTimelineTypeMapping[type]) {
      case 'loggedIn':
        return 'User is live'
      case 'callScheduled':
        return 'Call Scheduled'
      case 'scheduledCallHeld':
        return 'Scheduled Call Held'
      case 'callHeld':
        return isReceived ? 'Called Us' : 'Answered Our Call'
      case 'missedCall':
        return isReceived ? 'Missed Their Call' : 'Missed Our Call'
      case 'voiceNote':
        return isReceived ? 'Voice Note Received' : 'Voice Note Sent'
      case 'declinedCall':
        return 'Declined Our Call'
      case 'checkInSkipped':
        return 'Check-in Skipped'
      case 'checkInCompleted':
        return 'Check-in Completed'
      case 'checkInNotApplicable':
        return 'Check-in Not Applicable'
      case 'sessionRecording':
        return event.name || 'Session Recording'
      default:
        return ''
    }
  }

  const getSubTitle = (): string => {
    if (eventTimelineTypeMapping[event.type] === 'loggedIn') {
      return ''
    }
    if (eventTimelineTypeMapping[event.type] === 'callScheduled') {
      return event.receiver ? `With ${event.receiver}` : ''
    }
    // TODO: Add scheduled call held
    if (eventTimelineTypeMapping[event.type] === 'callHeld') {
      return event.isReceived
        ? `Answered by ${event.receiver}`
        : `Call initiated by ${event.sender}`
    }
    if (eventTimelineTypeMapping[event.type] === 'missedCall') {
      return event.isReceived ? '' : `Call initiated by ${event.sender}`
    }
    if (eventTimelineTypeMapping[event.type] === 'voiceNote') {
      return ''
    }
    if (eventTimelineTypeMapping[event.type] === 'declinedCall') {
      return event.isReceived ? '' : `Call initiated by ${event.sender}`
    }
    return ''
  }

  const getRightFooterText = (): JSX.Element | null => {
    if (event.type === 'WE_SENT_AUDIO_NOTE') {
      return (
        <Typography variant="caption2" noWrap>
          {event.isAudioPlayed ? 'Played' : 'Not Played'}
        </Typography>
      )
    }
    if (event.type === 'SESSION_RECORDING' && event?.duration) {
      return (
        <Typography variant="caption2" noWrap>
          Duration: {secToMinAndSec(event.duration / 1000)}
        </Typography>
      )
    }
    return null
  }

  return (
    <ListItem
      sx={{
        justifyContent: !event.isReceived ? 'end' : 'start',
        ...(highlightedRow && {
          background: ({ palette }) => alpha(palette.tertiary.main, 0.06),
        }),
      }}
      ref={itemRefs[event.id]}
    >
      <Stack width="80%" spacing={0.5}>
        <Stack>
          <Typography variant="caption2" noWrap>
            {formattedDateWithTime}
          </Typography>
        </Stack>
        <Stack
          p={1}
          spacing={1}
          borderRadius={({ shape }) => `${shape.borderRadius}px`}
          sx={{
            background: ({ palette }) =>
              !event.isReceived
                ? palette.secondary.lighter
                : palette.primary.lighter,
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <SvgIconReactComponent
              width={32}
              height={32}
              background={({ palette }) => palette.common.white}
              borderRadius="50%"
            >
              {icons[eventTimelineTypeMapping[event.type]]}
            </SvgIconReactComponent>
            <Stack flex={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2" fontWeight={500} noWrap>
                  {getTitle(event.type, event.isReceived)}
                </Typography>
                {event.isVideo && event.videoSrc && (
                  <IconButton
                    color="secondary"
                    onClick={() => onEventClick?.(event)}
                  >
                    <PlayCircleOutlineRoundedIcon />
                  </IconButton>
                )}
              </Stack>
              <Typography variant="body2" noWrap fontStyle="italic">
                {getSubTitle()}
              </Typography>
            </Stack>
          </Stack>
          {event.isAudio && event.audioSrc && (
            <Stack>
              <AudioControls
                audioSrc={event.audioSrc}
                isCurrentPlaying={currentPlayingIndex === event.id}
                index={event.id}
                onPlay={(index) => {
                  handlePlay(index)

                  if (
                    eventTimelineTypeMapping[event.type] === 'callHeld' ||
                    eventTimelineTypeMapping[event.type] === 'voiceNote'
                  ) {
                    let finalEventType = ''
                    if (eventTimelineTypeMapping[event.type] === 'callHeld') {
                      finalEventType = PI_TIMELINE_CALL_RECORDING_LISTENED
                    } else if (
                      eventTimelineTypeMapping[event.type] === 'voiceNote'
                    ) {
                      if (event.isReceived) {
                        finalEventType =
                          PI_TIMELINE_VOICE_NOTE_LISTENED_EXTERNAL
                      } else {
                        finalEventType = PI_TIMELINE_VOICE_NOTE_LISTENED
                      }
                    }

                    // June.so tracking
                    analytics?.track(finalEventType, {
                      pathname: window.location.pathname,
                      status: true,
                      timestamp: new Date().toISOString(),
                      event_type: finalEventType,
                      user_id: customerId,
                      device: deviceDetails,
                      summary: getSummary(finalEventType, String(customerId)),
                      view: window.location.pathname,
                      subject: TIMELINE,
                      object: {
                        onboarded,
                      },
                      orgKey: orgToken,
                    })
                  }
                }}
                onStop={setCurrentPlayingIndex}
              />
            </Stack>
          )}
        </Stack>
        <Stack>
          <Typography
            fontSize={12}
            sx={(theme) => ({
              color: theme.palette.secondary.main,
            })}
          >
            <Link
              href={decodeURIComponent(event.requestMeta || '')}
              target="_blank"
              rel="noreferrer"
              underline="always"
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                textDecorationColor: theme.palette.secondary.main,
              })}
              // color={(theme) => theme.palette.secondary.main}
            >
              Source URL
            </Link>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography variant="caption2" noWrap>
            {event.type === 'WE_SENT_AUDIO_NOTE' && event.isAutoSent
              ? 'Auto Note'
              : event.sender}
          </Typography>
          {getRightFooterText()}
        </Stack>
      </Stack>
    </ListItem>
  )
}

TimelineItem.defaultProps = {
  highlightedRow: false,
  onEventClick: null,
}

export default TimelineItem
