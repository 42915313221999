import { alpha } from '@mui/material'

const containedVariants = (palette: any, type: string): object => {
  const colors: { [key: string]: string } = {
    containedPrimary: palette.primary.main,
    containedSecondary: palette.secondary.main,
    containedError: palette.error.main,
    containedWarning: palette.warning.main,
    containedInfo: palette.info.main,
    containedSuccess: palette.success.main,
  }
  return {
    [type]: {
      '&:disabled': {
        backgroundColor: alpha(colors[type], 0.54),
        color: palette.common.white,
      },
    },
  }
}

export const MuiButton = (palette: any): object => {
  return {
    styleOverrides: {
      root: {
        padding: '0.875rem 2rem',
      },
      contained: {},
      ...containedVariants(palette, 'containedPrimary'),
      ...containedVariants(palette, 'containedSecondary'),
      ...containedVariants(palette, 'containedError'),
      ...containedVariants(palette, 'containedWarning'),
      ...containedVariants(palette, 'containedInfo'),
      ...containedVariants(palette, 'containedSuccess'),
      outlined: {},
      text: {},
    },
    defaultProps: {
      disableElevation: true,
    },
  }
}
