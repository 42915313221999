import { Theme, createTheme } from '@mui/material/styles'
import { colorPalette } from './color-palette'
import { Mode } from './mui-theme.type'
import { typography } from './typography'
import { MuiButton } from './mui-button'
import { MuiCssBaseline } from './mui-css-baseline'
import { MuiAppBar } from './mui-app-bar'
import { MuiFormControlLabel } from './mui-form-control-label'
import { MuiFormHelperText } from './mui-form-helper-text'
import { MuiTextField } from './mui-text-field'
import { MuiSelect } from './mui-select'
import { MuiSwitch } from './mui-switch'
import { MuiCheckbox } from './mui-checkbox'
import { MuiTableCell } from './mui-table-cell'
import { MuiTableRow } from './mui-table-row'
import { MuiTabs } from './mui-tabs'
import { MuiTab } from './mui-tab'
import { MuiSlider } from './mui-slider'
import { MuiToggleButtonGroup } from './mui-toggle-button-group'
import { MuiToggleButton } from './mui-toggle-button'

/**
 * This is used to create a theme used in the whole platform.
 *
 * @see See [Mui Theming](https://mui.com/material-ui/customization/theming/)
 * @param mode
 */
export const theme = (mode: Mode): Theme => {
  // MUI Default Theme
  const defaultPalette = createTheme({
    palette: {
      mode,
    },
  }).palette
  const palette: any = colorPalette(mode, defaultPalette)
  return createTheme({
    palette,
    typography: typography(palette),
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiCssBaseline: MuiCssBaseline(palette),
      MuiButton: MuiButton(palette),
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body1Bold: 'p',
            caption2: 'p',
          },
        },
      },
      MuiAppBar: MuiAppBar(palette),
      MuiFormControlLabel: MuiFormControlLabel(palette),
      MuiFormHelperText: MuiFormHelperText(palette),
      MuiTextField: MuiTextField(palette),
      MuiSelect: MuiSelect(palette),
      MuiSwitch: MuiSwitch(palette),
      MuiCheckbox: MuiCheckbox(palette),
      MuiTableCell: MuiTableCell(palette),
      MuiTableRow: MuiTableRow(palette),
      MuiTabs: MuiTabs(),
      MuiTab: MuiTab(),
      MuiSlider: MuiSlider(),
      MuiToggleButtonGroup: MuiToggleButtonGroup(),
      MuiToggleButton: MuiToggleButton(),
    },
  })
}
