import {
  Box,
  IconButton,
  Slider,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded'
import getBlobDuration from 'get-blob-duration'
import React, { useEffect, useRef, useState } from 'react'
import { secToMinAndSec } from '../../../helpers/date-helpers'
import useCommonStore from '../../../state/common-store'

export type AudioControlsProps = {
  audioSrc: string
  autoPlay?: boolean
  audioType?: 'url' | 'blob'
  isCurrentPlaying: boolean
  index: string
  hideVolumeSlider?: boolean
  onPlay: (index: string) => void
  onStop: () => void
  onClose?: () => void
  defaultCurrentTime?: number
  setDefaultCurrentTime?: (newTime: number) => void
}

function AudioControls(props: AudioControlsProps): JSX.Element {
  const {
    audioSrc,
    autoPlay,
    audioType,
    isCurrentPlaying,
    index,
    hideVolumeSlider,
    onPlay,
    onStop,
    onClose,
    defaultCurrentTime,
    setDefaultCurrentTime,
  } = props
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(defaultCurrentTime || 0)
  const [duration, setDuration] = useState(0)
  const [progressBarValue, setProgressBarValue] = useState(0)

  const [isDefaultParsed, setIsDefaultParsed] = useState(false)

  const userVolume = useCommonStore((state) => state.userVolume)
  const setUserVolume = useCommonStore((state) => state.setUserVolume)

  useEffect(() => {
    if (!isCurrentPlaying) {
      audioRef?.current?.pause()
      setIsPlaying(false)
    } else if (!isPlaying && autoPlay) {
      audioRef?.current?.play()
      setIsPlaying(true)
    }
  }, [isCurrentPlaying])

  useEffect(() => {
    if (audioType === 'blob') {
      getBlobDuration(audioSrc).then((newDuration) => {
        setDuration(newDuration)
      })
    }
  }, [audioSrc])

  useEffect(() => {
    if (!duration && audioType !== 'blob') {
      setDuration(audioRef?.current?.duration || 0)
    }
  }, [audioRef?.current?.duration])

  useEffect(() => {
    if (audioRef.current && !hideVolumeSlider) {
      audioRef.current.volume = userVolume / 100
    }
  }, [userVolume])

  useEffect(() => {
    if (duration && !isDefaultParsed) {
      if (defaultCurrentTime) {
        if (audioRef.current) {
          setCurrentTime(defaultCurrentTime)
          audioRef.current.currentTime = defaultCurrentTime
          setProgressBarValue((defaultCurrentTime * 100) / duration)
        }
      }
      setIsDefaultParsed(true)
    }
  }, [duration, isDefaultParsed])

  const handlePlay = (): void => {
    if (isPlaying) {
      audioRef?.current?.pause()
      setIsPlaying(false)
    } else {
      audioRef?.current?.play()
      onPlay(index)
      setIsPlaying(true)
    }
  }

  const handleTimeUpdate = (): void => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime)
      if (setDefaultCurrentTime)
        setDefaultCurrentTime(audioRef.current.currentTime)
      setProgressBarValue((audioRef.current.currentTime * 100) / duration)
    }
  }

  const handleLoadedData = (): void => {
    if (audioRef.current && audioType !== 'blob')
      setDuration(audioRef.current.duration)
  }

  const handleEnded = (): void => {
    if (audioRef.current) {
      // audioRef.current.currentTime = 0
      audioRef?.current?.pause()
    }
    setIsPlaying(false)
    // setCurrentTime(0)
    onStop()
  }

  const handleProgressChange = (
    event: Event,
    newValue: number | number[]
  ): void => {
    if (audioRef.current) {
      const currentTimeToSet = ((newValue as number) * duration) / 100
      audioRef.current.currentTime = currentTimeToSet
    }
  }

  const handleVolumeChange = (
    event: Event,
    newValue: number | number[]
  ): void => {
    setUserVolume(newValue as number)
  }

  return (
    <Stack
      direction="row"
      spacing={0.5}
      borderRadius={(theme) => `${theme.shape.borderRadius}px`}
      sx={{
        background: ({ palette }) => alpha(palette.secondary.main, 0.1),
      }}
      alignItems="center"
      width="100%"
      pr={1}
    >
      <Box
        id={`end-user-audio-element-${index}`}
        component="audio"
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleEnded}
        onLoadedMetadata={handleLoadedData}
      />
      <IconButton onClick={handlePlay}>
        {isCurrentPlaying && isPlaying ? (
          <PauseCircleOutlineRoundedIcon color="secondary" />
        ) : (
          <PlayCircleOutlineRoundedIcon color="secondary" />
        )}
      </IconButton>
      <Slider
        value={progressBarValue}
        onChange={handleProgressChange}
        sx={{ flexBasis: '40%' }}
        color="secondary"
      />
      <Typography variant="body2">
        {secToMinAndSec(duration - currentTime)}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        flex={1}
        spacing={0.75}
      >
        {!hideVolumeSlider && (
          <>
            <VolumeUpRoundedIcon />
            <Slider
              value={userVolume}
              onChange={handleVolumeChange}
              sx={{ flex: 1, maxWidth: 42 }}
              color="secondary"
              disabled={!isPlaying}
            />
          </>
        )}
        {Boolean(onClose) && (
          <IconButton onClick={onClose} size="small">
            <CloseRoundedIcon
              color="secondary"
              sx={{ width: 16, height: 16 }}
            />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}

AudioControls.defaultProps = {
  audioType: 'url',
  hideVolumeSlider: false,
  onClose: null,
  defaultCurrentTime: 0,
  autoPlay: false,
  setDefaultCurrentTime: null,
}

export default AudioControls
