export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function generateTimeArray(): string[] {
  const timeArray = []

  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12
      const period = hour < 12 ? 'AM' : 'PM'

      const timeString = `${formattedHour}:${minute < 10 ? '0' : ''}${minute} ${period}`
      timeArray.push(timeString)
    }
  }

  return timeArray
}

export const secToMinAndSec = (secondsToConvert: number): string => {
  const seconds = Number(secondsToConvert.toFixed(0))
  const hours: number | string = Math.floor(seconds / 3600)
  let minutes: number | string = Math.floor((seconds / 60) % 60)
  let remainingSeconds: number | string = seconds % 60

  const hoursStr = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  remainingSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds

  return `${hours ? `${hoursStr}:` : ''}${minutes}:${remainingSeconds}`
}

export const hoursOfDay = generateTimeArray()
