import { AxiosResponse } from 'axios'
import { API } from '../services/api'
import {
  ENDUSER_UNSEEN_EVENTS_ENDPOINT,
  GET_ALL_END_USERS_ENDPOINT,
  GET_CHECKLIST_ITEMS_ENDPOINT,
  GET_DASHBOARD_TASKS_COUNT_ENDPOINT,
  GET_END_USER_EVENTS_ENDPOINT,
  GET_END_USER_LIVE_SESSION_INFO_ENDPOINT,
  GET_LIVE_END_USERS_ENDPOINT,
  GET_MEETING_START_ENDPOINT,
  POST_MEETING_START_ENDPOINT,
  PUT_ENDUSER_CHECKIN_STATUS_ENDPOINT,
  UPDATE_CALL_EVENTS_ENDPOINT,
  UPLOAD_RECORDED_VOICE_NOTE_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from '../constants/api'
import { camelToSnakeCase, getResponse } from '../helpers/api-helpers'
import useMeetingStore from '../state/meeting-store'

export async function getUserProfile(): Promise<any> {
  try {
    const response = await API.get(USER_PROFILE_ENDPOINT)
    return Promise.resolve(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getDashboardTasksCount(): Promise<
  AxiosResponse<any, any>
> {
  return getResponse(
    API.get(GET_DASHBOARD_TASKS_COUNT_ENDPOINT, {
      headers: { includeAuth: true },
    })
  )
}

export async function getChecklistItems(): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.get(GET_CHECKLIST_ITEMS_ENDPOINT, {
      headers: { includeAuth: true },
    })
  )
}

export async function getEndUsersList(
  viewType: 'live' | 'all'
): Promise<AxiosResponse<any, any>> {
  if (viewType === 'live') {
    return getResponse(
      API.get(GET_LIVE_END_USERS_ENDPOINT, {
        headers: { includeAuth: true },
      })
    )
  }
  return getResponse(
    API.get(GET_ALL_END_USERS_ENDPOINT, {
      headers: { includeAuth: true },
    })
  )
}

export async function uploadRecordedVoiceNote(
  endUserId: string | number,
  blob: Blob
): Promise<AxiosResponse<any, any>> {
  const fileName = 'file'
  const blobFile = new File([blob], `${fileName}.mp3`, { type: 'audio/mp3' })
  const formData = new FormData()
  formData.append(fileName, blobFile, 'file')

  const params = {
    endUserId,
  }

  const url = `${UPLOAD_RECORDED_VOICE_NOTE_ENDPOINT}${fileName}.mp3`

  return getResponse(
    API.post(url, formData, {
      headers: {
        includeAuth: true,
        'Content-Type': 'multipart/form-data',
        // filename: `${fileName}.mp3`,
      },
      params,
    })
  )
}

export async function getUserTimelineEvents(
  endUserId: string | number
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.get(GET_END_USER_EVENTS_ENDPOINT, {
      headers: { includeAuth: true },
      params: { endUserId },
    })
  )
}

export async function postStartMeeting(
  endUserId: string | number
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.post(
      POST_MEETING_START_ENDPOINT,
      {},
      {
        headers: { includeAuth: true },
        params: { endUserId },
      }
    )
  )
}

export async function getStartMeeting(
  meetingId: string | number
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.get(GET_MEETING_START_ENDPOINT, {
      headers: { includeAuth: true },
      params: { meeting_id: meetingId },
    })
  )
}

const getCallId = (): string => useMeetingStore.getState().callId

export async function putCallEvents(
  updateType:
    | 'call_accepted'
    | 'we_accepted_the_call'
    | 'mark_as_missed'
    | 'discard_call',
  id?: string
): Promise<AxiosResponse<any, any>> {
  const data = camelToSnakeCase({
    updateType,
    callId: id || getCallId(),
  })
  return getResponse(
    API.put(`${UPDATE_CALL_EVENTS_ENDPOINT}`, data, {
      headers: { includeAuth: true },
    })
  )
}

export async function putCheckinStatus(
  checkInStatus: 'completed',
  endUserId: string | number
): Promise<AxiosResponse<any, any>> {
  const data = camelToSnakeCase({
    endUserId,
    checkInStatus,
  })
  return getResponse(
    API.put(`${PUT_ENDUSER_CHECKIN_STATUS_ENDPOINT}`, data, {
      headers: { includeAuth: true },
    })
  )
}

export async function getEndUserUnreadEvents(
  endUserId: string | number
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.get(`${ENDUSER_UNSEEN_EVENTS_ENDPOINT}`, {
      headers: { includeAuth: true },
      params: { endUserId },
    })
  )
}

export async function markEndUserUnreadEvents(
  endUserId: string | number
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.put(
      `${ENDUSER_UNSEEN_EVENTS_ENDPOINT}`,
      {},
      {
        headers: { includeAuth: true },
        params: { endUserId },
      }
    )
  )
}

// Live Session
export async function getEndUserLiveSessionInfo(
  endUserId: string | number
): Promise<AxiosResponse<any, any>> {
  return getResponse(
    API.get(`${GET_END_USER_LIVE_SESSION_INFO_ENDPOINT}`, {
      headers: { includeAuth: true },
      params: { enduser_id: endUserId },
    })
  )
}
