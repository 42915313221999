import { Box } from '@mui/material'
import React from 'react'

function PageNotFound(): JSX.Element {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box component="h1">404 Page Not Found</Box>
    </Box>
  )
}

export default PageNotFound
