import { useDyteMeeting } from '@dytesdk/react-web-core'
import { Box, Button, Stack, Typography, alpha } from '@mui/material'
import React, { useEffect } from 'react'

export type CallScreenShareViewProps = {
  dimensions?: {
    width?: number | string
    height?: number | string
    aspectRatio?: number | string
  }
  borderRadius?: 'rounded' | 'square' | 'extraRounded'
  participantId: string
  participantName?: string
  isSelf?: boolean
  screenShareEnabled: boolean
  screenShareTracks: {
    audio?: MediaStreamTrack
    video?: MediaStreamTrack
  }
}

function CallScreenShareView(props: CallScreenShareViewProps): JSX.Element {
  const {
    dimensions,
    participantId,
    participantName,
    isSelf,
    screenShareEnabled,
    screenShareTracks,
    borderRadius,
  } = props
  const { meeting } = useDyteMeeting()

  const borderStyles = {
    rounded: '8px',
    square: '0px',
    extraRounded: '16px',
  }

  useEffect(() => {
    if (participantId && screenShareEnabled && screenShareTracks.video) {
      const videoElem = document.getElementById(
        `screen-share-video-${participantId}`
      ) as HTMLVideoElement
      const stream = new MediaStream()
      stream.addTrack(screenShareTracks.video)
      videoElem.srcObject = stream
    }
  }, [screenShareEnabled, screenShareTracks.video, participantId])

  useEffect(() => {
    if (
      participantId &&
      !isSelf &&
      screenShareEnabled &&
      screenShareTracks.audio
    ) {
      const audioElem = document.getElementById(
        `screen-share-audio-${participantId}`
      ) as HTMLAudioElement
      const stream = new MediaStream()
      stream.addTrack(screenShareTracks.audio)
      audioElem.srcObject = stream
    }
  }, [isSelf, screenShareEnabled, screenShareTracks.audio, participantId])

  const handleCallScreenShareStop = async (): Promise<void> => {
    const response = await meeting.self.disableScreenShare()
    return response
  }

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        background: ({ palette }) => palette.common.black,
        ...dimensions,
        borderRadius: borderRadius && borderStyles[borderRadius],
      }}
    >
      {screenShareEnabled && !isSelf && (
        <Box
          sx={{ width: '100%', objectFit: 'contain' }}
          component="audio"
          id={`screen-share-audio-${participantId}`}
          autoPlay
          playsInline
        />
      )}

      <Box
        sx={{ width: '100%', objectFit: 'contain' }}
        component="video"
        id={`screen-share-video-${participantId}`}
        autoPlay
        playsInline
      />

      {screenShareEnabled && isSelf && (
        <Box>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: ({ palette }) => alpha(palette.common.black, 0.5),
            }}
          >
            {/* Other Stuff overlayed on the screen share */}
            <Button
              variant="contained"
              color="error"
              onClick={handleCallScreenShareStop}
            >
              Stop Sharing
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '4px',
          display: 'flex',
          color: ({ palette }) => palette.common.white,
        }}
      >
        <Stack
          direction="row"
          sx={{
            background: ({ palette }) => alpha(palette.common.black, 0.5),
            maxWidth: '50%',
            p: 0.5,
            borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          }}
        >
          {participantName && (
            <Typography variant="subtitle2" noWrap>
              {participantName}
              {isSelf && ' (You)'}
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

CallScreenShareView.defaultProps = {
  dimensions: {
    width: 200,
    aspectRatio: '16 / 9',
  },
  participantName: '',
  isSelf: false,
  borderRadius: 'rounded',
}

export default CallScreenShareView
