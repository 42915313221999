import { alpha } from '@mui/material'

export const MuiTableCell = (palette: any): object => {
  return {
    styleOverrides: {
      root: {},
      body: {
        borderBottom: 'none',
      },
      head: {
        borderBottom: `1px solid ${alpha(palette.text.primary, 0.1)}`,
        paddingBottom: 11,
      },
    },
    defaultProps: {},
  }
}
